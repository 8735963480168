import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { EncoderUiState } from "../../../../../store/encoder";
import { LivelyEncoderUiContext } from "../../../../context";
import Checkbox, { CheckboxProps, CheckboxStyles } from "../../../../ui-lib/Inputs/Checkbox";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../ErrorBoundary";

interface NoiseSuppressionClasses {
  noiseSuppressionCheckbox?: CheckboxStyles;
}

type NoiseSuppressionCheckboxProps = Omit<CheckboxProps, "classes"> & { classes?: NoiseSuppressionClasses };

const ModularNoiseSuppressionCheckbox = observer(({ classes, ...props }: Partial<NoiseSuppressionCheckboxProps>) => {
  /**
   * Component Name (for error msg)
   */
  const componentName = "<NoiseSuppressionCheckbox/>";

  /**
   * Access LivelyEncoderUiContext & destructure API state
   */
  const ctx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);

  /**
   * Throw error (and trigger ErrorBoundary) if store is undefined.
   * */
  useUndefinedStoreError(ctx?.mediaStreamController != null, componentName);

  const toggleNoiseSuppression = (): void => {
    ctx.mediaStreamController.noiseSuppression = !ctx.mediaStreamController.noiseSuppression;
  };

  /**
   * Wrap onChange function in global Error handler (to trigger ErrorBoundary).
   * */
  const handleInputChange = useUIEventError(toggleNoiseSuppression, componentName);

  return (
    <Checkbox
      {...props}
      classes={classes?.noiseSuppressionCheckbox}
      label="Noise Suppression"
      disabled={!ctx.mediaStreamController.supportsNoiseSuppression()}
      checked={ctx.mediaStreamController.noiseSuppression === true}
      onChange={handleInputChange}
      title={
        !ctx.mediaStreamController.supportsNoiseSuppression()
          ? "Your browser does not support noise suppression"
          : undefined
      }
    />
  );
});

const NoiseSuppressionCheckboxWithErrorBoundary: FC<Partial<NoiseSuppressionCheckboxProps>> = ({
  classes,
  ...props
}) => {
  return (
    <ErrorBoundary
      render={() => (
        <Checkbox
          {...props}
          classes={classes?.noiseSuppressionCheckbox}
          disabled
          label="Noise Suppression Unavailable"
        />
      )}
    >
      <ModularNoiseSuppressionCheckbox classes={classes} {...props} />
    </ErrorBoundary>
  );
};

export default NoiseSuppressionCheckboxWithErrorBoundary;
