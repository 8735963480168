import { Json } from "@livelyvideo/log-node";
import { types } from "mediasoup-client";
import { MediaStreamTrack } from "../../../api/adapter/features/media-stream";
import { BitrateLayer } from "../../../api/player/features/bitrate-switching";

export type AppData = Record<string, unknown>;

export type PeerParameters = {
  id?: string;
  name?: string;
  peerId?: string;
  userId: string;
  displayName: string;
  scope: string;
  permissions: string[];
  behaviours: string[];
  consumers?: types.Consumer[];
  appData?: AppData;
};

export type ConsumerParameters = {
  id: string;
  peerId: string;
  kind: string;
  streamName: string;
  supported: boolean;

  locallyPaused: boolean;
  remotelyPaused?: boolean;
  paused: boolean;

  track: MediaStreamTrack;
  codec: string;
  pause: () => void;
  resume: () => void;
  layers: BitrateLayer[];
  activeLayer: BitrateLayer | null;

  preferredSpatialLayer: number;
  preferredTemporalLayer: number;
  rtpParameters: types.RtpParameters;
  dontAdd: boolean;
};

export type ConsumerEncoding = {
  id: string | number;
  maxBitrate?: number;
  bitrate?: number;
  active: boolean;
};

export type ConsumerLayer = {
  consumerId: string;
  peerId: string;
  streamName: string;
  layers: {
    spatialLayer: number;
  };
  xcode: boolean;
  maxBitrate?: number;
  bitrate?: number;
  id: string | number;
  active?: boolean;
};

export interface ProducerParameters {
  preview: boolean;
  codec: string;
  paused: boolean;
  streamType: string;
  sendPreview?: boolean;
  kind: string;
  id: string;
  track: MediaStreamTrack;
  streamName: string;
}

export interface CommonResponse {
  reasons?: Array<{
    text: string;
  }>;
}

export type CommonRequestData = {
  data: {
    method: string;
    requestId: number;
    request: Json;
  };
};

export const PERMISSIONS = {
  STREAM_VIDEO: "produceVideo",
  STREAM_AUDIO: "produceAudio",
  CHANGE_PEER_SCOPE: "changePeerScope",
};

export const CUSTOM_STREAM_TYPE = "custom";

export interface WSRequest {
  peerId: string;
  consumerId: string;
}

export type WSConsumerSourcesResponse = {
  consumers: [
    {
      consumerId: string;
      peerId: string;
      streamName: string;
      xcode: boolean;
      encodings: ConsumerEncoding[];
    },
  ];
  active: {
    peerId: string;
    consumerId: string;
    xcode: boolean;
    encoding: ConsumerEncoding;
  };
};
