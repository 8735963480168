import { Json } from "@livelyvideo/log-node/lib";
import Views from "@livelyvideo/views";
import type { Encoding, FormatState, Manifest, ManifestFormats, ManifestJson, Serializable, State } from "../api";
import { SourceProvider } from "../api";
import { device } from "../api/adapter";
import { ManifestError, NetworkError, VideoClientError } from "./errors";
import { onceCanceled } from "./utils/context/context";
import type { VcContext } from "./utils/context/vc-context";
import { ObservableEventEmitter } from "./utils/events/event-emitter";

const MEOW_SUPPORTED_AUDIO_CODECS = ["aac"];

export type MediaLoaderOptions = {
  pollingInterval: number;
  unauthorizedPollingInterval: number;
  notFoundPollingInterval: number;
  unauthorizedRecoveryDuration: number;
  host?: string;
};

export interface MediaLoaderEvents {
  source: ManifestJson;
  online: void;
  offline: void;
  viewers: number;
  manifest: { state: State; code?: number; formats: ManifestFormats };
  error: { err: VideoClientError };
}

/**
 * Interacts with the api to fetch video data
 */
class MediaLoader
  extends ObservableEventEmitter<MediaLoaderEvents>
  implements SourceProvider<ManifestJson>, Serializable
{
  static readonly displayName = "MediaLoader";

  get source(): ManifestJson | null {
    if (typeof this.manifest === "string") {
      return null;
    }
    return this.manifest;
  }

  currentState: State = "offline";

  formats: ManifestFormats = {};

  uri: string | null = null;

  originalAuthToken: string | null = null;

  private pingUri: unknown | null = null;

  private destroyed = false;

  private readonly options: MediaLoaderOptions;

  private shouldBePolling = false;

  private readonly views?: Views;

  private readonly viewsInterval: number | null = null;

  private manifest: Manifest | null = null;

  private type: string | null = null;

  private pollingTimeout: number | null = null;

  private noLongerUnauthorizedPolling = false;

  private unauthorizedRecoveryTimout: number | null = null;

  private readonly ctx: VcContext;

  /**
   * Creates MediaLoader for loading manifest by url.
   * Or creates MediaLoader with already loaded manifest
   */
  constructor(ctx: VcContext, manifest: Manifest, options: MediaLoaderOptions) {
    super();

    this.ctx = ctx;
    onceCanceled(ctx).then((reason) => this.dispose(`MediaLoader Class Context Closed: ${reason}`));

    this.currentState = "offline";
    this.formats = {};
    this.pingUri = null;
    this.destroyed = false;
    this.options = options;
    this.setManifest(manifest);
    this.setUri(manifest);
    this.shouldBePolling = false;

    ctx.logger.attachObject(this);
    ctx.logger.trace("constructor()", { manifest });

    this.addInnerDisposer(() => {
      if (this.views != null) {
        this.views.stopTracking();
      }
      clearInterval(this.viewsInterval ?? 0);
      this.destroyed = true;
      clearInterval(this.pollingTimeout ?? 0);
    });

    this.on("error", (err) => {
      const msg = "err" in err ? err.err : err;
      this.ctx.logger.error(`Unable to load manifest: ${msg}`, { err });
    });
  }

  private static modifyFormats(formats: ManifestFormats): ManifestFormats {
    for (const formatName of Object.keys(formats) as Array<keyof ManifestFormats>) {
      if (formatName === "mp4-ws") {
        const format = formats[formatName];
        if (format?.origin != null && MEOW_SUPPORTED_AUDIO_CODECS.includes(format.audioCodec)) {
          format.origin = undefined;
        }
      }
    }

    return formats;
  }

  /**
   * Loads the media data
   */
  async load(previewImg = false): Promise<void> {
    if (this.manifest != null || this.vod) {
      this.emit("manifest", { state: this.currentState, formats: this.formats });
      if (this.manifest != null && typeof this.manifest !== "string") {
        this.emit("source", this.manifest);
      }
      return;
    }

    if (this.uri == null) {
      return;
    }

    this.ctx.logger.debug("loading manifest", { uri: this.uri });

    let response: Response | null = null;
    let code: number;
    let err: Error | null = null;
    let json;

    let uri = this.uri;
    if (previewImg) {
      if (uri.includes("?")) {
        uri += "&img=true";
      } else {
        uri += "?img=true";
      }
    }

    try {
      response = await fetch(uri, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      code = response.status;
    } catch (ex) {
      code = response?.status ?? 0;
      err = new NetworkError("Internal Error", { status: code });
    }

    if (this.isDisposed) {
      return;
    }

    if (code > 499) {
      err = new NetworkError("Internal Error", { status: code });
    } else if (code === 404) {
      err = new NetworkError("Not Found", { status: code });
    } else if (code === 403) {
      err = new NetworkError("Forbidden", { status: code });
    } else if (code === 401) {
      err = new NetworkError("Unauthorized", { status: code });
    }

    let body: ManifestJson | null = null;
    if (!err && response != null) {
      json = await response.json();
      body = this.validateResponse(json) ? json : null;
    }

    if (!err && body == null) {
      err = new NetworkError("Unexpected Response", { status: code });
    }

    if (err != null) {
      if (code === 404) {
        this.ctx.logger.debug("manifest not found");
      } else {
        this.ctx.logger.warn(`Unable to load manifest ${err}`);
      }
    }
    this.ctx.logger.debug("manifest received", {
      body,
      code,
      uri: this.uri,
    });

    let state: State | null = null;
    if (code === 404 || body == null || body.formats == null) {
      state = "offline";
    } else if (code === 200 && body.self != null) {
      // todo: probably that's a bug on server side and should be fixed
      // there instead of using this workaround
      let { self } = body;
      if (!self.includes("://") && this.uri.includes("://")) {
        const path = /(\w*:\/\/[^/]+)/.exec(this.uri);
        if ((path?.length ?? 0) > 1) {
          self = `${path?.[1]}/${self}`;
        }
      }
      this.uri = self;
    }

    if (state == null && body?.formats != null) {
      this.formats = MediaLoader.modifyFormats(body.formats);
      state = Object.keys(this.formats).length === 0 ? "offline" : "online";
    }

    if (state == null) {
      const msg = code >= 400 ? "Network error while retrieving manifest" : "Invalid manifest";
      this.throwError(
        new ManifestError(msg, {
          loader: this,
          manifest: body ?? undefined,
          url: this.uri,
          inner: err,
        }),
      );
    }

    if (state !== this.currentState) {
      this.emit(state);
    }

    this.currentState = state;
    this.pingUri = body?.ping != null ? body.ping : null;
    this.type = body?.type != null ? body.type : "live";
    this.emit("manifest", { state: this.currentState, code, formats: this.formats });
    if (body != null) {
      this.emit("source", body);
    }

    this.setNextPoll(code);
  }

  private setNextPoll(statusCode: number): void {
    if (this.vod) {
      return;
    }

    device.clearTimeout(this.pollingTimeout ?? 0);
    let to = this.options.pollingInterval;
    switch (statusCode) {
      case 401:
      case 403:
        if (this.noLongerUnauthorizedPolling) {
          to = this.options.pollingInterval;
        } else {
          to = this.options.unauthorizedPollingInterval;

          if (this.unauthorizedRecoveryTimout == null) {
            this.unauthorizedRecoveryTimout = device.setTimeout(() => {
              this.noLongerUnauthorizedPolling = true;
            }, this.options.unauthorizedRecoveryDuration);
          }
        }
        break;
      case 404:
        to = this.options.notFoundPollingInterval;
        break;
      default:
        this.noLongerUnauthorizedPolling = false;
    }

    this.pollingTimeout = device.setTimeout(() => {
      this.load().catch((ex) => this.ctx.logger.error(ex));
    }, to);
  }

  /**
   * Sets the manifest
   */
  private setManifest(manifest: Manifest): void {
    this.type = null;
    this.formats = {};

    if (typeof manifest === "string") {
      this.uri = manifest;
    } else if (this.validateResponse(manifest)) {
      this.uri = manifest.self ?? null;
      this.manifest = manifest;
      this.currentState = "online";
      this.type = this.manifest.type ?? null;
      this.formats = this.manifest.formats ?? {};
    }

    if (this.uri == null) {
      return;
    }

    // parse original auth token from manifest url
    const accessTokenIndex = this.uri.indexOf("accessToken=");
    if (accessTokenIndex > -1) {
      const at = this.uri.slice(accessTokenIndex + 12);
      const qpIndex = at.indexOf("&") ?? -1;
      if (qpIndex > -1) {
        this.originalAuthToken = at.slice(0, qpIndex);
      } else {
        this.originalAuthToken = at;
      }
    }
  }

  get hasPlayableMedia(): boolean {
    return this.uri != null || this.manifest != null;
  }

  private validateResponse(body: unknown): body is ManifestJson {
    try {
      const manifestBody = body as ManifestJson;

      // TODO: that's a bug on server-side. remove it when it's fixed
      const webrtc = manifestBody.formats?.webrtc;
      if (webrtc != null) {
        if (typeof webrtc.origin?.location === "string") {
          webrtc.origin.location = webrtc.origin?.location.replace(/https?:\/\/(https?):?\/\//, "$1://");
        }
        if (typeof webrtc.origin.rsrc === "string") {
          webrtc.origin.rsrc = webrtc.origin.rsrc.replace(/:3000:3000/, ":3000");
        }
      }
    } catch (ex) {
      this.emitError(new ManifestError("bad manifest", { loader: this, inner: ex instanceof Error ? ex : null }));
      return false;
    }
    return true;
  }

  private setUri(manifest?: Manifest): boolean {
    if (manifest == null) {
      return this.uri != null;
    }

    if (typeof manifest === "string") {
      this.uri = manifest;
      return true;
    }

    if (this.validateResponse(manifest)) {
      this.manifest = manifest;
      if (manifest.self != null) {
        this.uri = manifest.self;
      }
      return true;
    }

    this.emitError(new ManifestError("Invalid Media URL", { manifest, loader: this }));
    return false;
  }

  /**
   * Returns the state and formats
   */
  get(formatName: keyof ManifestFormats): FormatState {
    const format = this.formats[formatName];
    if (format == null) {
      return {
        encodings: [],
        state: "online",
        auto: false,
        driver: "",
      };
    }

    let encodings: Encoding[] = [];
    let origin: typeof format["origin"] | undefined;
    if ("origin" in format && format.origin != null) {
      origin = format.origin;
      origin.origin = true;
    }

    if ("encodings" in format && format.encodings != null) {
      encodings = format.encodings.sort((a, b) => {
        if ((a.videoKbps ?? 0) + (a.audioKbps ?? 0) > (b.videoKbps ?? 0) + (b.audioKbps ?? 0)) {
          return 1;
        }
        return -1;
      });
    }

    return {
      type: this.type ?? undefined,
      origin: origin ?? undefined,
      encodings,
      audioCodec: "audioCodec" in format ? format.audioCodec : undefined,
      videoCodec: "videoCodec" in format ? format.videoCodec : undefined,
      manifest: "manifest" in format ? format.manifest : undefined,
      state: "online",
      auto: false,
      driver: "",
    };
  }

  get vod(): boolean {
    return this.type === "recorded";
  }

  /**
   * Starts a polling interval
   */
  private startInterval(): void {
    if (this.options.pollingInterval == null || this.manifest != null || this.vod) {
      this.shouldBePolling = false;
      return;
    }
    this.shouldBePolling = true;
  }

  toJSON(): Json {
    return {
      uri: this.uri,
      options: this.options,

      aggregates: {
        support: this.ctx.support.hash,
        state: this.currentState,
      },
    };
  }
}

export default MediaLoader;
