import { types } from "@livelyvideo/video-client-core";
import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { EncoderUiState } from "../../../../../store/encoder";
import { LivelyEncoderUiContext } from "../../../../context";
import { useStyles } from "../../../../styling";
import { mergeStylesObjects } from "../../../../styling/utils";
import Select, { SelectProps } from "../../../../ui-lib/Inputs/Select";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../ErrorBoundary";

function getResolution(msc: types.MediaStreamControllerAPI): number | null {
  if (msc.settings?.video?.height != null) {
    return msc.settings.video.height;
  }

  const resolution = msc.resolution;
  if (resolution == null) {
    return null;
  }

  if (Array.isArray(resolution)) {
    return resolution[0];
  }
  return typeof resolution === "number" ? resolution : resolution.ideal;
}

/**
 * @todo: Possibly refactor Select Components
 *
 */
const ModularQualitySelect = observer(({ classes = {}, maxResolutionHeight, ...props }: Partial<SelectProps>) => {
  /**
   * Component Name (for error msg)
   */
  const componentName = "<ResolutionSelect/>";

  /**
   * Access LivelyEncoderUiContext & destructure API state
   */
  const ctx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);

  /**
   * Throw error (and trigger ErrorBoundary) if store is undefined.
   * */
  useUndefinedStoreError(ctx?.mediaStreamController != null, componentName);

  const availableResolutions = ctx.mediaStreamController.availableResolutions.filter(
    (r) => maxResolutionHeight == null || r <= maxResolutionHeight,
  );

  /**
   * On input change handler to (1) update API and (2) update component
   * <select> value with user selected resolution.
   */

  const selectResolution = (ev: React.ChangeEvent<HTMLSelectElement>): void => {
    ctx.mediaStreamController.resolution = Number(ev.target.value);
  };

  const mergedClasses = useStyles({ source: classes, target: {} }, "select");
  const mergedStyles = mergeStylesObjects(classes, {});

  /**
   * Wrap onChange function in global Error handler (to trigger ErrorBoundary).
   * */
  const handleInputChange = useUIEventError(selectResolution, componentName);

  return (
    <Select
      {...props}
      classes={mergedStyles}
      label="Quality"
      onChange={handleInputChange}
      value={getResolution(ctx.mediaStreamController)?.toString() ?? undefined}
    >
      <option disabled value="" className={mergedClasses.option}>
        Select a Resolution
      </option>
      {availableResolutions.map((item) => (
        <option key={item} value={item} className={mergedClasses.options}>
          {`${item}p`}
        </option>
      ))}
    </Select>
  );
});

// eslint-disable-next-line react/function-component-definition
const QualitySelectWithErrorBoundary: FC<Partial<SelectProps>> = ({ classes = {}, maxResolutionHeight, ...props }) => {
  const mergedClasses = useStyles({ source: classes, target: {} }, "select");
  const mergedStyles = mergeStylesObjects(classes, {});

  return (
    <ErrorBoundary
      render={() => (
        <Select {...props} value="" disabled classes={mergedStyles} label="Resolution: Unavailable">
          <option value="" className={mergedClasses?.options}>
            {props.fallbackText ?? " Resolution Unavailable"}
          </option>
        </Select>
      )}
    >
      <ModularQualitySelect maxResolutionHeight={maxResolutionHeight} classes={classes} {...props} />
    </ErrorBoundary>
  );
};

export default QualitySelectWithErrorBoundary;
