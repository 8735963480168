import { Serializable } from "@livelyvideo/log-node";

export interface IVideoClientError extends Error, Serializable {
  readonly code: ErrorCode;

  readonly inner?: Error | null;

  readonly critical?: boolean;

  handled: boolean;
}

export enum ErrorCode {
  NativeError = "native-error",
  InternalError = "internal-error",
  NotInitialized = "not-initialized",
  NotSupported = "not-supported",
  ValidationError = "validation-error",
  NetworkError = "network-error",
  Permission = "permission-error",
  Constraints = "constraints-error",
  DeviceNotFound = "device-not-found",
  TrackEnded = "track-ended",
  JoinCallError = "join-call-error",
  CallError = "call-error",
  JoinCallRejected = "join-call-rejected",
  StartBroadcastError = "start-broadcast-error",
  UpdateBroadcastError = "start-broadcast-error",
  StreamExists = "stream-exists",
  StreamNotFound = "stream-not-found",
  EnableVideoError = "enable-video-error",
  DisableVideoError = "disable-video-error",
  EnableAudioError = "enable-audio-error",
  DisableAudioError = "disable-audio-error",
  ManifestError = "manifest-error",
  LayerNotFound = "layer-not-found",
  PlayingIssue = "playing-issue",
  DisposedObject = "disposed-object",
  RetrievingMediaStreamError = "retrieving-media-stream-error",
  CapturableStreamError = "capturable-stream-error",
  SFUNewPeersEvent = "sfu-new-peers-event",
  SFUConsumerSourcesEvent = "sfu-consumer-sources-event",
  SFUNewConsumerEvent = "sfu-new-consumer-event",
  SFUPeerClosedEvent = "sfu-peer-closed-event",
  SFUSwitchConsumerTrackEvent = "sfu-switch-consumer-track-event",
  SFUConsumerClosedEvent = "sfu-consumer-closed-event",
  SFUConsumerPausedEvent = "sfu-consumer-paused-event",
  SFUConsumerResumedEvent = "sfu-consumer-resumed-event",
  SFUConsumerScoreEvent = "sfu-consumer-score-event",
  SFUConsumerLayersChangedEvent = "sfu-consumer-layers-changed-event",
  SFUProducerClosedEvent = "sfu-producer-closed-event",
  SFUProducerPausedEvent = "sfu-producer-paused-event",
  SFUProducerResumedEvent = "sfu-producer-resumed-event",
  MediasoupSetupError = "mediasoup-setup-error",
  TransportStateError = "transport-state-error",
  BadInput = "bad-input",
  DriverNotSupported = "driver-not-supported",
  ElementRequired = "element-required",
  EmbedSWFFailed = "embedding-flash-swf-failed",
  GetUserMediaFailed = "get-user-media-failed",
  ManifestUnexpectedResponse = "manifest-unexpected-response",
  ManifestUnauthorized = "manifest-unauthorized",
  ManifestForbidden = "manifest-forbidden",
  ManifestInternalError = "manifest-internal-error",
  ManifestNotFound = "manifest-not-found",
  InvalidControls = "invalid-controls-parent",
  InvalidMediaURL = "invalid-media-url",
  InvalidPopoutURL = "invalid-popout-url",
  InvalidElement = "invalid-element",
  WSNetworkError = "websocket-network-error",
  NoDrivers = "no-valid-drivers",
  PlaybackError = "playback-error",
  UnknownDriver = "unknown-driver",
  UnknownError = "unknown-error",
  UnrecognizedDriver = "unrecognized-driver",
  UserRequired = "user-required",
  InvalidBitrate = "invalid-bitrate",
  HlsjsNotLoaded = "hlsjs-not-loaded",
  MediaError = "media-error",
  MeowDriverError = "meow-driver-error",
  InternalCallError = "internal-call-error",
  WSRequestError = "ws-request-error",
  MediaRecorderError = "media-recorder-error",
  UpdateMSCError = "update-msc-error",
  Mp4BufferError = "mp4-buffer-error",
  HandleHlsJsError = "handle-hlsjs-error",
  ConsumerNotFound = "consumer-not-foud"
}
