import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { EncoderUiState } from "../../../../../../store";
import { LivelyEncoderUiContext } from "../../../../../context";
import ButtonText from "../../../../../ui-lib/Buttons/Text";
import ButtonTextProps from "../../../../../ui-lib/Buttons/Text/propTypes";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../../ErrorBoundary";

const ModularTestMicButton = observer(
  ({ active, children, classes, label = "Test Sound", onClick, ...props }: Partial<ButtonTextProps>) => {
    /**
     * Component Name (for error msg)
     */
    const componentName = "<TestMicButton/>";

    /**
     * Access LivelyEncoderUiContext & destructure API state
     */
    const ctx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);

    /**
     * Throw error (and trigger ErrorBoundary) if store is undefined.
     * */
    useUndefinedStoreError(ctx?.mediaStreamController != null && ctx.videoElement != null, componentName);

    /**
     * @todo: move this over to our ui-state.
     */
    const toggleMic = (): void => {
      ctx.testMic = !ctx.testMic;
    };

    /**
     * handleClick function wrapped in global Error handler (to trigger ErrorBoundary).
     * */
    const handleClick = onClick || useUIEventError(toggleMic, componentName);

    return (
      <ButtonText
        classNames="lv-button--get-sound"
        data-selenium="test-mic-button"
        label={label}
        classes={classes}
        onClick={handleClick}
        {...props}
      >
        {children ?? (ctx.testMic ? "Click to End Microphone Test" : "Click to Test Microphone")}
      </ButtonText>
    );
  },
);

// eslint-disable-next-line react/function-component-definition
const TestMicButtonWithErrorBoundary: FC<Partial<ButtonTextProps>> = ({
  label = "Test Sounds",
  buttonText,
  classes,
  ...props
}) => {
  return (
    <ErrorBoundary
      render={() => (
        <ButtonText
          classNames="lv-button--get-sound"
          data-selenium="test-mic-button"
          onClick={undefined}
          disabled
          label={label}
          {...props}
        >
          {buttonText ?? "Microphone Test Unavailable"}
        </ButtonText>
      )}
    >
      <ModularTestMicButton label={label} classes={classes} {...props} />
    </ErrorBoundary>
  );
};

export default TestMicButtonWithErrorBoundary;
