import type { LoggerCore } from "@livelyvideo/log-client";
import { extractAggregates, isSerializableObject, Json } from "@livelyvideo/log-node";
import { computed, IObservableArray, makeObservable, observable } from "mobx";
import { MediaStream } from "../../api/adapter/features/media-stream";
import { SourceProvider } from "../../api/common";
import { ManifestFormats } from "../../api/manifest";
import type { PlayerEvents } from "../../api/player";
import { BitrateSwitchingEvents, BitrateSwitchingFeature, Quality } from "../../api/player/features/bitrate-switching";
import { ConsumerEvents, ConsumerFeature } from "../../api/player/features/consumer";
import { Feature as PlayerFeature } from "../../api/player/features/feature";
import { MutedAutoplayFeature } from "../../api/player/features/muted-autoplay";
import { MediasoupSource } from "../mediasoup-source";
import { supportsMediasoupWebrtc } from "../utils/browser-support/browser";
import { VcContext } from "../utils/context/vc-context";
import { CorePlayer, CorePlayerOptions } from "./core";

interface MediasoupPlayerEvents extends PlayerEvents, ConsumerEvents, BitrateSwitchingEvents {}

export type MediasoupPlayerOptions = CorePlayerOptions & { mutedAutoplayFallback?: boolean };

export class MediasoupPlayer
  extends CorePlayer<MediasoupPlayerOptions, MediaStream, MediasoupPlayerEvents>
  implements ConsumerFeature, BitrateSwitchingFeature, MutedAutoplayFeature
{
  static readonly displayName = "MediasoupPlayer";

  get displayName(): string {
    return MediasoupPlayer.displayName;
  }

  static async isSupported(logger?: LoggerCore): Promise<boolean> {
    return supportsMediasoupWebrtc("Mediasoup Player", logger);
  }

  async isSupported(): Promise<boolean> {
    return MediasoupPlayer.isSupported();
  }

  static get format(): keyof ManifestFormats {
    return "webrtc";
  }

  get format(): keyof ManifestFormats {
    return MediasoupPlayer.format;
  }

  preferredSource: boolean | null = null;

  hasAudio = false;

  hasVideo = false;

  isManifestPlayer = false;

  private readonly playerOptions: MediasoupPlayerOptions;

  protected get implementedFeatures(): PlayerFeature[] {
    if (this.isManifestPlayer) {
      return [PlayerFeature.CONSUMER, PlayerFeature.BITRATE_SWITCHING, PlayerFeature.MUTED_AUTOPLAY];
    }
    return [PlayerFeature.CONSUMER, PlayerFeature.BITRATE_SWITCHING];
  }

  constructor(ctx: VcContext, provider: SourceProvider<MediaStream>, options: MediasoupPlayerOptions) {
    super(ctx, provider, options);

    makeObservable(this, {
      hasAudio: observable,
      hasVideo: observable,

      consumerAudioEnabled: computed,
      consumerVideoEnabled: computed,
    });

    this.playerOptions = options;

    if (this.playerOptions.mutedAutoplayFallback) {
      this.isManifestPlayer = true;
    }

    this.ctx.logger.trace("constructor()", { options });
  }

  protected async handleSource(stream: MediaStream): Promise<void> {
    this.ctx.logger.trace("handleSource()");

    this.source = stream;
    if (this.hostEl != null) {
      if (this.autoPlay) {
        await this.playingPromise;
        this.ctx.logger.trace("handleSource() -> await this.playingPromise");
        this.hostEl.srcObject = stream;
        await this.play();
        this.ctx.logger.trace("handleSource() -> await play()");
      } else {
        this.hostEl.srcObject = stream;
      }
      this.ctx.logger.debug("srcObject set");
    }

    if (this.provider instanceof MediasoupSource) {
      this.hasAudio = this.provider.hasAudio;
      this.hasVideo = this.provider.hasVideo;
      this.consumerAudioMuted = !this.hasAudio || this.provider.audioMuted;
      this.consumerVideoPaused = !this.hasVideo || this.provider.videoPaused;

      this.provider.off("availableQualities", this.handleAvailableQualities);
      this.provider.off("currentQuality", this.handleCurrentQuality);
      this.provider.on("availableQualities", this.handleAvailableQualities);
      this.provider.on("currentQuality", this.handleCurrentQuality);

      this.availableQualities = this.provider.availableQualities;
      if (this.provider.currentQuality != null && this.currentQuality?.level !== this.provider.currentQuality.level) {
        this.currentQuality = this.provider.currentQuality;
      }
    }
  }

  private handleAvailableQualities(ev: BitrateSwitchingEvents["availableQualities"]): void {
    (this.availableQualities as IObservableArray).replace(ev);
    this.emit(
      "layers",
      ev.map((q: Quality) => q.layer),
    );
  }

  private handleCurrentQuality(ev: BitrateSwitchingEvents["currentQuality"]): void {
    if (ev?.level !== this.currentQuality?.level) {
      this.currentQuality = ev;
      this.emit("activeLayer", ev?.layer);
    }
  }

  get consumerAudioEnabled(): boolean {
    const { consumerAudioMuted } = this;
    if (!(this.provider instanceof MediasoupSource)) {
      return false;
    }

    return this.hasAudio && consumerAudioMuted === false;
  }

  get consumerVideoEnabled(): boolean {
    const { consumerVideoPaused } = this;
    if (!(this.provider instanceof MediasoupSource)) {
      return false;
    }
    return this.hasVideo && consumerVideoPaused === false;
  }

  get streamName(): string {
    if (this.provider instanceof MediasoupSource) {
      return this.provider.streamName;
    }
    return "";
  }

  get peerId(): string {
    if (this.provider instanceof MediasoupSource) {
      return this.provider.peer?.peerId ?? "";
    }
    return "";
  }

  get callId(): string {
    if (this.provider instanceof MediasoupSource) {
      return this.provider.peer?.call?.id ?? "";
    }
    return "";
  }

  get userId(): string {
    if (this.provider instanceof MediasoupSource) {
      return this.provider.peer?.userId ?? "";
    }
    return "";
  }

  toJSON(): Json {
    const data = super.toJSON();
    if (!(data != null && typeof data === "object" && !Array.isArray(data) && !isSerializableObject(data))) {
      return {};
    }

    const aggregates = data.aggregates ?? {};
    if (
      !(
        aggregates != null &&
        typeof aggregates === "object" &&
        !Array.isArray(aggregates) &&
        !isSerializableObject(aggregates)
      )
    ) {
      return data;
    }

    delete data.aggregates;

    return {
      ...data,
      options: this.options,

      aggregates: {
        ...aggregates,
        ...extractAggregates(this.provider, "support"),
        support: this.ctx.support.hash,
        consumerAudioEnabled: this.consumerAudioEnabled,
        consumerVideoEnabled: this.consumerVideoEnabled,
        preferredSource: this.preferredSource,
        currentQuality: this.currentQuality,
        isManifestPlayer: this.isManifestPlayer,
      },
    };
  }
}
