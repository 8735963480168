import { types } from "@livelyvideo/video-client-core";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { FC, ReactElement, useContext, useEffect, useMemo } from "react";
import { EncoderUiState } from "../../../../../../store/encoder";
import { BroadcastState } from "../../../../../../store/encoder/broadcast-state";
import { LivelyEncoderUiContext, LivelyVideoClientContext } from "../../../../../context";
import ButtonIcon from "../../../../../ui-lib/Buttons/Icon";
import ButtonIconProps from "../../../../../ui-lib/Buttons/Icon/propTypes";
import Icon, { IconStyleProps } from "../../../../../ui-lib/Icons";
import { RootStyles } from "../../../../../ui-lib/typings/css";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../../ErrorBoundary";

interface JoinBroadcastButtonClasses extends RootStyles {
  icon?: IconStyleProps;
}

interface JoinBroadcastButtonProps extends Partial<ButtonIconProps> {
  broadcastOptions: types.BroadcastOptions;
  classes?: JoinBroadcastButtonClasses;
  setCallId?: (id: string | null) => void;
  callId?: string | null;
  onZeroBitrate?: (ev: types.BroadcastEvents["zeroBitrate"]) => void;
  setOwnerCall?: (call: types.CallAPI | null) => void;
  setCall?: (call: types.CallAPI | null) => void;
  clientReferrer?: string;
  sfuOptions?: types.SFUOptions;
  videoUserId?: string;
}

const ModularJoinBroadcastButton = observer(
  ({
    icon: ProvidedIcon,
    onClick,
    screenReaderText,
    broadcastOptions,
    onZeroBitrate,
    active,
    classes = {
      root: {},
      icon: {},
    },
    label = "Stream Broadcast Toggle Button",
    setCallId,
    callId,
    setOwnerCall,
    setCall,
    clientReferrer,
    sfuOptions,
    videoUserId,
    ...props
  }: JoinBroadcastButtonProps): ReactElement => {
    const componentName = "<BroadcastButton/>";

    /**
     * Access LivelyEncoderUiContext & destructure API state
     */
    const encoderCtx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);
    /**
     * Grab the videoClient supplied from the encoder.
     */
    const videoClient = useContext<types.VideoClientAPI | null>(LivelyVideoClientContext);

    useUndefinedStoreError(encoderCtx?.mediaStreamController != null && videoClient != null, componentName);

    const broadcastState = useMemo(
      () => new BroadcastState(videoClient, encoderCtx.mediaStreamController),
      [videoClient, encoderCtx.mediaStreamController],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(
      reaction(
        () => broadcastState.call,
        (newCall, oldCall) => {
          setCall?.(newCall);
          setCallId?.(newCall?.id ?? null);
          if (newCall?.isOwner) {
            setOwnerCall?.(newCall);
          }
          if (onZeroBitrate != null) {
            oldCall?.off("zeroBitrate", onZeroBitrate);
            newCall?.on("zeroBitrate", onZeroBitrate);
          }
        },
      ),
      [broadcastState, onZeroBitrate],
    );

    const callOptions = {
      userId: videoUserId,
      clientReferrer,
      sfu: sfuOptions,
    };

    const activeState = active ?? broadcastState.isBroadcasting;

    async function handleJoinBroadcast(): Promise<void> {
      return broadcastState.toggleBroadcast(callId ?? callOptions, broadcastOptions);
    }

    /**
     * handleClick function wrapped in global Error handler (to trigger ErrorBoundary).
     * */
    const handleClick = onClick ?? useUIEventError(handleJoinBroadcast, componentName);
    const icon = ProvidedIcon ?? <Icon iconName="broadcast" classes={classes?.icon} />;

    return (
      <ButtonIcon
        active={activeState}
        activeClass="lv-button--active"
        classes={classes}
        inactiveClass={null}
        data-selenium="broadcast-stream-button"
        icon={icon}
        onClick={handleClick}
        disabled={
          encoderCtx.mediaStreamController.videoDeviceId == null &&
          encoderCtx.mediaStreamController.audioDeviceId == null
        }
        label={label}
        {...props}
      >
        {screenReaderText ?? `Click to ${activeState ? "Stop" : "Start"} Broadcast`}
      </ButtonIcon>
    );
  },
);

/**
 *
 * @visibleName Broadcast Button
 */
const JoinBroadcastButtonWithErrorBoundary: FC<JoinBroadcastButtonProps> = ({
  icon: ProvidedIcon,
  label = "Stream Broadcast Toggle Button",
  screenReaderText,
  broadcastOptions,
  classes,
  ...props
}) => {
  const icon = <Icon iconName="broadcast" classes={classes?.icon} />;

  return (
    <ErrorBoundary
      render={() => (
        <ButtonIcon
          data-selenium="broadcast-stream-button"
          disabled
          classes={classes}
          icon={ProvidedIcon ?? icon}
          label={label}
          onClick={undefined}
          active={false}
          {...props}
        >
          {screenReaderText ?? "Click to start Broadcast"}
        </ButtonIcon>
      )}
    >
      <ModularJoinBroadcastButton
        icon={ProvidedIcon}
        classes={classes}
        label={label}
        screenReaderText={screenReaderText}
        broadcastOptions={broadcastOptions}
        {...props}
      />
    </ErrorBoundary>
  );
};

export default JoinBroadcastButtonWithErrorBoundary;
