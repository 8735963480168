import { types } from "@livelyvideo/video-client-core";
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

type Props = React.VideoHTMLAttributes<HTMLVideoElement> & { source: MediaStream | types.PlayerAPI | null };

export const Video = forwardRef<HTMLVideoElement, Props>((props, ref): React.ReactElement => {
  const videoEl = useRef<HTMLVideoElement>(null);
  const { source, ...other } = props;

  useImperativeHandle(ref, () => videoEl.current as HTMLVideoElement); // it can't be null here

  useEffect(() => {
    const el = videoEl.current;
    if (el == null || source == null || el.srcObject === source) {
      return;
    }

    if (source instanceof MediaStream) {
      el.srcObject = source;
      el.play().catch((err) => {
        // ignore because it's probably already playing
      });
    } else {
      source.attachTo(el);
    }
  }, [source]);

  // eslint-disable-next-line jsx-a11y/media-has-caption
  return <video {...other} ref={videoEl} />;
});
