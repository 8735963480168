import { LoggerCore } from "@livelyvideo/log-client";
import type { Json } from "@livelyvideo/log-node";
import { extractAggregates } from "@livelyvideo/log-node";
import { types } from "mediasoup-client";
import { PeerProducerOptions } from "../../../api";
import { device, onceDeviceReady } from "../../../api/adapter";
import { AudioContext, GainNode } from "../../../api/adapter/features/audio-context";
import { Feature } from "../../../api/adapter/features/feature";
import { MediaStream, MediaStreamTrack } from "../../../api/adapter/features/media-stream";
import { SourceProvider } from "../../../api/common";
import { PACKAGE_NAME } from "../../../utils/common";
import { InternalError, PermissionsError, RetrievingMediaStreamError, VideoClientError } from "../../errors";
import { MediasoupSource } from "../../mediasoup-source";
import { onceCanceled } from "../../utils/context/context";
import { VcContext } from "../../utils/context/vc-context";
import { ObservableEventEmitter } from "../../utils/events/event-emitter";
// import { WebrtcStats } from "../stats";
import * as anomalies from "../stats/anomalies";
import type { Call } from "./call";
import { AppData, CUSTOM_STREAM_TYPE, PERMISSIONS } from "./common";
import EchoDetector from "./echo";
import { TROUBLESHOOTING } from "./messageList";

let producerAudioContext: AudioContext | null = null;
onceDeviceReady.then((dev) => {
  if (dev.isImplements(Feature.AUDIO_CONTEXT)) {
    producerAudioContext = new dev.AudioContext();
  }
});

const VIDEO = "video";
const AUDIO = "audio";

// region Stream Events
export type StreamEvents = {
  error: VideoClientError;
};
// endregion

export type StreamRetriever = () => Promise<MediaStream>;

export interface StreamAudioOptions {
  streamProvider?: SourceProvider<MediaStream>;
  streamProviderType?: "devices";
  echoDetection?: boolean;
  preferredDeviceLabel?: string | null;
}

export interface StreamVideoOptions {
  streamProvider?: SourceProvider<MediaStream>;
  streamProviderType?: "devices";
  preferredDeviceLabel?: string | null;
}

export interface StreamOptions {
  audio: StreamAudioOptions;
  video: StreamVideoOptions;
  statsInterval?: number;
  defaultAudioConstraints?: {
    autoGainControl: number;
  };
}

class Stream extends ObservableEventEmitter<StreamEvents> {
  static readonly displayName = "PvcStream";

  readonly streamName: string;

  readonly call: Call;

  private readonly _audioOptions: Partial<StreamAudioOptions>;

  private _audioStreamProvider: SourceProvider<MediaStream> | null;

  private _audioStreamProviderType: string | null;

  private readonly _useEchoDetection: boolean;

  private readonly _videoOptions: Partial<StreamVideoOptions>;

  private _videoStreamProvider: SourceProvider<MediaStream> | null;

  private _videoStreamProviderType: string | null;

  private _videoProducer: types.Producer | null;

  private _audioProducer: types.Producer | null;

  private readonly _gainNode?: GainNode;

  private readonly _baseGainValue?: number;

  private _echoDetector?: EchoDetector | null;

  private readonly _statsInterval: number;

  private readonly ctx: VcContext;

  constructor(ctx: VcContext, streamName: string, call: Call, options_?: Partial<StreamOptions>) {
    super();

    onceCanceled(ctx).then((reason) => this.dispose(`Stream Context Cancelled: ${reason}`));

    this.ctx = ctx;
    this.streamName = streamName;
    this.call = call;

    const options: Partial<StreamOptions> = options_ ?? {};

    // Audio options
    this._audioOptions = options.audio ?? {};
    if (this._audioOptions.streamProvider != null) {
      this._audioStreamProvider = this._audioOptions.streamProvider ?? null;
      this._audioStreamProviderType = this._audioOptions.streamProviderType ?? CUSTOM_STREAM_TYPE;
    } else {
      this._audioStreamProvider = null;
      this._audioStreamProviderType = null;
    }
    this._useEchoDetection = this._audioOptions.echoDetection ?? false;

    // Video options
    this._videoOptions = options.video ?? {};
    if (this._videoOptions.streamProvider != null) {
      this._videoStreamProvider = this._videoOptions.streamProvider ?? null;
      this._videoStreamProviderType = this._videoOptions.streamProviderType ?? CUSTOM_STREAM_TYPE;
    } else {
      this._videoStreamProvider = null;
      this._videoStreamProviderType = null;
    }

    this._videoProducer = null;
    this._audioProducer = null;

    // if (this._useEchoDetection && producerAudioContext != null) {
    //   this._gainNode = producerAudioContext.createGain();
    //   this._baseGainValue = this._gainNode.gain.value;
    //   this._echoDetector = null;
    // }

    this._statsInterval = options.statsInterval ?? 5000;

    ctx.logger.attachObject(this);
    ctx.logger.trace("constructor()", this);

    this.addInnerDisposer(() => {
      this.close();
    });
  }

  get logData(): { streamName: string } {
    return {
      streamName: this.streamName,
    };
  }

  get audioProducer(): types.Producer | null {
    return this._audioProducer;
  }

  get videoProducer(): types.Producer | null {
    return this._videoProducer;
  }

  get hasVideoStreamTrack(): boolean {
    const source = this._videoStreamProvider?.source;
    if (source == null) {
      return false;
    }
    return source.getVideoTracks().length > 0;
  }

  get hasAudioStreamTrack(): boolean {
    const source = this._audioStreamProvider?.source;
    if (source == null) {
      return false;
    }
    return source.getAudioTracks().length > 0;
  }

  async setVideoStreamProvider(
    streamProvider: MediasoupSource,
    options: PeerProducerOptions,
    execute: boolean,
    preview: boolean,
  ): Promise<types.Producer | null> {
    return this._setMediaStreamProvider(VIDEO, streamProvider, options, execute, false, preview);
  }

  async setAudioStreamProvider(
    streamProvider: MediasoupSource,
    options: PeerProducerOptions,
    execute: boolean,
    preview: boolean,
  ): Promise<types.Producer | null> {
    return this._setMediaStreamProvider(AUDIO, streamProvider, options, execute, false, preview);
  }

  private checkPermissions(video: boolean): void {
    if (video && !this.call.hasPermission(PERMISSIONS.STREAM_VIDEO)) {
      this.throwError(
        new PermissionsError("no permission to stream video", { stream: this, permissions: this.call.permissions }),
      );
    } else if (!video && !this.call.hasPermission(PERMISSIONS.STREAM_AUDIO)) {
      this.throwError(
        new PermissionsError("no permission to stream audio", { stream: this, permissions: this.call.permissions }),
      );
    }
  }

  async _setMediaStreamProvider(
    kind: types.MediaKind,
    streamProvider: SourceProvider<MediaStream>,
    options: PeerProducerOptions,
    execute: boolean,
    final: boolean,
    preview: boolean,
  ): Promise<types.Producer | null> {
    this.ctx.logger.debug("setMediaStreamRetriever", {
      ...this.logData,
      kind,
    });

    const video = kind === VIDEO;

    let producer: types.Producer | null = null;
    let revertStreamProvider: SourceProvider<MediaStream> | null = null;
    let revertStreamProviderType;

    if (video) {
      revertStreamProvider = this._videoStreamProvider;
      revertStreamProviderType = this._videoStreamProviderType;
      this._videoStreamProvider = streamProvider;
      this._videoStreamProviderType = options.streamType ?? CUSTOM_STREAM_TYPE;
      producer = this._videoProducer;
    } else {
      revertStreamProvider = this._audioStreamProvider;
      revertStreamProviderType = this._audioStreamProviderType;
      this._audioStreamProvider = streamProvider;
      this._audioStreamProviderType = options.streamType ?? CUSTOM_STREAM_TYPE;
      producer = this._audioProducer;
    }

    if (!execute) {
      return null;
    }

    this.checkPermissions(video);

    if (producer != null && !producer.closed) {
      if (producer.track != null) {
        producer.track.stop();
      }

      let stream;
      try {
        stream = streamProvider.source;
        if (stream == null) {
          this.ctx.logger.error("Stream provider returned null", {
            ...this.logData,
            kind,
          });
          return null;
        }
      } catch (err) {
        const msg = err instanceof Error ? err.message : "unknown error";
        this.ctx.logger.warn("Unable to get media stream", {
          err: msg,
          ...this.logData,
          kind,
        });

        if (!final && revertStreamProvider != null) {
          try {
            const revertOptions = { ...options, streamType: revertStreamProviderType ?? CUSTOM_STREAM_TYPE };
            await this._setMediaStreamProvider(kind, revertStreamProvider, revertOptions, true, true, false);
          } catch (error) {
            this.emitError(new InternalError(`Unable to revert media stream: ${kind}`, {}));
          }
        }
        throw err;
      }

      let track: MediaStreamTrack | undefined;
      if (video) {
        [track] = stream.getVideoTracks();
      } else {
        [track] = stream.getAudioTracks();
      }

      this.ctx.logger.info("replacing producer track", {
        ...this.logData,
        kind,
        trackLabel: track?.label,
        trackState: track?.readyState,
        settings: track?.getSettings != null ? track.getSettings() : (null as any),
        capabilities: track?.getCapabilities != null ? track.getCapabilities() : (null as any),
        constraints: track?.getConstraints != null ? track.getConstraints() : (null as any),
      });

      if (track == null) {
        this.ctx.logger.error("stream has no tracks of this kind", {
          ...this.logData,
          kind,
          stream: stream as any,
        });
        throw new Error("Unable to get track");
      }

      await producer.replaceTrack({ track });

      if (producer.paused) {
        producer.resume();
        this.call._resumeProducer(producer.id, this.streamName);
      }

      this.call.emit("CALL_SET_PRODUCER_TRACK", {
        streamName: this.streamName,
        streamType: options.streamType ?? CUSTOM_STREAM_TYPE,
        producerId: producer.id,
        track,
      });
      return producer;
    }

    this.ctx.logger.info("cannot replaceTrack, so resetting producer", {
      ...this.logData,
    });

    try {
      producer = await this._setProducer(kind, options, preview);
    } catch (error) {
      this.ctx.logger.warn("unable to set new producer", {
        ...this.logData,
        kind,
      });
    }
    return producer;
  }

  async _setProducer(
    kind: types.MediaKind,
    options: PeerProducerOptions,
    preview = false,
    hotswap = false,
  ): Promise<types.Producer> {
    const video = kind === VIDEO;

    this.checkPermissions(video);

    let stream: MediaStream;
    try {
      if (video) {
        if (!hotswap && this._videoProducer?.track != null) {
          this._videoProducer.track.stop();
        }
        if (this._videoStreamProvider == null) {
          this.throwError(
            new RetrievingMediaStreamError("videoStreamProvider is not provided", {
              streamName: this.logData.streamName,
              mediaKind: kind,
            }),
          );
        }
        const s = this._videoStreamProvider.source;
        if (s == null) {
          this.throwError(
            new RetrievingMediaStreamError("videoStreamProvider returned null", {
              streamName: this.logData.streamName,
              mediaKind: kind,
            }),
          );
        }
        stream = s;
        this.ctx.logger.debug("retrieve a new video stream", { stream: stream as any });
      } else {
        if (!hotswap && this._audioProducer?.track != null) {
          this._audioProducer.track.stop();
        }
        if (this._audioStreamProvider == null) {
          this.throwError(
            new RetrievingMediaStreamError("audioStreamProvider is not provided", {
              streamName: this.logData.streamName,
              mediaKind: kind,
            }),
          );
        }
        const s = this._audioStreamProvider.source;
        if (s == null) {
          this.throwError(
            new RetrievingMediaStreamError("audioStreamProvider returned null", {
              streamName: this.logData.streamName,
              mediaKind: kind,
            }),
          );
        }
        stream = s;
        this.ctx.logger.debug("retrieve a new audio stream", { stream: stream as any });
      }
    } catch (err) {
      const msg = err instanceof Error ? err.message : "unknown error";
      this.ctx.logger.warn("Unable to get media stream", {
        err: msg,
        ...this.logData,
        kind,
      });
      throw err;
    }

    let switched = false;
    let track: MediaStreamTrack | undefined;
    let producer: types.Producer;
    if (video) {
      [track] = stream.getVideoTracks();
      const appData: AppData = {
        streamName: this.streamName,
        userId: this.call.user?.userId,
        trackEnabled: track?.enabled,
        displayName: this.call.user?.displayName,
      };
      if (video) {
        if (device.isImplements(Feature.SCREEN_ORIENTATION)) {
          appData.orientation = device.screenOrientation;
        }
        // if (window.screen?.orientation?.angle != null) {
        //     appData.orientation = window.screen.orientation.angle;
        // } else if (window.orientation != null) {
        //     appData.orientation = window.orientation;
        // }
      }

      if (track == null) {
        const err = new Error("stream.getVideoTracks()[0] is undefined");
        this.ctx.logger.warn("Unable to get media track", {
          err: err?.message,
          ...this.logData,
          kind,
        });
        throw err;
      }

      if (this._videoProducer && !hotswap) {
        try {
          await this._videoProducer.replaceTrack({ track });
          producer = this._videoProducer;
          switched = true;
        } catch (err) {
          const msg = err instanceof Error ? err.message : "unknown error";
          this.ctx.logger.warn("Unable to replace track", {
            err: msg,
            ...this.logData,
            kind,
          });
          producer = await this.call.createProducer(track, options, appData);
        }
      } else {
        producer = await this.call.createProducer(track, options, appData);
      }
    } else {
      const appData: AppData = {
        streamName: this.streamName,
        userId: this.call.user?.userId,
        displayName: this.call.user?.displayName,
      };
      let parallelTrack;
      // send from stream to this._gainNode
      if (
        this._useEchoDetection &&
        producerAudioContext?.createMediaStreamSource != null &&
        producerAudioContext?.createMediaStreamDestination != null &&
        this._gainNode != null
      ) {
        const mediaStreamSource = producerAudioContext.createMediaStreamSource(stream);
        mediaStreamSource.connect(this._gainNode);

        // send from this._gainNode to a new destination stream
        const dest = producerAudioContext.createMediaStreamDestination();
        this._gainNode.connect(dest);

        [track] = dest.stream.getAudioTracks();
        [parallelTrack] = stream.getAudioTracks();
      } else {
        [track] = stream.getAudioTracks();
      }

      if (track == null) {
        const err = new Error("stream.getAudioTracks()[0] is undefined");
        this.ctx.logger.warn("Unable to get media track", {
          err: err?.message,
          ...this.logData,
          kind,
        });
        throw err;
      }

      if (this._audioProducer && !hotswap) {
        try {
          await this._audioProducer.replaceTrack({ track });
          producer = this._audioProducer;
          switched = true;
        } catch (err) {
          const msg = err instanceof Error ? err.message : "unknown error";
          this.ctx.logger.warn("Unable to replace track", {
            err: msg,
            ...this.logData,
            kind,
          });
          producer = await this.call.createProducer(track, options, appData);
          producer.appData.parallelTrack = parallelTrack;
        }
      } else {
        producer = await this.call.createProducer(track, options, appData);
        producer.appData.parallelTrack = parallelTrack;
      }
    }

    // No need to keep original track.

    // !!! DISABLED BECAUSE NOW WE DON'T CLONE TRACKS !!!
    // track.stop();

    let oldProducer: types.Producer | null = null;
    if (video) {
      if (!switched) {
        oldProducer = this._videoProducer;
        this._videoProducer = producer;

        anomalies.watchCpuSpikes(new LoggerCore(PACKAGE_NAME).extend(this.ctx.logger), producer);

        producer.on("webrtc-anomaly", (anomaly: { message: string; cpuUsage: number; cpuUsageDuration: number }) => {
          this.call.emit("webrtc-anomaly", anomaly);
        });
      }
    } else {
      if (!switched) {
        oldProducer = this._audioProducer;
        this._audioProducer = producer;
      }
      if (this._useEchoDetection) {
        try {
          this._echoDetector = new EchoDetector(producer.track);
          this._echoDetector?.on("echo", () => {
            this.ctx.logger.debug(`echo detected at ${Date.now()}. Gain at ${this._gainNode?.gain.value}`, {
              ...this.logData,
            });
            this._reduceGain();
          });
          this._echoDetector?.on("noecho", () => {
            this.ctx.logger.debug(`echo stopped at ${Date.now()}. Gain at ${this._gainNode?.gain.value}`, {
              ...this.logData,
            });
            this._restoreGain();
          });
        } catch (err) {
          const msg = err instanceof Error ? err.message : "unknown error";
          this.ctx.logger.error("unable to set up echo detector", {
            err: msg,
            ...this.logData,
          });
        }
      }
    }

    const close = (msg: string): void => {
      this.ctx.logger.debug(msg, {
        ...this.logData,
      });

      if (producer.appData.parallelTrack != null) {
        producer.appData.parallelTrack.stop();
      }

      let producerId = "";
      if (video && this._videoProducer != null) {
        producerId = this._videoProducer.id;
        this._videoProducer = null;
      } else if (this._audioProducer != null) {
        producerId = this._audioProducer.id;
        this._audioProducer = null;
      }

      this.call._closeProducer(producerId, this.streamName);
    };
    producer.once("transportclose", () => close("producer transportclose"));
    producer.once("trackended", () => close("producer trackended"));

    if (!this.call._closed) {
      if (video) {
        this.call._removeMessage(TROUBLESHOOTING.NO_WEBCAM);

        this.call.emit("CALL_ADD_PRODUCER", {
          streamerName: this.streamName,
          producer: {
            id: producer.id,
            kind: VIDEO,
            streamName: this.streamName,
            streamType: options.streamType ?? CUSTOM_STREAM_TYPE,
            paused: producer.paused,
            track: producer.track,
            codec: producer.rtpParameters?.codecs[0]?.mimeType.split("/")[1] ?? "",
            preview,
          },
        });
      } else {
        this.call._removeMessage(TROUBLESHOOTING.NO_MIC);

        this.call.emit("CALL_ADD_PRODUCER", {
          streamerName: this.streamName,
          producer: {
            id: producer.id,
            kind: AUDIO,
            streamName: this.streamName,
            streamType: options.streamType ?? CUSTOM_STREAM_TYPE,
            paused: producer.paused,
            track: producer.track,
            codec: producer.rtpParameters?.codecs[0]?.mimeType.split("/")[1] ?? "",
            preview,
          },
        });
      }

      this.ctx.logger.debug("producer set", {
        ...this.logData,
        kind,
        producerId: producer.id,
      });
    }

    if (oldProducer != null && !oldProducer.closed) {
      oldProducer.close();
      this.call._closeProducer(oldProducer.id, this.streamName);
    }

    return producer;
  }

  disableAudio(): void {
    if (this._audioProducer == null) {
      this.ctx.logger.warn("stream.disableAudio: calling disableAudio without an audio producer", {
        ...this.logData,
      });
      return;
    }

    this.ctx.logger.debug("disableAudio", {
      ...this.logData,
    });

    try {
      this._audioProducer.close();
    } catch (err) {
      const msg = err instanceof Error ? err.message : "unknown error";
      this.ctx.logger.error("unable to close audio producer", {
        err: msg,
        ...this.logData,
      });
      throw err;
    }

    this.call._closeProducer(this._audioProducer.id, this.streamName);
    this._audioProducer = null;
  }

  pauseAudio(): void {
    if (this._audioProducer == null) {
      this.ctx.logger.warn("stream.pauseAudio: calling pauseAudio without an audio producer", {
        ...this.logData,
      });
      return;
    }

    this.ctx.logger.debug("pauseAudio", {
      ...this.logData,
    });

    this._audioProducer.pause();
    this.call._pauseProducer(this._audioProducer.id, this.streamName);
  }

  async enableAudio(options: PeerProducerOptions = {}, refresh = false, preview = false): Promise<void> {
    this.ctx.logger.debug("enableAudio", {
      ...this.logData,
    });

    /* TODO: see enableVideo note
    if (!this.call._room || !this.call._room.joined) {
        log.warn('enableAudio called before the room was joined', {
            ...this.logData,
        });
        return;
    }
    */

    if (this._audioProducer == null || this._audioProducer.closed || refresh) {
      if (this._audioStreamProvider == null) {
        const msg = "no default audio stream retriever";
        this.ctx.logger.error(msg, {
          ...this.logData,
        });
        throw new Error(msg);
      }

      try {
        const prodOptions = { ...options, streamType: this._videoStreamProviderType ?? CUSTOM_STREAM_TYPE };
        await this._setProducer(AUDIO, prodOptions, preview);
      } catch (err) {
        const msg = err instanceof Error ? err.message : "unknown error";
        this.ctx.logger.warn("unable to set up audio producer", {
          err: msg,
          ...this.logData,
        });
        throw err;
      }

      if (!this._audioProducer?.track?.enabled) {
        this.pauseAudio();
      }
      return;
    }

    this._audioProducer.resume();
    this.call._resumeProducer(this._audioProducer.id, this.streamName);
  }

  async enableVideo(options: PeerProducerOptions = {}, refresh = false, preview = false): Promise<void> {
    this.ctx.logger.debug("enableVideo", {
      ...this.logData,
      options: options as any,
      refresh,
      preview,
    });

    /* TODO: come up with a ready indicator
     * - possibley add a lock with a timeout to fix
     *   this race condition possibility
    if (!this.call._room || !this.call._room.joined) {
        log.warn('enableVideo called before the room was joined', {
            ...this.logData,
        });
        return;
    }
    */

    if (this._videoProducer == null || this._videoProducer.closed || refresh) {
      if (this._videoStreamProvider == null) {
        const msg = "no default video stream retriever";
        this.ctx.logger.error(msg, {
          ...this.logData,
        });
        throw new Error(msg);
      }

      try {
        const prodOptions = { ...options, streamType: this._videoStreamProviderType ?? CUSTOM_STREAM_TYPE };
        await this._setProducer(VIDEO, prodOptions, preview);
      } catch (err) {
        const msg = err instanceof Error ? err.message : "unknown error";
        this.ctx.logger.warn("unable to set up video producer", {
          err: msg,
          ...this.logData,
        });
        throw err;
      }

      if (!this._videoProducer?.track?.enabled) {
        this.pauseVideo();
      }
      return;
    }

    this._videoProducer.resume();
    this.call._resumeProducer(this._videoProducer.id, this.streamName);
  }

  disableVideo(): void {
    if (this._videoProducer == null) {
      this.ctx.logger.warn("stream.disableVideo: calling disable video without a video producer", {
        ...this.logData,
      });
      return;
    }

    this.ctx.logger.debug("disableVideo", {
      ...this.logData,
    });

    try {
      this._videoProducer.close();
    } catch (err) {
      const msg = err instanceof Error ? err.message : "unknown error";
      this.ctx.logger.error("unable to close video producer", {
        err: msg,
        ...this.logData,
      });
      throw err;
    }

    this.call._closeProducer(this._videoProducer.id, this.streamName);
    this._videoProducer = null;
  }

  pauseVideo(): void {
    if (this._videoProducer == null) {
      this.ctx.logger.warn("stream.pauseVideo: calling pause video without a video producer", {
        ...this.logData,
      });
      return;
    }

    this.ctx.logger.debug("pauseVideo", {
      ...this.logData,
    });

    this._videoProducer.pause();
    this.call._pauseProducer(this._videoProducer.id, this.streamName);
  }

  async hotswapProducer(kind: types.MediaKind, producerOptions: PeerProducerOptions): Promise<void> {
    try {
      if (producerOptions.streamType == null) {
        if (kind === "video") {
          producerOptions.streamType = this._videoStreamProviderType ?? CUSTOM_STREAM_TYPE;
        } else {
          producerOptions.streamType = this._audioStreamProviderType ?? CUSTOM_STREAM_TYPE;
        }
      }
      await this._setProducer(VIDEO, producerOptions, false, true);
    } catch (err) {
      const msg = err instanceof Error ? err.message : "unknown error";
      this.ctx.logger.warn("unable to hotswap producer", {
        kind,
        producerOptions,
        err: msg,
        ...this.logData,
      });
      throw err;
    }
  }

  private _reduceGain(): void {
    if (this._gainNode != null) {
      this._gainNode.gain.cancelScheduledValues(producerAudioContext?.currentTime ?? 0);
      this._gainNode.gain.exponentialRampToValueAtTime(0.5, producerAudioContext?.currentTime ?? 0);
    }
  }

  private _restoreGain(): void {
    if (this._gainNode != null && this._baseGainValue != null) {
      this._gainNode.gain.cancelScheduledValues(producerAudioContext?.currentTime ?? 0);
      this._gainNode.gain.exponentialRampToValueAtTime(
        this._baseGainValue,
        (producerAudioContext?.currentTime ?? 0) + 30,
      );
    }
  }

  close(sendSfuEvents = true): void {
    if (this._audioProducer != null) {
      if (sendSfuEvents) {
        this.call._closeProducer(this._audioProducer.id, this.streamName);
      }
      this._audioProducer.close();
      this._audioProducer = null;
    }

    if (this._videoProducer != null) {
      if (sendSfuEvents) {
        this.call._closeProducer(this._videoProducer.id, this.streamName);
      }
      this._videoProducer.close();
      this._videoProducer = null;
    }

    if (this._echoDetector != null) {
      this._echoDetector.close();
      this._echoDetector = null;
    }
  }

  toJSON(): Json {
    return {
      audioProducer:
        this.audioProducer == null
          ? null
          : {
              id: this.audioProducer.id,
              closed: this.audioProducer.closed,
              paused: this.audioProducer.paused,
              trackId: this.audioProducer.track?.id,
            },
      videoProducer:
        this.videoProducer == null
          ? null
          : {
              id: this.videoProducer.id,
              closed: this.videoProducer.closed,
              paused: this.videoProducer.paused,
              trackId: this.videoProducer.track?.id,
            },
      aggregates: {
        support: this.ctx.support.hash,
        ...extractAggregates(this.call),
        streamName: this.streamName,
      },
    };
  }
}

export default Stream;
