/* eslint-disable no-constructor-return */
import type { LoggerCore } from "@livelyvideo/log-client";
import { BitrateSwitchingEvents, Json, Quality, Serializable, SourceScoreLevel, TranscodeScoreLevel } from "../../api";
import type { CallAPI, SFUOptions } from "../../api/call";
import type { ManifestFormats, ManifestJson } from "../../api/manifest";
import type { PlayerAPI, PlayerEvents } from "../../api/player";
import type { BitrateSwitchingFeature } from "../../api/player/features/bitrate-switching";
import type { BroadcastingFeature } from "../../api/player/features/broadcast";
import type { ConsumerEvents, ConsumerFeature } from "../../api/player/features/consumer";
import { Feature, Features } from "../../api/player/features/feature";
import { MutedAutoplayEvents, MutedAutoplayFeature } from "../../api/player/features/muted-autoplay";
import type { VideoElement } from "../../api/typings/video-element";
import { JoinCallOptions } from "../../api/video-client";
import { NotSupportedError } from "../errors";
import MediaLoader from "../media-loader";
import { Join } from "../pvc/call";
import { makeBounded } from "../utils/bind";
import { supportsMediasoupWebrtc } from "../utils/browser-support/browser";
import { onceCanceled } from "../utils/context/context";
import type { VcContext } from "../utils/context/vc-context";
import { ObservableEventEmitter } from "../utils/events/event-emitter";
import { extendContext } from "../utils/logger";
import { implementation } from "../utils/proxy/sync-handler";
import { stats, STATS_EVENTS, TimingStat } from "../utils/stats";
import { VideoClient } from "../video-client";
import type { CorePlayerOptions } from "./core";
import { proxyHandler } from "./core";
import { MediasoupPlayer } from "./mediasoup";

export interface WebrtcPlayerEvents extends PlayerEvents, BitrateSwitchingEvents, ConsumerEvents, MutedAutoplayEvents {
  /**
   * @description Is emitted when the call endpoint is hit.
   * @example player.on("joinCallEndpoint", (stat) => { console.log(stat)})
   */
  joinCallEndpoint: TimingStat;
  /**
   * @description Is emitted when connection is made with the sfu.
   * @example player.on("sfuConnection", (stat) => { console.log(stat)})
   */
  sfuConnection: TimingStat;
  /**
   * @description Is emitted once the call receives a video consumer.
   * @example player.on("videoConsumer", (stat) => { console.log(stat)})
   */
  videoConsumer: TimingStat;
  /**
   * @description Is emitted when time update is sent for the first time.
   * @example player.on("firstTimeUpdate", (stat) => { console.log(stat)})
   */
  firstTimeUpdate: TimingStat;
}

export type WebrtcPlayerOptions = CorePlayerOptions;

export class WebrtcPlayer
  extends ObservableEventEmitter<WebrtcPlayerEvents>
  implements
    PlayerAPI,
    ConsumerFeature,
    MutedAutoplayFeature,
    BitrateSwitchingFeature,
    Serializable,
    BroadcastingFeature
{
  // Make sure all fields from this region are not initialized (don't have default value)
  // because it will be used from a real player implementation through SyncHandler
  //#region Proxy Properties
  [implementation]!: PlayerAPI | null;

  readonly attached?: boolean;

  autoPlay?: boolean;

  driverFailover?: boolean;

  localAudioMuted?: boolean;

  localAudioVolume?: number;

  localVideoPaused?: boolean;

  poster?: string | null;

  hostEl!: VideoElement | null;

  attachTo(el: VideoElement): void {
    this.hostEl = el;
  }

  isSupported!: () => Promise<boolean>;

  getClosestQuality!: () => Quality | null;

  // bitrate switch feature
  readonly availableQualities!: Quality[];

  readonly currentQuality!: Quality | null;

  preferredLevel!: TranscodeScoreLevel | SourceScoreLevel;

  setPreferredLevel(level: TranscodeScoreLevel | SourceScoreLevel): void {
    this.preferredLevel = level;
  }

  // consumer feature
  readonly consumerAudioEnabled!: boolean;

  readonly consumerVideoEnabled!: boolean;

  readonly streamName!: string;

  // broadcast feature
  get canBroadcast(): boolean {
    return this.call != null;
  }

  get callBroadcast(): CallAPI | null {
    return this.call;
  }

  // muted autoplay
  forcedMute!: boolean;

  initVideoEl!: (el: VideoElement) => Promise<void>;
  //#endregion

  get currentPlayer(): PlayerAPI | null {
    return this[implementation];
  }

  set currentPlayer(player: PlayerAPI | null) {
    this[implementation] = player;
  }

  static readonly displayName = "WebrtcPlayer";

  get logger(): LoggerCore {
    return this.ctx.logger;
  }

  private call: CallAPI | null = null;

  private manifestJson: ManifestJson | null = null;

  private readonly ctx: VcContext;

  private readonly mediaLoader: MediaLoader;

  private readonly options: CorePlayerOptions;

  private readonly videoEl: VideoElement | null = null;

  private callStreamName: string | null = null;

  private callId: string | null = null;

  private isJoiningCall = false;

  private readonly joinCallEndpointLoadId: number;

  private sfuConnectionLoadId = 0;

  private videoConsumerLoadId = 0;

  private firstTimeUpdateLoadId = 0;

  static async isSupported(logger?: LoggerCore): Promise<boolean> {
    return supportsMediasoupWebrtc("Webrtc", logger);
  }

  isImplements<K extends keyof Features, T extends Features[K]>(feature: K): this is this & T {
    switch (feature) {
      case Feature.MUTED_AUTOPLAY:
      case Feature.CONSUMER:
      case Feature.BROADCAST:
      case Feature.BITRATE_SWITCHING:
        return true;
      default:
        return false;
    }
  }

  static get format(): keyof ManifestFormats {
    return "webrtc";
  }

  get format(): keyof ManifestFormats {
    return WebrtcPlayer.format;
  }

  constructor(ctx: VcContext, provider: MediaLoader, options: CorePlayerOptions) {
    super(false);
    this.joinCallEndpointLoadId = stats.start(STATS_EVENTS.JOIN_CALL_ENDPOINT);

    this.ctx = ctx;
    this.mediaLoader = provider;
    this.options = options;
    this.autoPlay = options.autoPlay != null ? options.autoPlay : true;

    onceCanceled(ctx).then((reason) => this.dispose(`WebrtcPlayer Context Cancelled: ${reason}`));

    // don't initialize any properties form Proxy region
    // before next line, otherwise they will be shadowed

    const self = makeBounded(new Proxy(this, proxyHandler));
    // we replaced context so between this moment
    // and the end of the constructor we should use `self`
    // instead of `this`

    self.init();

    ctx.logger.attachObject(self);
    ctx.logger.trace("constructor()", {});

    return self;
  }

  // noinspection JSUnusedLocalSymbols
  private init(): void {
    this.mediaLoader.on("source", this.handleSource);
    this.addInnerDisposer((reason) => {
      this.mediaLoader.off("source", this.handleSource);
    });

    if (this.mediaLoader.source != null) {
      this.handleSource(this.mediaLoader.source);
    }
  }

  private async handleSource(manifest: ManifestJson | null): Promise<void> {
    this.ctx.logger.debug("webrtc: handleSource()");

    this.manifestJson = manifest;
    if (manifest == null) {
      this.stopCall();
    } else {
      const { webrtc } = manifest.formats;
      if (webrtc == null) {
        this.emitError(new NotSupportedError("manifest doesn't contains webrtc format", {}));
        return;
      }

      if (
        !this.isJoiningCall ||
        this.callId !== webrtc.origin.callId ||
        this.callStreamName !== webrtc.origin.streamNames[0]
      ) {
        this.isJoiningCall = true;
        this.callId = webrtc.origin.callId;

        this.callStreamName = webrtc.origin.streamNames[0];

        this.stopCall();
        await this.joinCall(this.callId, webrtc.origin.streamNames[0], webrtc.origin.token, {
          rsrc: webrtc.origin.rsrc,
          xkey: webrtc.origin.publicKey,
          bpeerId: webrtc.origin.peerId,
          joinUrl: webrtc.origin.location,
        });
      }
    }
  }

  private stopCall(): void {
    if (this.call != null) {
      this.call?.removeAllListeners("playerAdded");
      this.call = null;
    }
  }

  private get sfuJoinParams(): Required<Join> | null {
    const { webrtc } = this.manifestJson?.formats ?? {};
    if (webrtc?.origin.uri == null) {
      return null;
    }

    return {
      call: {
        id: webrtc.origin.callId,
        sfu: {
          uri: webrtc.origin.uri,
          httpUri: webrtc.origin.httpUri,
          region: webrtc.origin.region,
          version: webrtc.origin.version,
        },
        turn: webrtc.origin.turn,
        support: "ovh",
      },
      user: {
        userId: webrtc.origin.token,
        scope: "viewer",
        displayName: "",
        authorizeToken: webrtc.origin.token,
      },
    };
  }

  private async joinCall(callId: string, streamName: string, token: string, sfuOptions: SFUOptions): Promise<void> {
    const statInfo = {
      driver: this.format,
      abr: this.manifestJson?.abr,
      aor: this.manifestJson?.aor,
      atr: this.manifestJson?.atr,
      rep: this.manifestJson?.rep,
    };

    const joinCallOptions: JoinCallOptions = {
      token,
      onCallJoinEndpoint: () => {
        this.emit("joinCallEndpoint", stats.stop(this.joinCallEndpointLoadId, statInfo));
        this.sfuConnectionLoadId = stats.start(STATS_EVENTS.SFU_CONNECTION);
      },
      onSFUConnection: () => {
        this.emit("sfuConnection", stats.stop(this.sfuConnectionLoadId, statInfo));
        this.videoConsumerLoadId = stats.start(STATS_EVENTS.VIDEO_CONSUMER);
      },
      playerOptions: this.options,
    };

    this.call = await this.ctx.videoClient.internalJoinCall(
      callId,
      { ...sfuOptions, ...joinCallOptions },
      extendContext(this.ctx, VideoClient),
      this.sfuJoinParams,
    );

    this.call.once("videoConsumer", () => {
      this.emit("videoConsumer", stats.stop(this.videoConsumerLoadId, statInfo));
      this.firstTimeUpdateLoadId = stats.start(STATS_EVENTS.FIRST_TIME_UPDATE);
    });
    this.call.on("playerAdded", (ev) => {
      this.ctx.logger.debug("got a player", { ev });
      if (streamName !== ev.streamName) {
        return;
      }
      this.ctx.logger.debug("set currentPlayer", { old: this.currentPlayer });
      if (ev.player instanceof MediasoupPlayer) {
        ev.player.isManifestPlayer = true;
      }
      this.currentPlayer = ev.player;
      this.currentPlayer.once("videoFirstPlay", () => {
        this.emit("firstTimeUpdate", stats.stop(this.firstTimeUpdateLoadId, statInfo));
      });
    });
    this.call.on("webrtcStats", (ev) => {
      this.emit("webrtcStats", ev);
    });

    this.call.on("playerRemoved", (ev) => {
      if (streamName !== ev.streamName) {
        return;
      }
      this.currentPlayer?.dispose("peer player removed due to call.on('playerRemoved') event");
    });
  }

  toJSON(): Json {
    return this.currentPlayer?.toJSON() ?? {};
  }
}
