/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Generic video client error
 * All other video client errors are inherited
 * from this class
 */
import type { LoggerCore } from "@livelyvideo/log-client";
import { Aggregates, extractAggregates, Json } from "@livelyvideo/log-node";
import st from "stacktrace-js";
import { BroadcastAPI, CallAPI, ErrorCode, IVideoClientError, ManifestJson, MediaStreamControllerAPI } from "../api";
import type { MediaStreamConstraints } from "../api/adapter/features/media-device";
import type { MediaTrackConstraints } from "../api/adapter/features/media-stream";
import type MediaLoader from "./media-loader";
import type { CorePlayer } from "./player/core";
import type { Call as InternalCall } from "./pvc/call/call";
import type { PeerParameters, WSConsumerSourcesResponse } from "./pvc/call/common";
import type { Peer } from "./pvc/call/peer";
import type Stream from "./pvc/call/stream";
import { filterStacktrace, formatStacktrace } from "./utils/debug";

export { ErrorCode } from "../api";

// convert class to plain object
type Context<E> = Omit<
  Pick<E, keyof E>,
  "toJSON" | "code" | "stack" | "ppStack" | "message" | "detailed" | "name" | "handled" | "aggregates"
>;
const excludeFields = new Set<PropertyKey>([
  "toJSON",
  "ppStack",
  "stack",
  "detailed",
  "handled",
  "inner",
  "aggregates",
]);

function serialize(err: Error): Json {
  return {
    err: err.name ?? err.constructor.name,
    message: err.message,
    stack: err.stack,
  };
}

export function wrapNativeError(err: unknown): NativeError {
  if (err instanceof Error) {
    return new NativeError(err.message, { native: err });
  }
  return new NativeError(String(err), {});
}

const vdcErrPrefix = "Video Client Error: ";

function concatMessages(obj: any): string {
  if (obj == null || typeof obj !== "object" || typeof obj.message !== "string") {
    return "";
  }
  const code = obj.code == null ? "; " : `; [${String(obj.code)} `;
  return `${code}${obj.message}${concatMessages(obj.inner)}`;
}

/**
 * Generic VideoClient error. All errors VDC errors inherits this class
 */
export abstract class VideoClientError<C extends VideoClientError<any> = VideoClientError<any>>
  extends Error
  implements IVideoClientError
{
  abstract readonly code: ErrorCode;

  readonly inner?: Error | null = null;

  readonly critical?: boolean;

  readonly #message: string;

  #ppStack: string | null = null;

  public handled = false;

  constructor(message: string, ctx: Context<C>) {
    super();
    this.#message = message;

    const { inner, critical, ...other } = ctx;
    this.inner = inner;
    this.critical = critical ?? (inner instanceof VideoClientError ? inner.critical : false);

    for (const [key, val] of Object.entries(other) as Array<[keyof this, any]>) {
      this[key] = val;
    }
  }

  get message(): string {
    let inMsg = this.inner?.message;
    if (inMsg != null) {
      if (inMsg.startsWith(vdcErrPrefix)) {
        inMsg = inMsg.slice(vdcErrPrefix.length);
      }
      return `${vdcErrPrefix}[${this.code}] ${this.#message}; ${inMsg}`;
    }
    if (Reflect.has(this, "err")) {
      return `${vdcErrPrefix}[${this.code}] ${this.#message}${concatMessages(Reflect.get(this, "err"))}`;
    }

    return `${vdcErrPrefix}[${this.code}] ${this.#message}`;
  }

  private static async loadPrettyPrintStacktrace(err: VideoClientError): Promise<void> {
    if (err.inner instanceof VideoClientError) {
      await VideoClientError.loadPrettyPrintStacktrace(err.inner);
    }
    return st.fromError(err).then((stackFrames) => {
      const filteredSt = filterStacktrace(stackFrames);
      err.#ppStack = formatStacktrace(filteredSt);
    });
  }

  static log(err: IVideoClientError, logger: LoggerCore): void {
    VideoClientError.loadPrettyPrintStacktrace(err as VideoClientError).finally(() => {
      logger.error(err.message, { err });
    });
  }

  toJSON(): Json {
    const json: Json = {
      message: this.message,
      stack: this.#ppStack ?? this.stack ?? null,
      inner: this.inner == null || "toJSON" in this.inner ? this.inner : serialize(this.inner),
      aggregates: this.aggregates(),
    };

    for (const k of Object.keys(this) as Array<keyof this>) {
      if (!excludeFields.has(k)) {
        json[k.toString()] = this[k] as any;
      }
    }

    return json;
  }

  aggregates(): Aggregates {
    if (this.inner instanceof VideoClientError) {
      return {
        ...this.inner.aggregates(),
        errCode: this.code,
        errCritical: this.critical ?? false,
        errHandled: this.handled,
      };
    }
    return {
      errCode: this.code,
      errCritical: this.critical ?? false,
      errHandled: this.handled,
    };
  }
}

export class NativeError extends VideoClientError<NativeError> {
  readonly code = ErrorCode.NativeError;

  native?: unknown;
}

export class InternalError extends VideoClientError<InternalError> {
  readonly code = ErrorCode.InternalError;
}

export class NotInitializedError extends VideoClientError<NotInitializedError> {
  readonly code = ErrorCode.NotInitialized;
}

export class NotSupportedError extends VideoClientError<NotSupportedError> {
  readonly code = ErrorCode.NotSupported;
}

export class ValidationError extends VideoClientError<ValidationError> {
  readonly code = ErrorCode.ValidationError;
}

export class InternalCallError extends VideoClientError<InternalCallError> {
  readonly code = ErrorCode.InternalCallError;

  readonly internalCall?: InternalCall;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.internalCall),
    };
  }
}

/**
 * Can be thrown if some permissions were declined
 */
export class PermissionsError extends VideoClientError<PermissionsError> {
  readonly code = ErrorCode.Permission;

  readonly permissions!: Readonly<string[]>;

  readonly stream?: Stream;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.stream),
    };
  }
}

/**
 * Generic network error. Can be thrown on any network related issue
 */
export class NetworkError extends VideoClientError<NetworkError> {
  readonly code = ErrorCode.NetworkError;

  readonly status?: number;

  readonly data?: any;

  // readonly eventTargetCode?: number;

  aggregates(): Aggregates {
    return { ...super.aggregates(), errStatus: this.status ?? 0 };
  }
}

/**
 * Can be thrown when a media request is failed due Constraints error
 */
export class ConstraintsError extends VideoClientError<ConstraintsError> {
  readonly code = ErrorCode.Constraints;

  readonly constraints!: MediaTrackConstraints | MediaStreamConstraints;
}

/**
 * Occurred in MediaStreamController when media device not found
 */
export class DeviceNotFoundError extends VideoClientError<DeviceNotFoundError> {
  readonly code = ErrorCode.DeviceNotFound;

  readonly msc!: MediaStreamControllerAPI;
}

export class CapturableStreamError extends VideoClientError<CapturableStreamError> {
  readonly code = ErrorCode.CapturableStreamError;

  readonly msc!: MediaStreamControllerAPI;
}

/**
 * Can be thrown in MediaStreamController if MediaTrack has ended unexpectedly
 */
export class TrackEndedError extends VideoClientError<TrackEndedError> {
  readonly code = ErrorCode.TrackEnded;

  readonly msc!: MediaStreamControllerAPI;

  readonly trackId!: string;

  readonly kind!: string;
}

/**
 * Generic Call error
 */
export class CallError extends VideoClientError<CallError> {
  readonly code = ErrorCode.CallError;

  readonly reason!: string;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      reason: this.reason,
    };
  }
}

export class JoinCallError extends VideoClientError<JoinCallError> {
  readonly code = ErrorCode.JoinCallError;

  readonly call?: CallAPI;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.call),
    };
  }
}

/**
 * Generic Broadcast error
 */
export abstract class BroadcastError<T extends VideoClientError> extends VideoClientError<T> {
  readonly broadcast!: BroadcastAPI;

  readonly call?: CallAPI;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.broadcast),
    };
  }
}

export class StartBroadcastError extends BroadcastError<StartBroadcastError> {
  readonly code = ErrorCode.StartBroadcastError;

  readonly call?: CallAPI;
}

export class UpdateBroadcastError extends BroadcastError<StartBroadcastError> {
  readonly code = ErrorCode.UpdateBroadcastError;

  readonly call?: CallAPI;
}

export class StreamExistsError extends BroadcastError<StreamExistsError> {
  readonly code = ErrorCode.StreamExists;
}

export class StreamNotFoundError extends BroadcastError<StreamNotFoundError> {
  readonly code = ErrorCode.StreamNotFound;
}

export class EnableVideoError extends BroadcastError<EnableVideoError> {
  readonly code = ErrorCode.EnableVideoError;
}

export class EnableAudioError extends BroadcastError<EnableAudioError> {
  readonly code = ErrorCode.EnableAudioError;
}

export class DisableVideoError extends BroadcastError<DisableVideoError> {
  readonly code = ErrorCode.DisableVideoError;
}

export class DisableAudioError extends BroadcastError<DisableAudioError> {
  readonly code = ErrorCode.DisableAudioError;
}

/**
 * Can be thrown when manifest is not loaded by some reason
 */
export class ManifestError extends VideoClientError<ManifestError> {
  readonly code = ErrorCode.ManifestError;

  readonly loader?: MediaLoader;

  readonly manifest?: ManifestJson;

  readonly url?: string;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.loader),
    };
  }
}

export class ManifestBadInputError extends VideoClientError<ManifestBadInputError> {
  readonly code = ErrorCode.BadInput;
}

export class DriverNotSupportedError extends VideoClientError<DriverNotSupportedError> {
  readonly code = ErrorCode.DriverNotSupported;

  readonly manifest?: ManifestJson | null | string;

  readonly loader?: MediaLoader;

  readonly eventTargetCode?: number;

  readonly data?: any;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.loader),
    };
  }
}

export class ElementRequiredError extends VideoClientError<ElementRequiredError> {
  readonly code = ErrorCode.ElementRequired;
}

export class EmbedSWFFailedError extends VideoClientError<EmbedSWFFailedError> {
  readonly code = ErrorCode.EmbedSWFFailed;
}

export class GetUserMediaFailedError extends VideoClientError<GetUserMediaFailedError> {
  readonly code = ErrorCode.GetUserMediaFailed;
}

export class ManifestUnexpectedResponseError extends VideoClientError<ManifestUnexpectedResponseError> {
  readonly code = ErrorCode.ManifestUnexpectedResponse;
}

export class ManifestUnauthorizedError extends VideoClientError<ManifestUnauthorizedError> {
  readonly code = ErrorCode.ManifestUnauthorized;
}

export class ManifestForbiddenError extends VideoClientError<ManifestForbiddenError> {
  readonly code = ErrorCode.ManifestForbidden;
}

export class ManifestInternalError extends VideoClientError<ManifestInternalError> {
  readonly code = ErrorCode.ManifestInternalError;
}

export class ManifestNotFoundError extends VideoClientError<ManifestNotFoundError> {
  readonly code = ErrorCode.ManifestNotFound;
}

export class InvalidControlsError extends VideoClientError<InvalidControlsError> {
  readonly code = ErrorCode.InvalidControls;
}

export class InvalidMediaURLError extends VideoClientError<InvalidMediaURLError> {
  readonly code = ErrorCode.InvalidMediaURL;
}

export class InvalidPopoutURLError extends VideoClientError<InvalidPopoutURLError> {
  readonly code = ErrorCode.InvalidPopoutURL;
}

export class InvalidElementError extends VideoClientError<InvalidElementError> {
  readonly code = ErrorCode.InvalidElement;
}

export class WSNetworkError extends VideoClientError<WSNetworkError> {
  readonly code = ErrorCode.WSNetworkError;
}

export class NoDriversError extends VideoClientError<NoDriversError> {
  readonly code = ErrorCode.NoDrivers;
}

export class PlaybackError extends VideoClientError<PlaybackError> {
  readonly code = ErrorCode.PlaybackError;

  readonly data?: any;

  readonly loader?: MediaLoader | null;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.loader),
    };
  }
}

export class UnknownDriverError extends VideoClientError<UnknownDriverError> {
  readonly code = ErrorCode.UnknownDriver;
}

export class UnknownError extends VideoClientError<UnknownError> {
  readonly code = ErrorCode.UnknownError;

  readonly data?: any;
}

export class UnrecognizedDriverError extends VideoClientError<UnrecognizedDriverError> {
  readonly code = ErrorCode.UnrecognizedDriver;
}

export class UserRequiredError extends VideoClientError<UserRequiredError> {
  readonly code = ErrorCode.UserRequired;
}

export class InvalidBitrateError extends VideoClientError<InvalidBitrateError> {
  readonly code = ErrorCode.InvalidBitrate;
}

export class HlsjsNotLoadedError extends VideoClientError<HlsjsNotLoadedError> {
  readonly code = ErrorCode.HlsjsNotLoaded;
}

/**
 * Can be thrown when unable to get or switch a webrtc layer
 */
export class LayerNotFoundError extends VideoClientError<LayerNotFoundError> {
  readonly code = ErrorCode.LayerNotFound;

  readonly layerId!: string | number;
}


/**
 * Can be thrown when consumer is not found
 */
 export class ConsumerNotFoundError extends VideoClientError<ConsumerNotFoundError> {
  readonly code = ErrorCode.ConsumerNotFound;

  readonly consumerId!: string;
}

/**
 * Can be thrown when HTMLVideoElement unable to play the stream
 */
export class PlayingIssueError extends VideoClientError<PlayingIssueError> {
  readonly code = ErrorCode.PlayingIssue;

  readonly player!: CorePlayer;

  readonly playImmediately?: boolean;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.player),
    };
  }
}

export class MeowDriverError extends VideoClientError<MeowDriverError> {
  readonly code = ErrorCode.MeowDriverError;
}

/**
 * Can be thrown if there was called a method from a disposed object
 */
export class DisposedObjectError extends VideoClientError<DisposedObjectError> {
  readonly code = ErrorCode.DisposedObject;

  readonly className!: string;

  readonly method!: string;
}

/**
 * Can be thrown if MediaStreamController didn't provide MediaStream while supposed to
 */
export class RetrievingMediaStreamError extends VideoClientError<RetrievingMediaStreamError> {
  readonly code = ErrorCode.RetrievingMediaStreamError;

  readonly streamName!: string;

  readonly mediaKind!: string;

  aggregates(): Aggregates {
    return { ...super.aggregates(), streamName: this.streamName, mediaKind: this.mediaKind };
  }
}

export class SFUNewPeersEventError extends VideoClientError<SFUNewPeersEventError> {
  readonly code = ErrorCode.SFUNewPeersEvent;

  readonly peer!: Peer;

  readonly request!: { peers: PeerParameters[] };

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.peer),
    };
  }
}

export class SFUConsumerSourcesEventError extends VideoClientError<SFUConsumerSourcesEventError> {
  readonly code = ErrorCode.SFUConsumerSourcesEvent;

  readonly peer!: Peer;

  readonly request!: WSConsumerSourcesResponse;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.peer),
    };
  }
}

export class SFUNewConsumerEventError extends VideoClientError<SFUNewConsumerEventError> {
  readonly code = ErrorCode.SFUNewConsumerEvent;

  readonly peer!: Peer;

  readonly request!: Json;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.peer),
    };
  }
}

export class SFUPeerClosedEventError extends VideoClientError<SFUPeerClosedEventError> {
  readonly code = ErrorCode.SFUPeerClosedEvent;

  readonly peer!: Peer;

  readonly request!: Json;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.peer),
    };
  }
}

export class SFUSwitchConsumerTrackEventError extends VideoClientError<SFUSwitchConsumerTrackEventError> {
  readonly code = ErrorCode.SFUSwitchConsumerTrackEvent;

  readonly peer!: Peer;

  readonly request!: Json;

  readonly consumerId?: string;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.peer),
    };
  }
}

export class SFUConsumerClosedEventError extends VideoClientError<SFUConsumerClosedEventError> {
  readonly code = ErrorCode.SFUConsumerClosedEvent;

  readonly peer!: Peer;

  readonly request!: Json;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.peer),
    };
  }
}

export class SFUConsumerPausedEventError extends VideoClientError<SFUConsumerPausedEventError> {
  readonly code = ErrorCode.SFUConsumerPausedEvent;

  readonly peer!: Peer;

  readonly request!: Json;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.peer),
    };
  }
}

export class SFUConsumerResumedEventError extends VideoClientError<SFUConsumerResumedEventError> {
  readonly code = ErrorCode.SFUConsumerResumedEvent;

  readonly peer!: Peer;

  readonly request!: Json;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.peer),
    };
  }
}

export class SFUConsumerScoreEventError extends VideoClientError<SFUConsumerScoreEventError> {
  readonly code = ErrorCode.SFUConsumerScoreEvent;

  readonly peer!: Peer;

  readonly request!: Json;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.peer),
    };
  }
}

export class SFUConsumerLayersChangedEventError extends VideoClientError<SFUConsumerLayersChangedEventError> {
  readonly code = ErrorCode.SFUConsumerLayersChangedEvent;

  readonly peer!: Peer;

  readonly request!: Json;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.peer),
    };
  }
}

export class SFUProducerClosedEventError extends VideoClientError<SFUProducerClosedEventError> {
  readonly code = ErrorCode.SFUProducerClosedEvent;

  readonly peer!: Peer;

  readonly request!: Json;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.peer),
    };
  }
}

export class SFUProducerPausedEventError extends VideoClientError<SFUProducerPausedEventError> {
  readonly code = ErrorCode.SFUProducerPausedEvent;

  readonly peer!: Peer;

  readonly request!: Json;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.peer),
    };
  }
}

export class SFUProducerResumedEventError extends VideoClientError<SFUProducerResumedEventError> {
  readonly code = ErrorCode.SFUProducerResumedEvent;

  readonly peer!: Peer;

  readonly request!: Json;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.peer),
    };
  }
}

export class WSRequestError extends VideoClientError<WSRequestError> {
  readonly code = ErrorCode.WSRequestError;

  readonly internalCall!: InternalCall;

  readonly request!: string;

  readonly args: any;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.internalCall),
    };
  }
}

export class MediasoupSetupError extends VideoClientError<MediasoupSetupError> {
  readonly code = ErrorCode.MediasoupSetupError;
}

export class MediaError extends VideoClientError<MediaError> {
  readonly code = ErrorCode.MediaError;

  readonly eventTargetCode?: number;
}

export class TransportStateError extends VideoClientError<TransportStateError> {
  readonly code = ErrorCode.TransportStateError;
}

export class MediaRecorderError extends VideoClientError<MediaRecorderError> {
  readonly code = ErrorCode.MediaRecorderError;

  readonly mimeType?: string;
}

export class UpdateMSCError extends VideoClientError<UpdateMSCError> {
  readonly code = ErrorCode.UpdateMSCError;

  readonly msc!: MediaStreamControllerAPI;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.msc),
    };
  }
}

export class ChangeMSCDeviceError extends VideoClientError<ChangeMSCDeviceError> {
  readonly code = ErrorCode.UpdateMSCError;

  readonly msc!: MediaStreamControllerAPI;

  readonly prevVideoDeviceId?: string;

  readonly prevVideoDeviceLabel?: string;

  readonly failedVideoDeviceId?: string;

  readonly failedVideoDeviceLabel?: string;

  readonly prevAudioDeviceId?: string;

  readonly prevAudioDeviceLabel?: string;

  readonly failedAudioDeviceId?: string;

  readonly failedAudioDeviceLabel?: string;

  aggregates(): Aggregates {
    return {
      ...super.aggregates(),
      ...extractAggregates(this.msc),
      prevVideoDeviceId: this.prevVideoDeviceId ?? "",
      prevVideoDeviceLabel: this.prevVideoDeviceLabel ?? "",
      failedVideoDeviceId: this.failedVideoDeviceId ?? "",
      failedVideoDeviceLabel: this.failedVideoDeviceLabel ?? "",
      prevAudioDeviceId: this.prevAudioDeviceId ?? "",
      prevAudioDeviceLabel: this.prevAudioDeviceLabel ?? "",
      failedAudioDeviceId: this.failedAudioDeviceId ?? "",
      failedAudioDeviceLabel: this.failedAudioDeviceLabel ?? "",
    };
  }
}

export class Mp4BufferError extends VideoClientError<Mp4BufferError> {
  readonly code = ErrorCode.Mp4BufferError;
}

export class HandleHlsJsError extends VideoClientError<HandleHlsJsError> {
  readonly code = ErrorCode.HandleHlsJsError;
}
