import WebSocketClient from "@livelyvideo/hub-websocket";
import { LoggerCore } from '@livelyvideo/log-client';
import { Json } from "../../api";

const globalScope =
    (globalThis as any) ??
    (function fn(this: any): any {
      return this;
    })();

export class ClientStats {
  private websocketOpen = false;
  private ws: WebSocketClient | null = null;

  constructor(private readonly websocketUri: string, private readonly logger: LoggerCore) {}

  openWebsocket(data: Json): void {
    // check to see if websocket is passed in
    // if not, check window and global.
    // if not, create new websocket
    this.websocketOpen = true;
    if (globalScope?.livelyWebsocketV1?.readyState > 0) {
      this.ws = globalScope.livelyWebsocketV1;
    } else {
      this.ws = new WebSocketClient({
        websocketUri: this.websocketUri,
        logger: this.logger,
        plugin: 'client-stats',
        version: 'v2'},undefined,{
          maxReconnectInterval: 15000, // 15 sec
          reconnectDecay: 1.2, // 1s, 1.44s, 1.73s, 2.07s, ...
        });
    }

    this.ws?.send("'client-stats", data);
  }

  // Send event
  push(data: Record<string, Json>) {
    // send data over websocket
    data["type"] = "client-stats";

    if (!this.websocketOpen) {
      this.openWebsocket(data);
    } else {
      this.ws?.send("client-stats", data);
    }
  }

  // Close websocket
  close() {
    if (this.ws != null) {
      this.ws.close(1005, "ClientStats is closed");
      this.ws = null;
    }
    this.websocketOpen = false;
  }
}
