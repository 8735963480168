import { player as pl, SourceScoreLevel, TranscodeScoreLevel } from "@livelyvideo/video-client-core";
import { PlayerAPI } from "@livelyvideo/video-client-core/lib/api";
import { Properties } from "csstype";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useMemo } from "react";
import { PlayerUiState } from "../../../../../store";
import { LivelyPlayerUiContext } from "../../../../context";
import { useStyles } from "../../../../styling/livelyStyles";
import PillToggle from "../../../../ui-lib/Inputs/PillToggle";
import SelectNonNative, { SelectProps } from "../../../../ui-lib/Inputs/SelectNonNative";
import { ErrorBoundary, useUndefinedStoreError } from "../../../ErrorBoundary";
import styles from "./styles";

interface QualitySettingsClasses {
  root?: Properties;
  option?: Properties;
  activeOption?: Properties;
  disabledOption?: Properties;
}

type QualitySettingsProps = Omit<SelectProps, "classes"> & {
  classes?: QualitySettingsClasses;
  showBitrate?: boolean;
  disableToggle?: boolean;
  disableSelect?: boolean;
  active?: boolean;
};

type AvailableBitrate = {
  name: string;
  score: TranscodeScoreLevel | SourceScoreLevel;
};

const fallbackBitrates = [
  {
    name: "Source",
    score: null,
  },
  {
    name: "High",
    score: null,
  },
  {
    name: "Medium",
    score: null,
  },
  {
    name: "Low",
    score: null,
  },
];

const inactiveStyles: React.CSSProperties = {
  transition: "opacity 0.3s ease-in-out, transform 0.5s ease-in-out",
  transform: "translateY(50px)",
  opacity: 0,
  visibility: "hidden",
};

const activeStyles: React.CSSProperties = {
  transition: "opacity 0.3s ease-in-out, transform 0.5s ease-in-out",
  opacity: 1,
};

class QualityState {
  constructor(public readonly player: PlayerAPI) {
    makeAutoObservable(this);
  }

  /**
   * Select the closest quality to the given score if player supports bitrate switching.
   * Otherwise, it does nothing.
   */
  selectQuality(score: SourceScoreLevel | TranscodeScoreLevel): void {
    if (this.player.isImplements(pl.Feature.BITRATE_SWITCHING)) {
      this.player.setPreferredLevel(score);
    }
  }

  /**
   * Returns pretty-printed bitrate.
   */
  formatBitrate(score?: SourceScoreLevel | TranscodeScoreLevel, defaultVal = ""): string {
    if (!this.player.isImplements(pl.Feature.BITRATE_SWITCHING) || score == null) {
      return defaultVal;
    }

    const quality = this.player.getClosestQuality(score);
    if (quality == null) {
      return defaultVal;
    }

    return ((quality.layer.bitrate ?? 0) / 1_000_000).toFixed(2);
  }

  /**
   * Returns true if the given score matches the current quality.
   */
  isSelected(score: SourceScoreLevel | TranscodeScoreLevel): boolean {
    if (!this.player.isImplements(pl.Feature.BITRATE_SWITCHING)) {
      return false;
    }

    const quality = this.player.getClosestQuality(score);
    if (quality == null) {
      return false;
    }

    return quality.level === this.player.currentQuality?.level;
  }

  /**
   * Returns true when the player uses WebRTC with source layer.
   */
  get lowLatency(): boolean {
    if (
      !this.player.isImplements(pl.Feature.PLAYER_SELECTOR) ||
      !this.player.isImplements(pl.Feature.BITRATE_SWITCHING)
    ) {
      return false;
    }

    return (this.player.format === "webrtc" && this.player.currentQuality?.level.startsWith("source:")) ?? false;
  }

  /**
   * Switches the player to WebRTC with a source layer.
   * @param val
   */
  set lowLatency(val: boolean) {
    if (
      !this.player.isImplements(pl.Feature.PLAYER_SELECTOR) ||
      !this.player.isImplements(pl.Feature.BITRATE_SWITCHING) ||
      !this.isSupportLowLatency
    ) {
      return;
    }

    if (val) {
      if (this.player.format !== "webrtc") {
        const index = this.player.availablePlayers.findIndex((p) => p.id === "webrtc");
        this.player.selectPlayer(index);
      }
      this.player.setPreferredLevel(pl.SourceScoreLevel.High);
    } else {
      this.player.setPreferredLevel(pl.TranscodeScoreLevel.Highest);
    }
  }

  /**
   * Returns true if the player supports low latency mode.
   * Generally, this is true if the player supports WebRTC.
   */
  get isSupportLowLatency(): boolean {
    return (
      this.player.isImplements(pl.Feature.PLAYER_SELECTOR) &&
      this.player.availablePlayers.some((p) => p.id === "webrtc")
    );
  }

  /**
   * Returns the number of source layers available.
   */
  get countSources(): number {
    if (!this.player.isImplements(pl.Feature.BITRATE_SWITCHING) || this.player.availableQualities == null) {
      return 0;
    }

    return this.player.availableQualities.reduce((amount, q) => amount + (q.level.startsWith("source:") ? 1 : 0), 0);
  }

  /**
   * Returns a hardcoded list of bitrates.
   */
  get availableBitrates(): AvailableBitrate[] {
    if (this.countSources > 1) {
      return [
        {
          name: "Source:High",
          score: pl.SourceScoreLevel.High,
        },
        {
          name: "Source:Medium",
          score: pl.SourceScoreLevel.Medium,
        },
        {
          name: "Source:Low",
          score: pl.SourceScoreLevel.Low,
        },
      ];
    }
    return [
      {
        name: "Source",
        score: pl.SourceScoreLevel.High,
      },
      {
        name: "High",
        score: pl.TranscodeScoreLevel.High,
      },
      {
        name: "Medium",
        score: pl.TranscodeScoreLevel.Medium,
      },
      {
        name: "Low",
        score: pl.TranscodeScoreLevel.Low,
      },
    ];
  }
}

const ModularQualitySettings = observer(
  ({ classes = {}, disableToggle, disableSelect, showBitrate, active, ...props }: Partial<QualitySettingsProps>) => {
    const componentName = "<QualitySettings/>";

    /**
     * Access LivelyPlayerUiContext & v2 to grab necessary player properties.
     */
    const ctx = useContext<PlayerUiState | null>(LivelyPlayerUiContext);

    /**
     * Throw error (and trigger ErrorBoundary) if store is undefined.
     * */
    useUndefinedStoreError(ctx?.player != null, componentName);

    const state = useMemo(() => new QualityState(ctx.player), [ctx.player]);

    const mergedClasses = useStyles({ source: classes, target: styles }, "select");

    return (
      <div style={active ? activeStyles : inactiveStyles} className={mergedClasses.wrapper}>
        {ctx?.player?.isImplements(pl.Feature.BITRATE_SWITCHING) && (
          <SelectNonNative {...props} className={mergedClasses.root}>
            {state.availableBitrates.map((layer) =>
              layer.score !== undefined && !disableSelect ? (
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => e.key === "Enter" && layer.score !== undefined && state.selectQuality(layer.score)}
                  key={layer.score}
                  onClick={() => state.selectQuality(layer.score)}
                  className={state.isSelected(layer.score) ? mergedClasses.activeOption : mergedClasses.option}
                >
                  {showBitrate ? `${`${layer.name}`} ${state.formatBitrate(layer.score)} ` : layer.name}
                </div>
              ) : (
                <div key={layer.name} className={mergedClasses.disabledOption}>
                  {`${`${layer.name}`} ${
                    state.formatBitrate(layer.score) !== ""
                      ? `: ${state.formatBitrate(layer.score)} Mbps`
                      : " Unavailable"
                  } `}
                </div>
              ),
            )}
          </SelectNonNative>
        )}
        {ctx.player.isImplements(pl.Feature.PLAYER_SELECTOR) ? (
          <PillToggle
            disabledOn={!state.isSupportLowLatency}
            disabledOff={disableToggle}
            isActive={state.lowLatency}
            label="Low-latency:"
            handleClick={() => {
              state.lowLatency = !state.lowLatency;
            }}
          />
        ) : null}
      </div>
    );
  },
);

// type QualityList = Array<{ name: string; score: number | string | null; bitrate: number | undefined }>;

function QualitySettingsWithErrorBoundary({ classes, ...props }: QualitySettingsProps): React.ReactElement {
  const mergedClasses = useStyles({ source: classes ?? {}, target: styles }, "select");
  const ctx = useContext<PlayerUiState | null>(LivelyPlayerUiContext);

  /**
   * Instead of just displayed that the UI is unavailable this is taking the approach of displaying the UI but unselectable and grayed out.
   * If the properties do not exist the UI will not display
   */
  return (
    <ErrorBoundary
      render={() => (
        <>
          {ctx?.player.isImplements(pl.Feature.BITRATE_SWITCHING) ? (
            <SelectNonNative {...props} className={mergedClasses.root}>
              {fallbackBitrates.map((layer, index) => (
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                <div role="button" key={layer.name} className={mergedClasses.disabledOption} />
              ))}
            </SelectNonNative>
          ) : null}
          {ctx?.player.isImplements(pl.Feature.PLAYER_SELECTOR) ?? <PillToggle isActive={false} disabled />}
        </>
      )}
    >
      <ModularQualitySettings {...props} />
    </ErrorBoundary>
  );
}

export default QualitySettingsWithErrorBoundary;
