import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { EncoderUiState } from "../../../../../../store";
import { LivelyEncoderUiContext } from "../../../../../context";
import ButtonIcon from "../../../../../ui-lib/Buttons/Icon";
import ButtonIconProps from "../../../../../ui-lib/Buttons/Icon/propTypes";
import Icon, { IconStyleProps } from "../../../../../ui-lib/Icons";
import { RootStyles } from "../../../../../ui-lib/typings/css";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../../ErrorBoundary";

interface ScreenCaptureButtonClasses extends RootStyles {
  icon?: IconStyleProps;
}

interface ScreenCaptureButtonProps extends Partial<ButtonIconProps> {
  classes?: ScreenCaptureButtonClasses;
}

const ModularScreenCaptureButtonButton = observer(
  ({
    icon: ProvidedIcon,
    onClick,
    active,
    label = "Toggle screenshare",
    screenReaderText,
    classes = {
      root: {},
      icon: {},
    },
    ...props
  }: ScreenCaptureButtonProps) => {
    /**
     * Component Name (for error msg)
     */
    const componentName = "<ScreenCaptureButton/>";
    /**
     * Access LivelyEncoderUiContext & destructure API state
     */
    const ctx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);

    /**
     * Throw error (and trigger ErrorBoundary) if store is undefined.
     * */
    useUndefinedStoreError(ctx?.mediaStreamController != null, componentName);

    /**
     * handleClick function wrapped in global Error handler (to trigger ErrorBoundary).
     * */
    const handleClick = onClick || useUIEventError(ctx?.handleScreenCapture, componentName);

    const activeState: boolean = active ?? ctx?.mediaStreamController?.videoDeviceId === "screencapture";
    const icon = <Icon iconName="screencapture" classes={classes?.icon} />;

    return ctx?.screenShareSupported ? (
      <ButtonIcon
        active={activeState}
        data-selenium="screencapture-toggle-button"
        icon={ProvidedIcon || icon}
        label={label}
        onClick={handleClick}
        activeClass="lv-button--active"
        inactiveClass={null}
        classes={classes}
        {...props}
      >
        {screenReaderText ?? `Click to ${active ? "Unshare" : "Share"} Screen`}
      </ButtonIcon>
    ) : null;
  },
);

// eslint-disable-next-line react/function-component-definition
const ScreenCaptureButtonWithErrorBoundary: FC<Partial<ButtonIconProps>> = ({
  icon: ProvidedIcon,
  label = "Toggle screenshare",
  screenReaderText,
  classes,
  ...props
}: ScreenCaptureButtonProps) => {
  const icon = ProvidedIcon || <Icon iconName="screencapture" classes={classes?.icon} />;

  return (
    <ErrorBoundary
      render={() => (
        <ButtonIcon
          data-selenium="screencapture-toggle-button"
          disabled
          icon={icon}
          label={label}
          classes={classes}
          onClick={undefined}
          active={false}
          {...props}
        >
          {screenReaderText ?? "Click to Share Screen"}
        </ButtonIcon>
      )}
    >
      <ModularScreenCaptureButtonButton
        icon={ProvidedIcon}
        label={label}
        screenReaderText={screenReaderText}
        classes={classes}
        {...props}
      />
    </ErrorBoundary>
  );
};

export default ScreenCaptureButtonWithErrorBoundary;
