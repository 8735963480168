import { player } from "@livelyvideo/video-client-core";
import { observer } from "mobx-react-lite";
import React, { ReactElement, useContext } from "react";
import { PlayerUiState } from "../../../../store";
import { LivelyPlayerUiContext } from "../../../context";
import PeerMutedBadge, { PeerMutedBadgeProps } from "../../../ui-lib/Badges/PeerMuted";
import { ErrorBoundary, useUndefinedStoreError } from "../../ErrorBoundary";

const ModularPeerMutedBadge = observer(
  ({ icon, muted, ...props }: Partial<PeerMutedBadgeProps>): React.ReactElement | null => {
    /**
     * Component Name (for error msg)
     */
    const componentName = "<PeerMutedBadge/>";

    /**
     * Access LivelyPlayerUiContext.
     */
    const ctx = useContext<PlayerUiState | null>(LivelyPlayerUiContext);
    useUndefinedStoreError(ctx != null, componentName);

    return ctx.player.isImplements(player.Feature.CONSUMER) ? (
      <PeerMutedBadge muted={muted ?? !ctx.player.consumerAudioEnabled ?? false} icon={icon} {...props} />
    ) : null;
  },
);

// eslint-disable-next-line react/function-component-definition
const PeerMutedBadgeWithErrorBoundary: ({ ...props }: Partial<PeerMutedBadgeProps>) => ReactElement = ({
  ...props
}) => {
  return (
    <ErrorBoundary render={() => null}>
      <ModularPeerMutedBadge {...props} />
    </ErrorBoundary>
  );
};

export default PeerMutedBadgeWithErrorBoundary;
