/* eslint-disable no-constructor-return,import/no-cycle */
import type { LoggerCore } from "@livelyvideo/log-client";
import { extractAggregates, isSerializableObject, Json } from "@livelyvideo/log-node";
import {
  BitrateSwitchingEvents,
  BitrateSwitchingFeature,
  ConsumerEvents,
  ConsumerFeature,
  MutedAutoplayEvents,
  MutedAutoplayFeature,
  PlayerEvents,
  Quality,
  SourceScoreLevel,
  TranscodeScoreLevel,
  VideoElement,
} from "../../api";
import { ErrorCode } from "../../api/error";
import { isValidFormat, ManifestFormats, ManifestJson, State } from "../../api/manifest";
import type {
  GenericDriverOptions,
  ManifestPlayerAnySpec,
  ManifestPlayerSpecList,
  PlayerAPI,
  PlayerConstructor,
  PlayerOptions,
} from "../../api/player";
import { players } from "../../api/player";
import { Feature, Features } from "../../api/player/features/feature";
import type { PlayerSelectorEvents, PlayerSelectorFeature } from "../../api/player/features/player-selector";
import { ManifestError, NoDriversError, VideoClientError } from "../errors";
import MediaLoader, { MediaLoaderEvents } from "../media-loader";
import { makeBounded } from "../utils/bind";
import { onceCanceled } from "../utils/context/context";
import { VcContext } from "../utils/context/vc-context";
import { ObservableEventEmitter } from "../utils/events/event-emitter";
import { extendContext } from "../utils/logger";
import { implementation } from "../utils/proxy/sync-handler";
import { stats, STATS_EVENTS, TimingStat } from "../utils/stats";
import { CorePlayer, proxyHandler } from "./core";

export interface ManifestPlayerEvents
  extends PlayerEvents,
    PlayerSelectorEvents,
    BitrateSwitchingEvents,
    ConsumerEvents,
    MutedAutoplayEvents {
  // availablePlayers: { players: Array<PlayerAnySpec> };
  /**
   * @description Is emitted when the compatibile players are determined.
   * @example player.on("availablePlayers", ({players}) => { // handle available players})
   */
  availablePlayers: { players: ManifestPlayerSpecList };
  /**
   * @description Is emitted on error.
   * @example player.on("error", (err) => { // handle err})
   */
  error: VideoClientError;
  /**
   * @description Is emitted when no compatible players are available.
   * @example player.on("noPlayers", (bool) => { // handle no players})
   */
  noPlayers: boolean;
  /**
   * @description Is emitted on initial load.
   * @example player.on("initialLoadTime", (stat) => { console.log(stat))
   */
  initialLoadTime: TimingStat;
  /**
   * @description Is emitted when the manifest is loaded.
   * @example player.on("manifestLoadTime", (stat) => { console.log(stat))
   */
  manifestLoadTime: TimingStat;
  /**
   * @description Is emitted when the currentPlayer is set.
   * @example player.on("currentPlayer", (val) => { // handle current player})
   */
  currentPlayer: PlayerAPI;

  implementation: void;

  /**
   * @description Is emitted when the driver is set
   * @example player.on("driver", (driver) => { // do something})
   */
  driver: string;
  /**
   * @description Is emitted when the manifest is set
   * @example player.on("manifest", ({ state: State; code?: number; formats: ManifestFormats }) => { // do something})
   */
  manifest: { state: State; code?: number; formats: ManifestFormats };
  /**
   * @description Is emitted when the driverFailover occurs
   * @example player.on("driverFailover", () => { // do something})
   */
  driverFailover: boolean;
}

export type ManifestPlayerOptions = Omit<PlayerOptions, "players"> & {
  players: ManifestPlayerSpecList;
  refetch: boolean;
  volume?: number;
  driverFailoverSeconds?: number;
};

export class ManifestPlayer
  extends ObservableEventEmitter<ManifestPlayerEvents>
  implements PlayerAPI, PlayerSelectorFeature, BitrateSwitchingFeature, ConsumerFeature, MutedAutoplayFeature
{
  // Make sure all fields from this region are not initialized (don't have default value)
  // because it will be used from a real player implementation through SyncHandler
  //#region Proxy Properties
  [implementation]!: PlayerAPI | null;

  readonly attached?: boolean;

  autoPlay?: boolean;

  driverFailover?: boolean;

  readonly format?: keyof ManifestFormats | null;

  localAudioMuted?: boolean;

  localAudioVolume?: number;

  localVideoPaused?: boolean;

  poster?: string | null;

  hostEl!: VideoElement | null;

  attachTo(el: VideoElement): void {
    this.hostEl = el;
  }

  isSupported!: () => Promise<boolean>;

  getClosestQuality!: () => Quality | null;

  // bitrate switch feature
  availableQualities?: Quality[];

  readonly currentQuality!: Quality | null;

  preferredLevel!: TranscodeScoreLevel | SourceScoreLevel;

  setPreferredLevel(level: TranscodeScoreLevel | SourceScoreLevel): void {
    this.preferredLevel = level;
  }

  // consumer feature
  readonly consumerAudioEnabled!: boolean;

  readonly consumerVideoEnabled!: boolean;

  readonly streamName!: string;

  // muted autoplay
  forcedMute!: boolean;

  initVideoEl!: (el: VideoElement) => Promise<void>;
  //#endregion

  get currentPlayer(): PlayerAPI | null {
    return this[implementation];
  }

  set currentPlayer(player: PlayerAPI | null) {
    this[implementation] = player;
  }

  isImplements<K extends keyof Features, T extends Features[K]>(feature: K): this is this & T {
    const impl = this[implementation];
    if (feature === Feature.PLAYER_SELECTOR) {
      return true;
    }
    return impl?.isImplements(feature) ?? false;
  }

  static readonly displayName = "ManifestPlayer";

  private readonly ctx: VcContext;

  private readonly options: ManifestPlayerOptions;

  private previousTime: Date | null = null;

  private readonly provider: MediaLoader;

  private manifest: ManifestJson | null = null;

  availablePlayers: Array<ManifestPlayerAnySpec> = [];

  private providedPlayers: Array<ManifestPlayerAnySpec> = [];

  currentPlayerIndex: number | null = null;

  private allPlayers: Array<ManifestPlayerAnySpec> = [];

  private readonly firstLoadId: number;

  private manifestLoadId = 0;

  private driverFailoverTimeout: () => void = () => undefined;

  driverTimeout: ReturnType<typeof setTimeout> = setTimeout(() => typeof setTimeout);

  get logger(): LoggerCore {
    return this.ctx.logger;
  }

  constructor(ctx: VcContext, provider: MediaLoader, options: ManifestPlayerOptions) {
    super(false);
    this.firstLoadId = stats.start(STATS_EVENTS.FIRST_LOAD);
    this.ctx = ctx;
    this.options = {
      ...{ displayPoster: "preview" },
      ...options,
      ...{ driverFailoverSeconds: options.driverFailoverSeconds ?? 10 },
    };

    this.provider = provider;
    onceCanceled(ctx).then((reason) => this.dispose(`ManifestPlayer Context Cancelled: ${reason}`));

    // don't initialize any properties form Proxy region
    // before next line, otherwise they will be shadowed

    const self = makeBounded(new Proxy(this, proxyHandler));
    // we replaced context so between this moment
    // and the end of the constructor we should use `self`
    // instead of `this`

    self.init();

    ctx.logger.attachObject(self);
    ctx.logger.trace("constructor()", options);

    return self;
  }

  // noinspection JSUnusedLocalSymbols
  private init(): void {
    this.provider.on("manifest", this.updateManifest);

    const addOptions: GenericDriverOptions = {
      autoPlay: this.options.autoPlay,
      muted: this.options.muted,
      volume: this.options.volume,
    };

    this.allPlayers = this.parsePlayerSpecs(this.options.players, addOptions);

    this.validatePlayers(this.allPlayers).then(() => {
      this.provider.once("source", (body) => {
        this.manifest = body;
        this.emit(
          "manifestLoadTime",
          stats.stop(this.manifestLoadId, {
            driver: this.currentPlayer?.format || undefined,
            abr: this.manifest?.abr,
            aor: this.manifest?.aor,
            atr: this.manifest?.atr,
            rep: this.manifest?.rep,
          }),
        );
      });

      this.manifestLoadId = stats.start(STATS_EVENTS.MANIFEST_LOAD);
      this.provider
        .load(this.options.displayPoster === "preview")
        .catch((err) => this.ctx.logger.error("unable to load manifest: ", err))
        .then(this.firstLoad);
    });

    // Sets interval check to see if last timeupdate HAS NOT occurred within driverFailoverSeconds
    this.driverFailoverTimeout = () => {
      this.driverTimeout = setTimeout(() => {
        if (this.previousTime != null && this.options.driverFailoverSeconds != null) {
          const shouldFailover =
            Math.abs(this.previousTime.getTime() - new Date().getTime()) / 1000 >= this.options.driverFailoverSeconds;
          this.previousTime = new Date();

          if (shouldFailover) {
            this.ctx.logger.debug(
              `Select next player because didn't get 'timeupdate' for ${this.options.driverFailoverSeconds} sec`,
            );
            this.nextPlayer();
          }
        }
      }, this.options.driverFailoverSeconds);
    };

    // Used to set previous time updated, and play next player if a new timeupdate HAS occurred after driverFailoverSeconds
    this.on("timeupdate", () => {
      const currentTime = new Date();
      // First timeupdate event
      if (this.previousTime == null) {
        this.previousTime = currentTime;
        return;
      }

      if (this.options.driverFailoverSeconds != null) {
        const shouldFailover =
          Math.abs(this.previousTime.getTime() - currentTime.getTime()) / 1000 >= this.options.driverFailoverSeconds;

        if (!shouldFailover) {
          // If subsequent timeupdate events fire before driverFailoverSeconds,
          // reset previous time to most recent timeout
          this.previousTime = currentTime;
        } else {
          // if new timeupdate fired after 10 seconds
          this.emit("driverFailover");
          this.driverFailoverTimeout();
        }
      }
    });

    this.on("implementation", () => this.emit("currentPlayer", this));
  }

  protected firstLoad(): void {
    this.poster = this.selectPoster();
  }

  private selectPoster(): string | null {
    const displayPoster = this.options.displayPoster;
    const jpeg = this.manifest?.formats.jpeg;

    if (displayPoster == null || displayPoster === false || jpeg == null) {
      this.ctx.logger.debug("no posters", { displayPoster, manifest: this.manifest });
      return null;
    }

    if (displayPoster === "preview" && this.manifest?.previewImg != null) {
      this.ctx.logger.debug("select previewImg poster");
      return this.manifest?.previewImg;
    }

    let videoHeight: number | undefined;
    let videoWidth: number | undefined;
    if (typeof displayPoster === "object") {
      videoHeight = displayPoster.videoHeight;
      videoWidth = displayPoster.videoWidth;
    } else if (this.currentQuality?.layer.appData != null) {
      videoHeight = this.currentQuality.layer.appData.videoHeight as number;
      videoWidth = this.currentQuality.layer.appData.videoWidth as number;
    }

    const previewUrl = jpeg.encodings.find((j) => j.videoHeight === videoHeight && j.videoWidth === videoWidth);
    if (previewUrl != null) {
      this.ctx.logger.debug(`select ${videoWidth}x${videoHeight} poster`);
      return previewUrl.location;
    }

    if (jpeg.encodings.length > 0) {
      this.ctx.logger.debug("select first poster");
      return jpeg.encodings[0].location;
    }

    this.ctx.logger.debug("poster not found");
    return null;
  }

  private parsePlayerSpecs(
    playerSpecList: ManifestPlayerSpecList,
    addOptions: GenericDriverOptions,
  ): ManifestPlayerAnySpec[] {
    return playerSpecList.map((p) => {
      if (typeof p === "string") {
        return { id: p, options: addOptions };
      }

      const options = p.options ?? {};
      return { ...p, options: { ...addOptions, ...options } };
    });
  }

  async validatePlayers(optionPlayers: Array<ManifestPlayerAnySpec>): Promise<void> {
    this.providedPlayers = [];
    // const list = Object.keys(options.players) as Array<keyof PlayerMap>;
    const support = await Promise.all(
      optionPlayers.map((spec) => {
        return players[spec.id].isSupported(this.logger);
      }),
    );

    const enriched = optionPlayers.map((spec, i) => {
      return { ...spec, supported: support[i] };
    });

    this.providedPlayers = enriched.filter((spec) => spec.supported);
    this.emit("availablePlayers", { players: this.providedPlayers });

    if (this.providedPlayers.length === 0) {
      this.noDrivers();
    }
  }

  private updateManifest(ev: MediaLoaderEvents["manifest"]): void {
    const errMsg = {
      401: "MANIFEST_UNAUTHORIZED",
      403: "MANIFEST_FORBIDDEN",
      404: "MANIFEST_NOT_FOUND",
    };

    this.manifest = ev;

    if (ev.state !== "online") {
      if (ev.code === 401 || ev.code === 403) {
        if (this.options.refetch) {
          this.emit("info", { code: errMsg[ev.code] });
        } else {
          this.emitError(new ManifestError(`manifest error: ${errMsg[ev.code]}`, { loader: this.provider }));
          this.dispose(errMsg[ev.code]);
        }
      }
      if (ev.code === 404) {
        this.currentPlayer?.dispose();
        this.currentPlayer = null;
        this.emit("info", { code: errMsg[ev.code] });
      }

      return;
    }

    this.availablePlayers = this.providedPlayers.filter((spec) => {
      const format = ev.formats[players[spec.id].format];
      return format != null && isValidFormat(format);
    });

    this.emit("availablePlayers", { players: this.availablePlayers });

    if (this.currentPlayer == null) {
      if (this.availablePlayers.length === 0) {
        this.noDrivers();
      } else {
        this.ctx.logger.debug("Select the first player");
        this.selectPlayer(0);
      }
    }

    this.poster = this.selectPoster();
  }

  private noDrivers(): void {
    if (this.provider.currentState !== "online") {
      return;
    }
    this.emit("noPlayers", true);
    this.emitError(new NoDriversError("no drivers", {}));
  }

  nextPlayer(): void {
    if (this.currentPlayerIndex == null || this.currentPlayerIndex >= this.availablePlayers.length - 1) {
      this.selectPlayer(0);
    } else {
      this.selectPlayer(this.currentPlayerIndex + 1);
    }
  }

  selectPlayer(index: number): void {
    this.ctx.logger.debug("selectPlayer()", { index });
    if (index >= this.availablePlayers.length) {
      this.noDrivers();
      return;
    }
    const spec = this.availablePlayers[index];

    this.emit("driver", spec.id);
    const Player = players[spec.id] as PlayerConstructor;
    if (Player == null) {
      this.noDrivers();
      return;
    }

    if (this.currentPlayer != null) {
      const oldPlayer = this.currentPlayer as CorePlayer;
      this.currentPlayer = null;

      oldPlayer.cleanVideoEl("");
      oldPlayer.hostEl = null;
      oldPlayer.removeAllListeners("error");
      oldPlayer.dispose("replacing current player");
    }
    const player = new Player(extendContext(this.ctx, Player), this.provider, spec.options) as CorePlayer;

    this.currentPlayer = player;
    this.currentPlayerIndex = index;
    this.ctx.logger.debug("set manifest player", {
      player: this.currentPlayer,
      format: player.format,
      name: spec,
    });

    // this.proxyEventsFrom(this.currentDriver);

    // this.currentPlayer.init();

    player.on("error", (err) => {
      switch (err.code) {
        case ErrorCode.EmbedSWFFailed:
          this.ctx.logger.debug(`Select next player because ${ErrorCode.EmbedSWFFailed}`);
          this.nextPlayer();
          break;
        case ErrorCode.NotSupported:
          this.ctx.logger.debug(`Select next player because ${ErrorCode.NotSupported}`);
          this.nextPlayer();
          break;
        case ErrorCode.HandleHlsJsError:
          this.ctx.logger.debug(`Select next player because ${ErrorCode.HandleHlsJsError}`);
          this.nextPlayer();
          break;
        default:
          break;
      }
    });

    player.once("videoFirstPlay", () => {
      this.emit(
        "initialLoadTime",
        stats.stop(this.firstLoadId, {
          driver: this.currentPlayer?.format || undefined,
          abr: this.manifest?.abr,
          aor: this.manifest?.aor,
          atr: this.manifest?.atr,
          rep: this.manifest?.rep,
        }),
      );
    });

    if (this.hostEl != null) {
      player.initVideoEl(this.hostEl).catch((err) => this.ctx.logger.error(err));
    }

    // this.emit("select-driver", this.currentDriver);
    // this.currentDriver.on("estimated-bw", (kbps) => {
    //   this.store.set(STORAGE_VALUES.estimatedKbps, (parseInt(kbps.toString(), 10) ?? LOW_BITRATE).toString());
    // });
    //
    // this.currentDriver.on("ended", () => {
    //   this.isTryingToPlay = false;
    // });
  }

  toJSON(): Json {
    const data = this[implementation]?.toJSON();
    if (!(data != null && typeof data === "object" && !Array.isArray(data) && !isSerializableObject(data))) {
      return {};
    }

    const aggregates = data.aggregates ?? {};
    if (
      !(
        aggregates != null &&
        typeof aggregates === "object" &&
        !Array.isArray(aggregates) &&
        !isSerializableObject(aggregates)
      )
    ) {
      return data;
    }

    delete data.aggregates;

    return {
      ...data,
      options: this.options,
      playersSpecs: this.availablePlayers,
      currentPlayerIndex: this.currentPlayerIndex,
      uri: this.provider?.uri,

      aggregates: {
        ...aggregates,
        ...extractAggregates(this.provider, "support"),
        support: this.ctx.support.hash,
      },
    };
  }
}
