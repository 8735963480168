/* eslint-disable */
import { configure } from "mobx";
export * as adapter from "./api/adapter";

import { MediaControllerAPI, VideoClientAPI, VideoClientConstructor } from './api';
import { mediaController as mediaControllerImpl } from "./internal/media-controller";
import { startMobxDebugging } from "./internal/utils/debug";
import { VideoClient as VideoClientImpl } from "./internal/video-client";

export const VideoClient: VideoClientConstructor = VideoClientImpl;
export type VideoClient = VideoClientAPI;
export const mediaController: MediaControllerAPI = mediaControllerImpl;

export * as types from "./api";
export * as player from "./api/player/index";
export * as errors from "./internal/errors";
export * as utils from "./internal/utils/index";
export { Recorder as RecorderCore } from "./recorder/index";
export { contextId, instanceId } from "./utils/common";

// @deprecated the following should be moved to utils namespace
export { timeupdateWrapper } from "./internal/player/core";
export { makeBounded } from "./internal/utils/bind";
export { isAndroidDevice, isIosDevice, isMobileDevice, isSafari } from "./internal/utils/browser-support";
export { InstanceCollector } from "./internal/utils/debug/instance-collector";
export { ObservableEventEmitter } from "./internal/utils/events/event-emitter";
export { TranscodeScoreLevel, SourceScoreLevel } from "./api/player/features/bitrate-switching"

configure({
  observableRequiresReaction: false,
  reactionRequiresObservable: false,
  enforceActions: "never",
});

startMobxDebugging();
