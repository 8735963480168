import { isIosDevice } from "@livelyvideo/video-client-core";

let iOSFullScreenElement: Element | null = null;

/**
 * Cross-browser API to request fullscreen mode.
 */
export function requestFullscreen(el: Element): Promise<void> {
  let promise: Promise<void> | void;
  if (iOSWorkaround(el)) {
    promise = requestFullscreenIOS(el);
  } else if (typeof el.requestFullscreen === "function") {
    promise = el.requestFullscreen();
  } else if (typeof el.webkitRequestFullScreen === "function") {
    promise = el.webkitRequestFullScreen();
  } else {
    promise = Promise.reject(new Error("requestFullscreen not supported"));
  }

  return promise ?? Promise.resolve();
}

/**
 * Cross-browser API to exit fullscreen mode.
 */
export function exitFullscreen(): Promise<void> {
  let promise: Promise<void> | void;
  if (iOSWorkaround(iOSFullScreenElement)) {
    promise = exitFullscreenIOS();
  } else if (typeof document.exitFullscreen === "function") {
    promise = document.exitFullscreen();
  } else if (typeof document.webkitCancelFullScreen === "function") {
    promise = document.webkitCancelFullScreen();
  } else {
    promise = Promise.reject(new Error("exitFullscreen not supported"));
  }

  return promise ?? Promise.resolve();
}

/**
 * Cross-browser API to check if fullscreen mode is active.
 */
export function fullscreenElement(): Element | null {
  return (
    document.fullscreenElement ??
    document.webkitFullscreenElement ??
    document.webkitCurrentFullScreenElement ??
    iOSFullScreenElement
  );
}

function findVideoEl(el: Element | null): HTMLVideoElement | null {
  if (el == null || el instanceof HTMLVideoElement) {
    return el;
  }

  return el.querySelector("video") ?? null;
}

function iOSWorkaround(el: Element | null): boolean {
  const videoEl = findVideoEl(el);
  return isIosDevice() && typeof videoEl?.webkitEnterFullscreen === "function";
}

function requestFullscreenIOS(el: Element): Promise<void> {
  const videoEl = findVideoEl(el);
  if (videoEl == null) {
    return Promise.reject(new Error("video element not found"));
  }

  const promise = videoEl.webkitEnterFullscreen?.();
  iOSFullScreenElement = videoEl;

  videoEl.addEventListener(
    "webkitendfullscreen",
    () => {
      iOSFullScreenElement = null;
      document.dispatchEvent(new Event("fullscreenchange"));

      setTimeout(() => {
        // I am a dirty hack, please fix me :(
        videoEl.play().catch(() => {
          // ignore
        });
      }, 1000);
    },
    {
      once: true,
    },
  );
  document.dispatchEvent(new Event("fullscreenchange"));

  return promise ?? Promise.resolve();
}

function exitFullscreenIOS(): Promise<void> {
  const promise = iOSFullScreenElement?.webkitCancelFullScreen?.();

  return promise ?? Promise.resolve();
}
