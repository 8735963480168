import { player } from "@livelyvideo/video-client-core";
import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { PlayerUiState } from "../../../../store/player";
import { LivelyPlayerUiContext } from "../../../context";
import { useStyles } from "../../../styling";
import VideoWrapper, { VideoWrapperProps } from "../../../ui-lib/VideoWrapper";
import { videoWrapperStyles } from "../../../ui-lib/VideoWrapper/styles";
import { ErrorBoundary, useUndefinedStoreError } from "../../ErrorBoundary";
import { Video } from "./Video";

const ModularVideo = observer(({ classes, elementBlur, blurPixels, ...props }: Partial<VideoWrapperProps>) => {
  const componentName = "<PlayerVideo/>";
  /**
   * @todo: Not sure how we want to handle styles for this.
   */

  const ctx = useContext<PlayerUiState | null>(LivelyPlayerUiContext);

  useUndefinedStoreError(ctx?.videoElement != null, componentName);

  const blurredVideo = elementBlur || (ctx.player.isImplements(player.Feature.BITRATE_SWITCHING) && ctx.player.blurred);

  const styling = videoWrapperStyles(
    props.pillarBox ?? false,
    props.isFullScreen,
    props.mirror,
    blurredVideo,
    blurPixels,
  );

  const mergedClasses = useStyles({ source: classes ?? {}, target: styling }, "videoWrapper");

  // @TODO Needs to be added back in when this feature is fixed
  /*
  useEffect(
    () =>
      autorun(() => {
        const p = ctx?.player;
        const el = ctx?.videoElement;
        if (p == null || !(el instanceof HTMLVideoElement)) {
          return;
        }

        if (p.isImplements(player.Feature.CONSUMER)) {
          const logCtx = {
            streamName: p.streamName,
            consumerVideoEnabled: p.consumerVideoEnabled,
          };

          if (p.consumerVideoEnabled) {
            ctx?.logger?.debug("Video element is visible", logCtx);
            el.style.visibility = "";
          } else {

            //ctx?.logger?.debug("Video element is hidden", logCtx);
            //el.style.visibility = "hidden";
          }

        } else {
          el.style.visibility = "";
        }

      }),
    [ctx?.logger, ctx?.player, ctx?.videoElement, ctx?.player.format],
  );
  */
  return (
    <VideoWrapper
      isFullScreen={ctx?.isFullscreen}
      classes={classes}
      ref={ctx.videoWrapperElement}
      elementBlur={blurredVideo}
      blurPixels={blurPixels}
      {...props}
    >
      <Video source={ctx.player} autoPlay={false} className={mergedClasses.videoElement} ref={ctx.videoElement} />
    </VideoWrapper>
  );
});

const PlayerVideoWithErrorBoundary: FC<Partial<VideoWrapperProps>> = ({ classes, ...props }) => {
  return (
    /**
     * @todo: Temporary fallback. Update for a real fallback.
     */
    <ErrorBoundary
      render={() => (
        <div style={{ height: "600px", width: "100%" }}>
          <p>Video is not working</p>
        </div>
      )}
    >
      <ModularVideo classes={classes} {...props} />
    </ErrorBoundary>
  );
};

export default PlayerVideoWithErrorBoundary;
