import { observer } from "mobx-react-lite";
import React, { FC, ReactElement, useContext } from "react";
import { EncoderUiState, PlayerUiState } from "../../../../store";
import { LivelyEncoderUiContext, LivelyPlayerUiContext } from "../../../context";
import MediaWrapper, { MediaWrapperProps } from "../../../ui-lib/MediaWrapper";
import { ErrorBoundary, useUndefinedStoreError } from "../../ErrorBoundary";

type ModularMediaContainerProps = Omit<MediaWrapperProps, "ref">;

const ModularMediaContainer = observer(({ children, classes, ...props }: Partial<ModularMediaContainerProps>) => {
  const ctx =
    useContext<EncoderUiState | null>(LivelyEncoderUiContext) != null
      ? useContext<EncoderUiState | null>(LivelyEncoderUiContext)
      : useContext<PlayerUiState | null>(LivelyPlayerUiContext);

  useUndefinedStoreError(ctx != null, "<MediaContainer/>");

  const mediaWrapperComponent: ReactElement = (
    <MediaWrapper ref={ctx.videoWrapperElement} classes={classes} isFullscreen={ctx.isFullscreen} {...props}>
      {children}
    </MediaWrapper>
  );

  // return !uiState.launchNewWindow
  //   ? mediaWrapperComponent
  //   : createPortal(mediaWrapperComponent, uiState.newWindowElement);
  return mediaWrapperComponent;
});

// eslint-disable-next-line react/function-component-definition
const MediaContainerWithErrorBoundary: FC<Partial<ModularMediaContainerProps>> = ({ classes, ...props }) => {
  return (
    <ErrorBoundary render={() => <MediaWrapper classes={classes} {...props} />}>
      <ModularMediaContainer classes={classes} {...props} />
    </ErrorBoundary>
  );
};

export default MediaContainerWithErrorBoundary;
