import { Properties } from "csstype";
import React, { FC, useEffect, useState } from "react";
import { useStyles } from "../../styling/livelyStyles";
import { EventFunction } from "../typings/clickevents";

type PillToggleProps = {
  disabled?: boolean;
  checked?: boolean;
  classes?: Properties;
  className?: string;
  label?: string | number;
  disabledOn?: boolean;
  disabledOff?: boolean;
  handleClick?: EventFunction;
  isActive: boolean;
};

/**
 * styling
 */
const styles = {
  pillWrapper: {
    width: 120,
    position: "relative",
    borderTop: "1px solid #eeeeee",
    display: "flex",
    alignItems: "center",
    paddingTop: 5,
    paddingRight: 10,
    paddingLeft: 10,
    paddingBottom: 5,
    background: "#111111",
  },
  pill: {
    marginLeft: 10,
    position: "relative",
    display: "flex",
    alignItems: "center",
    width: 35,
    height: 20,
    borderRadius: 20,
    background: "#111111",
    border: "solid 1px #eeeeee",
    transition: "all 0.3s ease-in-out",
  },
  pillBall: {
    position: "absolute",
    height: 14,
    width: 14,
    left: 3,
    borderRadius: 100,
    background: "#eeeeee",
    transition: "all 0.3s ease-in-out",
    transform: "translate(0px, 0px)",
  },
  label: {
    cursor: "default",
    fontSize: 12,
    color: "#eeeeee",
    fontFamily: "Lato, Arial, sans-serif",
  },
  activePill: {
    background: "#eeeeee",
  },
  activePillBall: {
    height: 16,
    width: 16,
    borderColor: "#111111",
    transform: "translate(15px, 0px)",
    background: "#111111",
  },
  disabled: {
    height: "100%",
    width: "100%",
    zIndex: 999,
    background: "rgba(81, 81, 81, 0.5)",
    cursor: "not-allowed",
    position: "absolute",
  },
};

const PillToggle: FC<PillToggleProps> = ({
  disabled,
  classes = {},
  className,
  label,
  handleClick,
  disabledOff,
  disabledOn,
  isActive,
  ...rest
}) => {
  /**
   * Local state to set the pill toggle to active/inactive.
   */
  const [active, setActive] = useState<boolean>(isActive);
  const mergedClasses = useStyles({ source: classes, target: styles },  "radio" );

  /**
   *  Watching the setActive prop so our parent element has the same state as the toggle.
   */
  useEffect(() => {
    if (isActive !== undefined) {
      setActive(isActive);
    }
  }, [isActive]);

  return (
    <div
      className={`${mergedClasses.pillWrapper} ${className}`}
      style={{
        cursor: disabledOn || disabledOff ? "not-allowed" : "pointer",
      }}
    >
      {disabled && <div className={mergedClasses.disabled} />}
      <div className={`${mergedClasses.label} ${className}`}> {label} </div>
      <div
        role="button"
        tabIndex={!disabled ? 0 : -1}
        onKeyDown={(e) => e.key === "Enter" && !disabledOn && !disabledOff && (setActive(!active), handleClick)}
        onClick={() => {
          if (!disabledOn && !disabledOff) {
            setActive(!active);
            handleClick?.();
          }
        }}
        className={`${mergedClasses.pill} ${className} ${active && mergedClasses.activePill}`}
      >
        <div className={`${mergedClasses.pillBall} ${className} ${active && mergedClasses.activePillBall}`} />
      </div>
    </div>
  );
};

export default PillToggle;
