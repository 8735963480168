import { LoggerCore } from "@livelyvideo/log-client";
import { Json, Serializable } from "@livelyvideo/log-node";
import { device, Feature } from "../../../api/adapter";
import { wrapNativeError } from "../../errors";
import { VcContext } from "../../utils/context/vc-context";
import { InstanceCollector } from "../../utils/debug/instance-collector";
import { ObservableEventEmitter } from "../../utils/events/event-emitter";
import type { RequestResponse } from "../../utils/request/request";
import type { Join } from "./call";
import type { CommonRequestData, CommonResponse } from "./common";

export default class Transport extends ObservableEventEmitter<any> implements Serializable {
  static readonly displayName = "Transport";

  public uri: string;

  public joinCall: () => Promise<RequestResponse<Join>>;

  public connected = false;

  public connecting = false;

  public closed = false;

  public closing = false;

  public online: boolean = device.onLine;

  private _ws: WebSocket | null = null;

  private _hasError = false;

  private readonly _retries: number;

  private _retryCount = 0;

  private readonly _retryTimeout?: number = undefined;

  private _requestId = 0;

  private _pingTimeout?: number = undefined;

  private readonly _handleOffline: () => void;

  private readonly _handleOnline: () => void;

  private readonly _retry: (retry?: boolean) => void = () => undefined;

  private wsMessageTime: Date | null = null;

  private readonly wsMessageInterval: number | undefined = undefined;

  private reconnecting = false;

  private readonly ctx: VcContext;

  private wsMessageSent = false;

  constructor(
    ctx: VcContext,
    uri: string,
    joinCall: () => Promise<RequestResponse<Join>>,
    retries: number,
    wsReconnect: boolean,
    wsReconnectTime: number,
  ) {
    super();
    this.ctx = ctx;
    this.uri = uri;
    this.joinCall = joinCall;

    // ws state
    this._retries = retries ?? Infinity;

    this._handleOffline = () => {
      ctx.logger.debug("Transport: offline detected");
      this.online = false;
    };
    this._handleOnline = () => {
      ctx.logger.debug("Transport: online detected", {
        connected: this.connected,
        connecting: this.connecting,
        hasError: this._hasError,
      });
      this.online = true;
      if (!this.connected && !this.connecting) {
        device.clearTimeout(this._retryTimeout);
        this._retry();
      }
    };

    device.addEventListener("offline", this._handleOffline);
    device.addEventListener("online", this._handleOnline);

    ctx.logger.attachObject(this);
    ctx.logger.trace("constructor()");

    this._connect();

    this.wsMessageInterval = device.setInterval(() => {
      let timeDifference = 0;
      const currentTime = new Date();
      if (this.wsMessageTime != null) {
        if (this.wsMessageTime.getSeconds() !== currentTime.getSeconds()) {
          timeDifference = currentTime.getTime() - this.wsMessageTime.getTime();
        }
      }
      timeDifference /= 1000;
      //Don't attempt a reconnect if we are not online
      if (this.online) {
        if (wsReconnect) {
          if (timeDifference > wsReconnectTime || this.reconnecting) {
            this.wsMessageSent = true;
            this.log.warn(
              `Message not received from websocket exceeding ${wsReconnectTime} seconds, re-initializing websocket.`,
            );
            this._emit("websocket-reconnect");
            this.reconnecting = false;
          }
        } else if ((timeDifference > wsReconnectTime || this.reconnecting) && !this.wsMessageSent) {
          this.wsMessageSent = true;
          this.log.warn(`Message not received from websocket exceeding ${wsReconnectTime} seconds.`);
          this.reconnecting = false;
        }

        //Set that we are going to attempt to reconnect if we are offline so that we don't try multiple reconnects.
      } else if (!this.reconnecting && timeDifference > wsReconnectTime) {
        this.reconnecting = true;
      }
    }, 1000);
    this.addInnerDisposer((reason) => {
      this.close();
    });
  }

  get log(): LoggerCore {
    return this.ctx.logger;
  }

  request<T>(type: string, data: unknown): Promise<T | null> {
    if (!this._sendReady()) {
      return Promise.resolve(null);
    }
    this._requestId += 1;
    const rid = this._requestId;

    return new Promise<T>((resolve, reject) => {
      this.once(`response-${rid}`, (response: CommonResponse & T) => {
        if (response.reasons != null && response.reasons.length > 0) {
          const reason = response.reasons[0]?.text;
          const e = new Error(`request error: ${reason}`);
          reject(e);
          return;
        }

        resolve(response);
      });

      this.send("request", {
        requestId: rid,
        type,
        request: data ?? null,
      });
    });
  }

  send(event: string, data: unknown): void {
    if (!this._sendReady(false, this._ws)) {
      return;
    }

    let request: string;
    try {
      request = JSON.stringify({
        event,
        data,
      });
    } catch (err) {
      this.log.error("websocket send error: unable to serialize date", {
        event,
        err: wrapNativeError(err),
      });
      return;
    }

    try {
      this._ws.send(request);
    } catch (err) {
      const msg = err instanceof Error ? err.message : "unknown error";
      this.log.error("websocket send error", {
        event,
        data: request,
        err: msg,
      });
    }
  }

  setClosing(): void {
    this.closing = true;
  }

  close(done = true): void {
    this.closed = done;
    if (this.closed) {
      device.removeEventListener("offline", this._handleOffline);
      device.removeEventListener("online", this._handleOnline);
      device.clearInterval(this.wsMessageInterval);
    }
    if (this._ws != null) {
      this.log.debug("Transport: close called - closing websocket");
      this._ws.close();
      InstanceCollector.disposeInstance("websocket", this._ws);
    }
  }

  _sendReady(isPing = false, ws = this._ws): ws is WebSocket {
    if (ws == null || ws?.readyState !== 1) {
      if (!isPing) {
        this.log.warn("Transport: trying to sending on a closed websocket", {
          uri: this.uri,
          ws: ws?.readyState,
        });
      }
      return false;
    }
    return true;
  }

  _onServerRequest(json: CommonRequestData): void {
    const { method, requestId, request } = json.data;
    if (Number.isNaN(requestId)) {
      this.log.error("transport: Invalid request from server", { json });
      return;
    }

    const response = (resp = {}, err = null): void => {
      this.send("response", {
        requestId,
        response: resp,
        error: err,
      });
    };

    this.emit(method, request, response);
  }

  _connect(): void {
    this.connecting = true;

    // close websocket - this prevents a race condition.
    if (this._ws != null) {
      this._ws.onclose = () => undefined;
      this._ws.close();
      InstanceCollector.disposeInstance("websocket", this._ws);
      this._ws = null;
    }

    this._ws = null;
    this._ws = new WebSocket(this.uri);
    InstanceCollector.reportNewInstance("websocket", this._ws, { file: "call/transport.ts" });

    const id = Math.random();
    this.log.debug("Transport: _connect", {
      id,
    });

    this._ws.onopen = () => {
      // setup connection state
      this.connected = true;
      this.connecting = false;
      this._hasError = false;
      this._retryCount = 0;

      // Ping seems to be causeing more problems then it's solving
      // this._ping();

      this.log.debug("Transport: onopen", {
        id,
      });

      this._emit("connect");
    };

    this._ws.onerror = (err) => { // eslint-disable-line
      this._hasError = true;
      // info because err is an event with no relevant info
      this.log.info("websocket error", {
        uri: this.uri,
        err: wrapNativeError(err),
      });
    };

    this._ws.onmessage = (message) => {
      this.wsMessageTime = new Date();
      if (message.data === "pong") {
        this._pong();
        return;
      }

      if (message.data === "ping" || message.data === "0") {
        this._ws?.send("pong");
        return;
      }

      let obj;
      try {
        obj = JSON.parse(message.data);
      } catch (err) {
        const msg = err instanceof Error ? err.stack : "unknown error";
        this.log.error("websocket parse error", {
          data: message?.data,
          uri: this.uri,
          err: msg,
        });
        return;
      }

      if (obj.event === "ping") {
        this._ws?.send("pong");
        if (device.isImplements(Feature.DEBUGGING)) {
          device.console.debug("websocket pong sent");
        }
        return;
      }

      if (obj.event === "request") {
        this._onServerRequest(obj);
        return;
      }

      if (obj.event === "callError") {
        this.log.error("Call Error", {
          data: message?.data,
          uri: this.uri,
          event: obj.event,
        });
        this.close();
      }

      this._emit(obj.event, obj.data);
    };

    this._ws.onclose = (e) => {
      this.reconnect(id, e);
    };
  }

  private reconnect(id?: number, event?: CloseEvent): void {
    this.connected = false;
    this.connecting = false;

    device.clearTimeout(this._pingTimeout);

    if (id !== undefined) {
      this.log.debug("Transport: onclose", {
        id,
      });
    }

    this._pong();

    if (event) {
      this.log.info("Transport: websocket close", {
        code: event.code,
        uri: this.uri,
      });

      this._emit("disconnect", event.code, "websocket-closed");
    }

    if (this._canRetry()) {
      // if we're going to reconnect
      // then we should notify call about that
      // so it won't try to send closeProducer to SFU
      this.emit("prepare-rejoin-call");

      if (this._hasError) {
        // wait a second in case this error was caused by the network going away,
        // iOS seems to close the socket immediately, this pause gives us a chance
        // to detect that the user is now offline
        device.setTimeout(this._joinCall.bind(this), 2000);
      } else {
        this._joinCall();
      }
    }
  }

  private _canRetry(): boolean {
    if (this.connecting || this.closed || this.closing) {
      this.log.debug("Transport: no retry", {
        online: this.online,
        connecting: this.connecting,
        closed: this.closed,
        closing: this.closing,
      });
      return false;
    }
    if (this._retryCount > this._retries) {
      this.log.warn("Transport: limited retries", {
        uri: this.uri,
      });
      this._emit("call-rejected");
      return false;
    }

    return true;
  }

  private _retryTimeoutLength(): number {
    let timeout = 500;
    if (this._retryCount > 5 && this._retryCount < 15) {
      timeout = 1000;
    } else if (this._retryCount >= 15) {
      timeout = 10000;
    } else if (this._retryCount >= 100) {
      timeout = 600000;
    }
    return timeout;
  }

  private _joinCall(): void {
    if (!this.online && !this.connecting && !this.closed) {
      device.setTimeout(this._joinCall.bind(this), 500);
      return;
    }
    if (!this._canRetry()) {
      return;
    }
    this._retryCount += 1;

    this.log.debug("Transport: join call");

    this.joinCall()
      .then((response) => {
        this.log.debug("Transport: response from joinCall", { response });

        let call;
        if (response.body?.call != null) {
          call = response.body.call;
        } else {
          this.log.warn("Transport: refreshCall error", {
            sfuUri: this.uri,
          });
          this._emit("join-call-error", {
            error: "invalid response, call was missing",
          });
          device.setTimeout(this._joinCall.bind(this), this._retryTimeoutLength());
          return;
        }

        this._hasError = false;

        if (call.callUri != null && this.uri !== call.callUri) {
          this.log.debug("Transport: call uri changed", { call });
          this.uri = call.callUri;
        } else {
          this.log.debug("Transport: join call did not update call uri");
        }
        this._emit("join-refresh", response);

        this._connect();
      })
      .catch((err) => {
        if (err.status !== 404) {
          this.log.warn("Transport: refreshCall error", {
            error: err,
            sfuUri: this.uri,
          });
        }
        this._emit("join-call-error", {
          error: err,
        });
        device.setTimeout(this._joinCall.bind(this), this._retryTimeoutLength());
      });
  }

  private _ping(): void {
    if (!this._sendReady(true, this._ws)) {
      return;
    }

    this._ws.send("ping");

    this._pingTimeout = device.setTimeout(() => {
      this.log.error("Transport: failed ping to server - retry connection");
      this._retry(true);
    }, 3000);
  }

  private _pong(): void {
    device.clearTimeout(this._pingTimeout);

    if (!this.connected) {
      return;
    }

    device.setTimeout(this._ping.bind(this), 3000);
  }

  private _emit(event: string, ...args: unknown[]): void {
    try {
      this.emit(event, ...args);
    } catch (err) {
      const msg = err instanceof Error ? err.stack : "unknown error";
      this.log.warn("unhandled event handler error", {
        event: `${args[0]}`,
        err: msg,
        uri: this.uri,
      });
    }
  }

  toJSON(): Json {
    return {
      aggregates: {
        support: this.ctx.support.hash,
        uri: this.uri,
        online: this.online,
      },
    };
  }
}
