import { Quality, SourceScoreLevel, TranscodeScoreLevel } from "../../../api/player/features/bitrate-switching";

interface qualityDebugLogParams {
  currentQuality: Quality | null;
  preferredLevel: TranscodeScoreLevel | SourceScoreLevel | null;
  availableQualities: Quality[];
  delay: number;
}

interface AggregateLogs {
  debug: string;
  qualityDebugHasCurrentQuality: boolean;
  qualityDebugHasPreferredLevel: boolean;
  qualityDebugHasAvailableQualities: boolean;
  qualityDebugCurrentMatchesPreferredQuality: boolean;
  intervalDelay: number;
}

export const qualityDebugLog = ({
  currentQuality,
  preferredLevel,
  availableQualities,
  delay,
}: qualityDebugLogParams): Partial<AggregateLogs> => {
  const logs: Partial<AggregateLogs> = {
    debug: "quality",
    qualityDebugCurrentMatchesPreferredQuality: currentQuality?.level === preferredLevel,
  };

  if (currentQuality != null) {
    logs.qualityDebugHasCurrentQuality = currentQuality != null;
  }

  if (preferredLevel !== undefined) {
    logs.qualityDebugHasPreferredLevel = preferredLevel !== undefined;
  }

  if (availableQualities.length > 0) {
    logs.qualityDebugHasAvailableQualities = availableQualities.length > 0;
  }
  logs.intervalDelay = delay;

  return logs;
};
