import type { types } from "@livelyvideo/video-client-core";
import { makeAutoObservable } from "mobx";

export class BroadcastState {
  call: types.CallAPI | null = null;

  broadcast: types.BroadcastAPI | null = null;

  constructor(
    public readonly videoClient: types.VideoClientAPI,
    public readonly mediaStreamController: types.MediaStreamControllerAPI, // public readonly callId: string | null, // public readonly isOwner: boolean,
  ) {
    makeAutoObservable(this);
  }

  /**
   * Check if the broadcast is active.
   */
  get isBroadcasting(): boolean {
    return this.broadcast?.state === "active";
  }

  //
  // get isOwner(): boolean {
  //   return this.call?.isOwner ?? false;
  // }
  //
  async toggleBroadcast(
    callOptionsOrId: string | types.CallOptions,
    broadcastOptions: types.BroadcastOptions,
  ): Promise<void> {
    if (this.isBroadcasting) {
      return this.stopBroadcast();
    }

    if (typeof callOptionsOrId === "string") {
      return this.startBroadcastOnExistingCall(callOptionsOrId, broadcastOptions);
    }
    return this.startBroadcastOnNewCall(callOptionsOrId, broadcastOptions);
  }

  async startBroadcastOnNewCall(
    callOptions: types.CallOptions,
    broadcastOptions: types.BroadcastOptions,
  ): Promise<void> {
    if (this.call != null) {
      throw new Error("Cannot start broadcast on a call that is already active");
    }

    this.call = await this.videoClient.createCall(callOptions);
    return this.startBroadcastOnExistingCall(this.call.id, broadcastOptions);
  }

  /**
   * Joins the existing call and starts the broadcast.
   */
  async startBroadcastOnExistingCall(callId: string, options: types.BroadcastOptions): Promise<void> {
    if (this.broadcast != null) {
      throw new Error("Broadcast is already active");
    }

    if (this.call == null || this.call.state !== "active") {
      this.call = await this.videoClient.joinCall(callId);
    }
    this.call.on("viewerKicked", () => this.stopBroadcast());

    this.broadcast = await this.call.broadcast(this.mediaStreamController, options);
  }

  async stopBroadcast(): Promise<void> {
    if (this.call?.isOwner) {
      this.call?.dispose("call disposed via JoinBroadcast Button");
      this.call = null;
    } else {
      this.broadcast?.dispose("broadcast disposed via JoinBroadcast Button");
    }
    this.broadcast = null;
  }
}
