import { Properties } from "csstype";
import React, { FC, ReactNode } from "react";
import { useStyles } from "../../styling/livelyStyles";

export interface SelectProps {
  className?: string;
  children?: ReactNode;
  classes?: Properties;
}

const SelectNonNative: FC<SelectProps> = ({ className, classes, children }) => {
  const mergedClasses = useStyles({ source: classes ?? {}, target: {} },  "select" );
  return <div className={`${mergedClasses.range} ${className}`}>{children}</div>;
};

export default SelectNonNative;
