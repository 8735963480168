import { LoggerCore } from "@livelyvideo/log-client";
import { types } from "@livelyvideo/video-client-core";

export function onlineWatcher(logger: LoggerCore): types.DisposeFunction {
  const online = (event: Event): void => {
    const eventJson = JSON.stringify(event);
    logger.warn("user online", { eventJson });
  };

  const offline = (event: Event): void => {
    const eventJson = JSON.stringify(event);
    logger.warn("user offline", { eventJson });
  };

  window.addEventListener("online", online);
  window.addEventListener("offline", offline);

  return () => {
    window.removeEventListener("online", online);
    window.removeEventListener("offline", offline);
  };
}
