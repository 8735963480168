import { Properties } from "csstype";
import React, { FC } from "react";
import { useStyles } from "../../../styling/livelyStyles";
import { IconProps } from "../iconProps";
import styles from "../styles";
// All Icons
import CamCallIcon from "./CamCall";
import DelayStreamIcon from "./Delay";
import FullscreenIcon from "./Fullscreen";
import FullscreenExitIcon from "./FullscreenExit";
import LaunchOpenIcon from "./LaunchOpen";
import PauseIcon from "./Pause";
import PlayIcon from "./Play";
import SettingsIcon from "./Settings";
import SoundIcon from "./Sound";
import SoundOffIcon from "./SoundOff";

interface PlayerIconComponentProps extends IconProps {
  classes?: Properties;
  iconName:
    | "camcall"
    | "delay"
    | "fullscreen"
    | "fullscreenexit"
    | "launchopen"
    | "play"
    | "pause"
    | "settings"
    | "sound"
    | "soundoff";
}

const Icon: FC<PlayerIconComponentProps> = ({ classes = {}, iconName }) => {
  const ComponentMap: {
    camcall: FC<IconProps>;
    delay: FC<IconProps>;
    fullscreen: FC<IconProps>;
    fullscreenexit: FC<IconProps>;
    launchopen: FC<IconProps>;
    play: FC<IconProps>;
    pause: FC<IconProps>;
    settings: FC<IconProps>;
    sound: FC<IconProps>;
    soundoff: FC<IconProps>;
  } = {
    camcall: CamCallIcon,
    delay: DelayStreamIcon,
    fullscreen: FullscreenIcon,
    fullscreenexit: FullscreenExitIcon,
    launchopen: LaunchOpenIcon,
    play: PlayIcon,
    pause: PauseIcon,
    settings: SettingsIcon,
    sound: SoundIcon,
    soundoff: SoundOffIcon,
  };

  const mergedClasses = useStyles({ source: classes, target: styles },  "icons" );

  const iconClass = iconName.toLowerCase();
  const IconType = ComponentMap[iconName];

  return (
    <span className={`${mergedClasses.root}`}>
      <IconType className={`${mergedClasses.icon} ${iconClass}`} />
    </span>
  );
};

export default Icon;
