import { Properties } from "csstype";
import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { PlayerUiState } from "../../../../../../store";
import { LivelyPlayerUiContext } from "../../../../../context";
import ButtonIcon from "../../../../../ui-lib/Buttons/Icon";
import ButtonIconProps from "../../../../../ui-lib/Buttons/Icon/propTypes";
import Icon from "../../../../../ui-lib/Icons/Player";
import { RootStyles } from "../../../../../ui-lib/typings/css";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../../ErrorBoundary";

interface PlayButtonClasses extends RootStyles {
  icon?: Properties;
}

interface PlayButtonProps extends Partial<ButtonIconProps> {
  classes?: PlayButtonClasses;
}

const ModularPlayButton = observer(
  ({
    active,
    classes = {
      root: {},
      icon: {},
    },
    onClick,
    icon: ProvidedIcon,
    label = "Play Pause Toggle Button",
    screenReaderText,
    ...props
  }: PlayButtonProps) => {
    const componentName = "<PlayButton/>";

    const ctx = useContext<PlayerUiState | null>(LivelyPlayerUiContext);

    useUndefinedStoreError(ctx?.player != null, componentName);

    const togglePlay = (): void => {
      ctx.player.localVideoPaused = !ctx.player.localVideoPaused;
    };

    const handleClick = onClick ?? useUIEventError(togglePlay, componentName);

    const activeState: boolean = active ?? !ctx.player.localVideoPaused;

    const icon = activeState ? (
      <Icon iconName="pause" classes={classes?.icon} />
    ) : (
      <Icon iconName="play" classes={classes?.icon} />
    );

    return (
      <ButtonIcon
        classes={classes}
        active={activeState}
        inactiveClass={null}
        // disabled={!driverReady}
        label={label}
        icon={ProvidedIcon ?? icon}
        onClick={handleClick}
        data-selenium="play-pause-button"
        {...props}
      >
        {screenReaderText ?? `Click to ${activeState ? "Pause" : "Play"} Stream`}
      </ButtonIcon>
    );
  },
);

// eslint-disable-next-line react/function-component-definition
const PlayButtonWithErrorBoundary: FC<Partial<ButtonIconProps>> = ({
  icon: ProvidedIcon,
  label = "Play Pause Toggle Button",
  screenReaderText,
  classes,
  active,
  ...props
}: PlayButtonProps) => {
  const icon = ProvidedIcon || <Icon iconName="play" classes={classes?.icon} />;

  return (
    <ErrorBoundary
      render={() => (
        <ButtonIcon
          classes={classes}
          data-selenium="play-pause-button"
          disabled
          icon={icon}
          label={label}
          onClick={undefined}
          active={false}
          {...props}
        >
          {screenReaderText ?? "Click to Play Stream"}
        </ButtonIcon>
      )}
    >
      <ModularPlayButton
        icon={ProvidedIcon}
        label={label}
        screenReaderText={screenReaderText}
        classes={classes}
        active={active}
        {...props}
      />
    </ErrorBoundary>
  );
};

export default PlayButtonWithErrorBoundary;
