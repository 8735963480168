import { types } from "@livelyvideo/video-client-core";
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

type Props = React.VideoHTMLAttributes<HTMLVideoElement> & { source: MediaStream | types.PlayerAPI | null };

/**
 * @todo
 * This component should be removed once Encoder is converted to using players.
 */
export const TempVideo = forwardRef<HTMLVideoElement, Props>((props, ref): React.ReactElement => {
  const videoEl = useRef<HTMLVideoElement>(null);
  const { source, ...other } = props;

  useImperativeHandle(ref, () => videoEl.current as HTMLVideoElement); // it can't be null here

  useEffect(() => {
    const el = videoEl.current;
    if (el == null || source == null || el.srcObject === source) {
      return;
    }

    if (source instanceof MediaStream) {
      el.srcObject = source;
      el.play().catch((err) => {
        // ignore because it's probably already playing
      });
    } else {
      source.attachTo(el);
    }
  }, [source]);

  useEffect(() => {
    if (videoEl.current == null) {
      return;
    }

    if (!(source instanceof MediaStream) && !source?.localVideoPaused) {
      // resume it back after unmounting
      // see: https://html.spec.whatwg.org/multipage/media.html#playing-the-media-resource:media-element-83
      if (videoEl.current.paused && !videoEl.current.ended /* && videoEl.current.readyState > 2*/) {
        videoEl.current.play();
      }
    }
  });

  // eslint-disable-next-line jsx-a11y/media-has-caption
  return <video {...other} ref={videoEl} />;
});
