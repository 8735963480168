import type { Json } from "@livelyvideo/log-node";
import { extractAggregates } from "@livelyvideo/log-node";
import { types } from "mediasoup-client";
import { makeObservable, observable } from "mobx";
import { MediaStreamControllerAPI } from "../api";
import type { MediaDeviceInfo } from "../api/adapter/features/media-device";
import type { BroadcastAPI, BroadcastEvents, BroadcastOptions, PeerProducerOptions } from "../api/broadcast";
import type { CallAPI } from "../api/call";
import { ConsumerStats, ProducerStats } from "../api/stats";
import {
  DisableAudioError,
  DisableVideoError,
  EnableAudioError,
  EnableVideoError,
  StreamNotFoundError,
  UpdateBroadcastError,
  wrapNativeError,
} from "./errors";
import type { Call as InternalCall } from "./pvc/call/call";
import type Stream from "./pvc/call/stream";
import { onceCanceled } from "./utils/context/context";
import type { VcContext } from "./utils/context/vc-context";
import { ObservableEventEmitter } from "./utils/events/event-emitter";

export class Broadcast extends ObservableEventEmitter<BroadcastEvents> implements BroadcastAPI {
  static readonly displayName = "Broadcast";

  private readonly ctx: VcContext;

  private ctrl: MediaStreamControllerAPI;

  private readonly stream: Stream;

  private readonly pvcCall: InternalCall;

  readonly call: CallAPI;

  private readonly options: BroadcastOptions;

  state: "active" | "paused" | "closed" | "stall" | "error" = "active";

  private lastTrafficTS = 0;

  private readonly lastTrafficBitrate: number[] = [];

  constructor(
    ctx: VcContext,
    call: CallAPI,
    pvcCall: InternalCall,
    ctrl: MediaStreamControllerAPI,
    broadcastOptions: BroadcastOptions,
  ) {
    super();

    makeObservable(this, {
      state: observable,
    });

    const stream = pvcCall?.streams[broadcastOptions.streamName];
    if (pvcCall == null || stream == null) {
      throw new StreamNotFoundError("stream not found", {
        call,
        broadcast: this,
      });
    }

    this.ctx = ctx;
    this.ctrl = ctrl;
    this.call = call;
    this.pvcCall = pvcCall;
    this.stream = stream;
    this.options = broadcastOptions;

    if (this.options.simulcast) {
      this.options.videoProducerOptions = this.options.videoProducerOptions ?? {};
      this.options.videoProducerOptions.simulcast = this.options.simulcast;
    }

    if (this.call.isOwner) {
      this.lastTrafficTS = Date.now();
      pvcCall.on("webrtc-stats", this.bitrateObserver);
    }

    ctrl.on("audioDeviceChanged", this.onAudioDeviceChanged);
    ctrl.on("videoDeviceChanged", this.onVideoDeviceChanged);
    ctrl.on("audioMuted", this.onAudioMuted);
    ctrl.on("videoPaused", this.onVideoPaused);

    ctx.logger.attachObject(this);
    ctx.logger.trace("constructor()");

    this.sourceUpdate().catch((err) => {
      this.emitError(
        new UpdateBroadcastError("initial source update error", {
          broadcast: this,
          call: this.call,
          inner: wrapNativeError(err),
        }),
      );
    });

    onceCanceled(ctx).then((reason) => this.dispose(`Broadcast Class Context Cancelled: ${reason}`));

    this.addInnerDisposer(() => {
      try {
        this.stop();
      } catch (err) {
        this.ctx.logger.warn("unable to stop broadcast while disposing", { err: `${err}` });
      }

      if (this.options.disposeController) {
        this.ctrl.dispose("Broadcast options are set to disposeController");
      }
    });
  }

  get streamName(): string {
    return this.options.streamName;
  }

  get audioMuted(): boolean {
    return this.ctrl.audioMuted;
  }

  set audioMuted(value: boolean) {
    this.ctrl.audioMuted = value;
  }

  get videoHidden(): boolean {
    return this.ctrl.videoPaused;
  }

  set videoHidden(value: boolean) {
    this.ctrl.videoPaused = value;
  }

  private bitrateObserver(stats: ConsumerStats | ProducerStats): void {
    if (stats.kind !== "producer" || this.options.zeroBitrate == null) {
      return;
    }

    const timeout = this.options.zeroBitrate.timeout ?? 30000;
    const dropPercent = this.options.zeroBitrate.dropPercent ?? 0.04;

    let bitrate = stats.videoBitrate ?? 0;
    if (this.options.zeroBitrate.kind === "total") {
      bitrate += stats.audioBitrate;
    }

    const meanBitrate =
      this.lastTrafficBitrate.length === 0
        ? 0
        : this.lastTrafficBitrate.reduce((l, r) => l + r, 0) / this.lastTrafficBitrate.length;

    const minBitrate = meanBitrate * dropPercent;

    if (bitrate > minBitrate) {
      this.lastTrafficTS = Date.now();
      this.lastTrafficBitrate.push(bitrate);
      if (this.lastTrafficBitrate.length > 10) {
        this.lastTrafficBitrate.unshift();
      }
    } else if (Date.now() - this.lastTrafficTS > timeout) {
      const drop = 1 - bitrate / meanBitrate;
      this.ctx.logger.warn("zero bitrate", { drop, timeout, meanBitrate, lastBitrate: bitrate });
      this.emit("zeroBitrate", { call: this.call, broadcast: this, drop });
    }
  }

  pause(): void {
    if (this.ctrl.audioDeviceId != null) {
      this.stream.pauseAudio();
    }

    if (this.ctrl.videoDeviceId != null) {
      this.stream.pauseVideo();
    }

    this.state = "paused";
  }

  get controller(): MediaStreamControllerAPI {
    return this.ctrl;
  }

  replaceController(ctrl: MediaStreamControllerAPI): void {
    this.ctrl = ctrl;
  }

  resume(): void {
    if (this.ctrl.audioDeviceId != null) {
      this.stream
        .enableAudio(this.options.audioProducerOptions)
        .then(() => {
          this.state = "active";
        })
        .catch((err) => {
          this.emitError(
            new UpdateBroadcastError("unable to resume audio device", {
              broadcast: this,
              call: this.call,
              inner: wrapNativeError(err),
            }),
          );
        });
    }

    if (this.ctrl.videoDeviceId != null) {
      this.stream
        .enableVideo(this.options.videoProducerOptions)
        .then(() => {
          this.state = "active";
        })
        .catch((err) => {
          this.emitError(
            new UpdateBroadcastError("unable to resume video device", {
              broadcast: this,
              call: this.call,
              inner: wrapNativeError(err),
            }),
          );
        });
    }
  }

  private stop(): void {
    this.ctx.logger.debug("stop()");
    this.state = "closed";
    this.stream.close();
    this.ctrl.off("source", this.sourceUpdate);
    this.ctrl.off("audioDeviceChanged", this.onAudioDeviceChanged);
    this.ctrl.off("videoDeviceChanged", this.onVideoDeviceChanged);
    this.ctrl.off("audioMuted", this.onAudioMuted);
    this.ctrl.off("videoPaused", this.onVideoPaused);
    this.pvcCall.off("webrtc-stats", this.bitrateObserver);
    this.pvcCall.removeStream(this.stream.streamName);
  }

  private async sourceUpdate(): Promise<void> {
    this.ctx.logger.debug("sourceUpdate()", {
      audio: this.ctrl.audioDeviceId,
      video: this.ctrl.videoDeviceId,
    });

    if (
      this.ctrl.videoDeviceId != null &&
      !this.ctrl.videoPaused &&
      !this.options.audioOnly &&
      !this.ctrl.videoDisabled
    ) {
      try {
        await this.pvcCall.enableVideo(this.options.videoProducerOptions, this.stream.streamName, true);
      } catch (err) {
        this.emitError(
          new EnableVideoError("unable to enable video on `source` event", {
            inner: wrapNativeError(err),
            broadcast: this,
            call: this.call,
          }),
        );
      }
    } else if (this.options.audioOnly) {
      this.ctx.logger.debug("Broadcast is set to audioOnly, video not produced", { options: this.options });
    }

    if (this.ctrl.audioDeviceId != null && !this.ctrl.audioMuted && !this.options.videoOnly) {
      try {
        await this.pvcCall.enableAudio(this.options.audioProducerOptions, this.stream.streamName, true);
      } catch (err) {
        this.emitError(
          new EnableAudioError("unable to enable audio on `source` event", {
            inner: wrapNativeError(err),
            broadcast: this,
            call: this.call,
          }),
        );
      }
    } else if (this.options.videoOnly) {
      this.ctx.logger.debug("Broadcast is set to videoOnly, audio not produced", { options: this.options });
    }
  }

  private async onAudioDeviceChanged(device: MediaDeviceInfo | null): Promise<void> {
    this.ctx.logger.debug("audioDeviceChanged()", { device });

    if (device == null) {
      try {
        await this.pvcCall.disableAudio(this.stream.streamName);
      } catch (err) {
        if (err instanceof types.InvalidStateError && err.message === "track ended") {
          this.ctx.logger.warn("broadcast has not been updated because the audio track is ended");
          return;
        }

        const inner = err instanceof Error ? err : null;
        this.emitError(
          new DisableAudioError("unable to disable audio on `audioDeviceChanged` event", {
            inner,
            broadcast: this,
            call: this.call,
          }),
        );
      }
    } else {
      try {
        await this.pvcCall.enableAudio(this.options.audioProducerOptions, this.stream.streamName, true);
      } catch (err) {
        if (err instanceof types.InvalidStateError && err.message === "track ended") {
          this.ctx.logger.warn("broadcast has not been updated because the audio track is ended");
          return;
        }

        const inner = err instanceof Error ? err : null;
        this.emitError(
          new EnableAudioError("unable to enable audio on `audioDeviceChanged` event", {
            inner,
            broadcast: this,
            call: this.call,
          }),
        );
      }
    }
  }

  private async onVideoDeviceChanged(device: MediaDeviceInfo | null): Promise<void> {
    this.ctx.logger.debug("videoDeviceChanged()", { device });

    // await this.ctrl.source();

    if (device == null) {
      try {
        await this.pvcCall.disableVideo(this.stream.streamName);
      } catch (err) {
        if (err instanceof types.InvalidStateError && err.message === "track ended") {
          this.ctx.logger.warn("broadcast has not been updated because the video track is ended");
          return;
        }

        const inner = err instanceof Error ? err : null;
        this.emitError(
          new DisableVideoError("unable to disable video on `videoDeviceChanged` event", {
            inner,
            broadcast: this,
            call: this.call,
          }),
        );
      }
    } else {
      try {
        await this.pvcCall.enableVideo(this.options.videoProducerOptions, this.stream.streamName, true);
      } catch (err) {
        if (err instanceof types.InvalidStateError && err.message === "track ended") {
          this.ctx.logger.warn("broadcast has not been updated because the video track is ended");
          return;
        }

        this.emitError(
          new EnableVideoError("unable to enable video on `videoDeviceChanged` event", {
            inner: wrapNativeError(err),
            broadcast: this,
            call: this.call,
          }),
        );
      }
    }
  }

  private async onAudioMuted(value: boolean): Promise<void> {
    this.ctx.logger.debug("audioMuted()", { value });

    if (!this.ctrl.hasActiveAudioTrack()) {
      return;
    }

    if (value) {
      try {
        await this.pvcCall.muteAudio(this.stream.streamName);
      } catch (err) {
        this.emitError(
          new EnableAudioError("unable to mute audio on `audioMuted` event", {
            inner: wrapNativeError(err),
            broadcast: this,
            call: this.call,
          }),
        );
      }
    } else {
      try {
        await this.pvcCall.unmuteAudio(this.stream.streamName, this.options.audioProducerOptions);
      } catch (err) {
        this.emitError(
          new DisableAudioError("unable to unmute audio on `audioMuted` event", {
            inner: wrapNativeError(err),
            broadcast: this,
            call: this.call,
          }),
        );
      }
    }
  }

  private async onVideoPaused(value: boolean): Promise<void> {
    this.ctx.logger.debug("videoPaused()", { value });

    if (!this.ctrl.hasActiveVideoTrack()) {
      return;
    }

    if (value) {
      try {
        await this.pvcCall.pauseVideo(this.stream.streamName);
      } catch (err) {
        const inner = err instanceof Error ? err : null;
        this.emitError(
          new DisableVideoError("unable to pause video on `videoPaused` event", {
            inner,
            broadcast: this,
            call: this.call,
          }),
        );
      }
    } else {
      try {
        await this.pvcCall.unpauseVideo(this.stream.streamName, this.options.videoProducerOptions);
      } catch (err) {
        const inner = err instanceof Error ? err : null;
        this.emitError(
          new EnableVideoError("unable to unpause video on `videoPaused` event", {
            inner,
            broadcast: this,
            call: this.call,
          }),
        );
      }
    }
  }

  async hotswapProducer(kind: types.MediaKind, producerOptions: PeerProducerOptions): Promise<void> {
    this.ctx.logger.debug("hotswapProducer()", { kind, producerOptions });
    await this.pvcCall.hotswapProducer(kind, this.streamName, producerOptions);
  }

  toJSON(): Json {
    return {
      msc: this.controller,
      stream: this.stream,
      options: this.options,

      aggregates: {
        ...extractAggregates(this.stream, "support"),
        support: this.ctx.support.hash,
        state: this.state,
      },
    };
  }
}
