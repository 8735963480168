import type { LoggerCore } from "@livelyvideo/log-client";
import { IObservableArray } from "mobx";
import type { ManifestFormats, ManifestJson } from "../../api/manifest";
import type { PlayerEvents } from "../../api/player";
import {
  BitrateSwitchingEvents,
  BitrateSwitchingFeature,
  TranscodeScoreLevel,
} from "../../api/player/features/bitrate-switching";
import { Feature as PlayerFeature } from "../../api/player/features/feature";
import { MutedAutoplayFeature } from "../../api/player/features/muted-autoplay";
import { VideoElement } from "../../api/typings/video-element";
import { DriverNotSupportedError, PlayingIssueError } from "../errors";
import MediaLoader from "../media-loader";
import { supportsNativeHls } from "../utils/browser-support/browser";
import { VcContext } from "../utils/context/vc-context";
import { CorePlayer, CorePlayerOptions } from "./core";
import { fetchManifestQualities } from "./helper";

export type NativeHlsPlayerEvents = PlayerEvents & BitrateSwitchingEvents;

export type NativeHlsPlayerOptions = {
  autoPlay?: boolean;
  muted?: boolean;
};
export class NativeHlsPlayer
  extends CorePlayer<NativeHlsPlayerOptions, ManifestJson, NativeHlsPlayerEvents>
  implements BitrateSwitchingFeature, MutedAutoplayFeature
{
  constructor(ctx: VcContext, provider: MediaLoader, options: CorePlayerOptions) {
    super(ctx, provider, options);

    this.on("currentQuality", () => {
      this.restart(true);
      this.srcSet = false;
    });
  }

  srcSet = false;

  static async isSupported(logger?: LoggerCore): Promise<boolean> {
    return supportsNativeHls(logger);
  }

  async isSupported(): Promise<boolean> {
    return NativeHlsPlayer.isSupported();
  }

  static get format(): keyof ManifestFormats {
    return "mp4-hls";
  }

  get format(): keyof ManifestFormats {
    return NativeHlsPlayer.format;
  }

  protected async handleSource(manifest: ManifestJson | null): Promise<void> {
    this.ctx.logger.debug("native-hls: handleSource()");

    const format = manifest?.formats["mp4-hls"];
    if (format == null) {
      this.emitError(
        new DriverNotSupportedError("manifest doesn't contains 'mp4-hls' format", {
          manifest,
          loader: this.provider as MediaLoader,
        }),
      );
      return;
    }

    this.source = format.manifest;

    const [encodings, needToUpdate] = this.handleEncodings(format);

    const qualities = this.availableQualities as IObservableArray;
    
    qualities.replace(fetchManifestQualities(encodings, format.origin?.location ?? null));

    if (this.hostEl != null && this.srcSet === false) {
      this.srcSet = true;
      this.hostEl.src = this.source;
      if (this.options.autoPlay) {
        try {
          await this.play(true);
        } catch (err) {
          const inner = err instanceof Error ? err : null;
          this.emitError(
            new PlayingIssueError("autoplay call failed after handleSource()", {
              inner,
              player: this,
            }),
          );
        }
      }
    }
    if (needToUpdate) {
      this.setPreferredLevel(TranscodeScoreLevel.Highest);
    }
  }

  async attachTo(el: VideoElement): Promise<void> {
    await super.attachTo(el);
    if (this.options.autoPlay) {
      try {
        await this.play(true);
      } catch (err) {
        const inner = err instanceof Error ? err : null;
        this.emitError(
          new PlayingIssueError("autoplay call failed after attachTo()", {
            inner,
            player: this,
          }),
        );
      }
    }
  }

  protected get implementedFeatures(): PlayerFeature[] {
    return [PlayerFeature.BITRATE_SWITCHING, PlayerFeature.MUTED_AUTOPLAY];
  }
}
