/**
 * @todo resolve this linting error of no unused vars re: React import.
 */
import { Component, useEffect, useState } from "react";

type ErrorBoundaryState = {
  error: Error | null;
};

type ErrorBoundaryProps = {
  children: JSX.Element;
  render: () => JSX.Element | null;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidCatch(error: Error): void {
    // const { logType } = this.props;
    this.setState({ error });
    // if (logger) {
    //   logger(error, logType);
    // }
  }

  render(): JSX.Element | null {
    const { children, render } = this.props;
    const { error } = this.state;

    if (error) {
      return render();
    }
    return children;
  }
}


function useUndefinedStoreError(condition: boolean, component: string): asserts condition {
  if (!condition) {
    throw new Error(`[LivelyVideo]: Undefined store observable(s) and/or method(s) at ${component}`);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (...args: any[]) => void;

function useUIEventError<T extends AnyFunction | undefined>(eventFunction: T, component: string): T {
  const [error, setError] = useState<Error | null | unknown>(null);

  // if (eventFunction == null) {
  //   return;
  // }

  const errorHandledFunction: AnyFunction = (...args) => {
    if (eventFunction != null) {
      try {
        eventFunction(...args);
      } catch (e) {
        setError(e);
      }
    }
  };

  useEffect(() => {
    if (error instanceof Error && eventFunction != null) {
      const functionName = eventFunction.name || "undefined function";
      throw new Error(`[LivelyVideo]: ${error.name} ${error.message} in ${functionName} at ${component}.`);
    }
  }, [component, error, eventFunction]);

  return errorHandledFunction as T;
}

export { ErrorBoundary, useUndefinedStoreError, useUIEventError };
