/* eslint-disable @typescript-eslint/no-explicit-any */
// @todo: write types instead of any

import { LoggerCore } from "@livelyvideo/log-client";
import Bowser from "bowser";
import { types } from "mediasoup-client";
import type { ApplyConstrainsFeature } from "../../api/adapter/features/apply-constrains-feature";
import type { AudioContextConstructor, AudioContextFeature } from "../../api/adapter/features/audio-context";
import type { CpuUsageFeature } from "../../api/adapter/features/cpu-usage";
import type { CreateCanvasElementFeature } from "../../api/adapter/features/create-canvas-element";
import type { CreateVideoElementFeature } from "../../api/adapter/features/create-video-element";
import type { DebuggingFeature, Performance } from "../../api/adapter/features/debugging";
import { Feature, Features } from "../../api/adapter/features/feature";
import type { HlsJsFeature } from "../../api/adapter/features/hlsjs";
import type { LocalStorage, LocalStorageFeature } from "../../api/adapter/features/local-storage";
import type {
  MediaDeviceFeature,
  MediaDevices as MediaDevicesInterface,
} from "../../api/adapter/features/media-device";
import type {
  FileSystemFileHandle,
  MediaRecorderConstructor,
  MediaRecorderFeature,
  SaveFilePickerOptions,
} from "../../api/adapter/features/media-recorder";
import type { MediaSourceFeature } from "../../api/adapter/features/media-source";
import type {
  MediaStream,
  MediaStreamConstructor,
  MediaStreamFeature,
  MediaStreamTrack,
  MediaTrackConstraints,
} from "../../api/adapter/features/media-stream";
import type { MpegtsFeature } from "../../api/adapter/features/mpegts";
import type { NetworkInformation, NetworkInformationFeature } from "../../api/adapter/features/network-information";
import type { Permissions, PermissionsFeature } from "../../api/adapter/features/permissions";
import type { ScreenOrientation, ScreenOrientationFeature } from "../../api/adapter/features/screen-orientation";
import type { WebSocketFeature } from "../../api/adapter/features/web-socket";
import type { DeviceAPI } from "../../api/device";
import { CanvasElement } from "../../api/typings/canvas-element";
import type { VideoElement } from "../../api/typings/video-element";

const globalScope =
  (globalThis as any) ??
  (function fn(this: any): any {
    return this;
  })();

const HLSJS_URL = process.env.HLSJS_URL ?? "https://cdnjs.cloudflare.com/ajax/libs/hls.js/1.0.8/hls.min.js";
if (process.env.HLSJS_BUNDLED !== "false") {
  // eslint-disable-next-line global-require
  globalScope.Hls = require("hls.js");
}

const MPEGTS_URL = process.env.MPEGTS_URL ?? "https://cdn-us.livelyvideo.tv/mpegts.js";
if (process.env.MPEGTS_BUNDLED !== "false") {
  try {
    // eslint-disable-next-line global-require
    globalScope.mpegts = require("mpegts.js");
  } catch (err) {
    // pass
  }
}

class NotSupportedError extends Error {
  constructor(prop: string, err?: Error) {
    super(`${prop} is not supported. ${err || ""}`);
  }
}

export class WebDevice
  implements
    DeviceAPI,
    AudioContextFeature,
    CpuUsageFeature,
    CreateCanvasElementFeature,
    CreateVideoElementFeature,
    DebuggingFeature,
    LocalStorageFeature,
    ScreenOrientationFeature,
    ApplyConstrainsFeature,
    MediaDeviceFeature,
    MediaStreamFeature,
    MediaSourceFeature,
    NetworkInformationFeature,
    HlsJsFeature,
    WebSocketFeature,
    PermissionsFeature,
    MpegtsFeature,
    MediaRecorderFeature
{
  static readonly displayName = "WebDevice";

  constructor() {
    const estimateFunc = globalScope?.navigator?.storage?.estimate;
    if (typeof estimateFunc === "function") {
      this.storageEstimate = estimateFunc.bind(globalScope.navigator.storage);
    }

    const logger = new LoggerCore("VDC-core").setLoggerMeta("client", "VDC").appendChain(WebDevice);

    if (!globalScope) {
      logger.error("globalScope is undefined");
    }

    if (!globalScope?.navigator) {
      logger.info("globalScope navigator is undefined");
    }
  }

  processRtpCapabilities(direction: "send" | "recv", rtpCapabilities: types.RtpCapabilities): types.RtpCapabilities {
    return rtpCapabilities;
  }

  get isFirefox(): boolean {
    const browserName = Bowser.getParser(this.userAgent).getBrowserName().toLowerCase();
    return browserName === "firefox";
  }

  get isIosDevice(): boolean {
    let ios = false;
    if (this.platform != null) {
      ios = ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(this.platform);
    }
    return ios;
  }

  get isAndroidDevice(): boolean {
    let android = false;
    if (this.userAgent != null) {
      android = /Android/.test(this.userAgent);
    }
    return android;
  }

  get isMobileDevice(): boolean {
    let mobileDevice = false;
    if (this.userAgent != null) {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(this.userAgent)) {
        mobileDevice = true;
      }
    }
    return mobileDevice;
  }

  // basic features

  get hidden(): boolean {
    return globalScope.document.hidden ?? false;
  }

  get appVersion(): string | null {
    return globalScope?.navigator?.appVersion ?? null;
  }

  get platform(): string | null {
    return globalScope?.navigator?.platform ?? null;
  }

  readonly globals: Map<string, any> = new Map<string, any>();

  get supportFullscreen(): boolean {
    const v = globalScope.document.createElement("video");
    const d = globalScope.document;

    return (
      globalScope.document.fullscreenEnabled === true ||
      d.mozFullScreenEnabled === true ||
      d.msFullscreenEnabled === true ||
      d.webkitSupportsFullscreen === true ||
      d.webkitFullscreenEnabled === true ||
      v.webkitRequestFullScreen != null ||
      v.webkitEnterFullScreen != null
    );
  }

  get supportSharedDevices(): boolean {
    if (this.userAgent != null) {
      return !(this.isFirefox || this.isIosDevice || this.isAndroidDevice);
    }
    return false;
  }

  get onLine(): boolean {
    return globalScope?.navigator?.onLine ?? true;
  }

  get userAgent(): string {
    return globalScope?.navigator?.userAgent ?? "<unknown ua>";
  }

  get permissions(): Permissions {
    return globalScope?.navigator?.permissions;
  }

  addEventListener(type: string, listener: (event: any) => void): void {
    globalScope.addEventListener(type, listener);
  }

  removeEventListener(type: string, listener: (event: any) => void): void {
    globalScope.removeEventListener(type, listener);
  }

  setInterval(fn: () => void, ms: number): number {
    return globalScope.setInterval(fn, ms);
  }

  // optional features

  get console(): Console {
    return globalScope.console;
  }

  get AudioContext(): AudioContextConstructor {
    return globalScope.AudioContext ?? globalScope.webkitAudioContext;
  }

  get MediaRecorder(): MediaRecorderConstructor {
    return globalScope.MediaRecorder;
  }

  get MediaStream(): MediaStreamConstructor {
    return globalScope.MediaStream;
  }

  get Uint8Array(): Uint8ArrayConstructor {
    return globalScope.Uint8Array;
  }

  get mediaDevices(): MediaDevicesInterface {
    return globalScope?.navigator?.mediaDevices;
  }

  get connection(): NetworkInformation {
    return (
      globalScope?.navigator?.connection ??
      globalScope?.navigator?.mozConnection ??
      globalScope?.navigator?.webkitConnection
    );
  }

  get localStorage(): LocalStorage {
    return globalScope.localStorage;
  }

  get performance(): Performance {
    return globalScope.performance;
  }

  get screenOrientation(): ScreenOrientation {
    const scr: any = globalScope.screen;
    return (scr.orientation ?? {}).type ?? scr.mozOrientation ?? scr.msOrientation;
  }

  get MediaSource(): any {
    return globalScope.MediaSource;
  }

  get WebSocket(): any {
    return globalScope.WebSocket;
  }

  get SourceBuffer(): any {
    return globalScope.SourceBuffer;
  }

  // Hls.js
  get Hls(): any {
    return globalScope.Hls;
  }

  applyConstraints(track: MediaStreamTrack, constraints: MediaTrackConstraints): Promise<void> {
    return track.applyConstraints(constraints);
  }

  createVideoElement(): VideoElement {
    const video = globalScope.document.createElement("video");
    video.autoplay = true;
    video.setAttribute("webkit-playsinline", "true");
    video.setAttribute("playsinline", "true");
    const fallbackText = globalScope.document.createTextNode("Sorry, your browser doesn't support embedded videos.");
    video.appendChild(fallbackText);
    return video;
  }

  createCanvasElement(): CanvasElement {
    const canvas = globalScope.document.createElement("canvas");
    return canvas;
  }

  supportsMediaStreamCapture(el: any): boolean {
    const support = el?.captureStream;
    if (!support) {
      // @todo add logger warning
    }
    return support;
  }

  createVideoStub(): Promise<MediaStream> {
    throw new Error("not implemented");
  }

  disableCpuStats(): void {
    // @todo: implement me
  }

  enableCpuStats(): void {
    // @todo: implement me
  }

  averageCpuUsage(interval: number): number {
    return 0;
  }

  isCodecSupported(codec: string): boolean {
    return false;
  }

  isHlsLoaded(): boolean {
    try {
      return this.Hls != null;
    } catch (err) {
      return false;
    }
  }

  hlsPromise?: Promise<void>;

  loadHlsScript(path?: string): Promise<void> {
    if (this.hlsPromise != null) {
      return this.hlsPromise;
    }

    if (this.isHlsLoaded()) {
      return Promise.resolve();
    }

    const newPath = path ?? HLSJS_URL;

    this.hlsPromise = new Promise<void>((resolve, reject) => {
      // Add a timeout
      if (this.isHlsLoaded()) {
        resolve();
        return;
      }

      const script = globalScope.document.createElement("script");
      script.setAttribute("src", newPath ?? "");
      script.onload = () => {
        if (!this.isHlsLoaded()) {
          reject(new Error("hlsjs not loaded"));
        } else {
          resolve();
        }
      };
      script.onerror = (err: Error) => {
        reject(new Error(`hlsjs not loaded: ${err}`));
      };

      globalScope.document.body.appendChild(script);
    });
    return this.hlsPromise;
  }

  get mpegts(): any {
    return globalScope.mpegts;
  }

  isMpegtsLoaded(): boolean {
    try {
      return this.mpegts != null;
    } catch (err) {
      return false;
    }
  }

  mpegtsPromise?: Promise<void>;

  loadMpegtsScript(path?: string): Promise<void> {
    if (this.mpegtsPromise != null) {
      return this.mpegtsPromise;
    }

    if (this.isMpegtsLoaded()) {
      return Promise.resolve();
    }

    const newPath = path ?? MPEGTS_URL;

    this.mpegtsPromise = new Promise<void>((resolve, reject) => {
      // Add a timeout
      if (this.isMpegtsLoaded()) {
        resolve();
        return;
      }

      const script = globalScope.document.createElement("script");
      script.setAttribute("src", newPath ?? "");
      script.onload = () => {
        if (!this.isMpegtsLoaded()) {
          reject(new Error("mpegts not loaded"));
        } else {
          resolve();
        }
      };
      script.onerror = (err: Error) => {
        reject(new Error(`mpegts not loaded: ${err}`));
      };

      globalScope.document.body.appendChild(script);
    });
    return this.mpegtsPromise;
  }

  setTimeout = setTimeout.bind(globalScope);

  // setInterval = setInterval.bind(globalScope);

  clearTimeout = clearTimeout.bind(globalScope);

  clearInterval = clearInterval.bind(globalScope);

  // MediaRecorder feature

  showSaveFilePicker(options: SaveFilePickerOptions): FileSystemFileHandle {
    return globalScope?.showSaveFilePicker(options);
  }

  confirmMessage(message: string): void {
    globalScope?.confirm(message);
  }

  storageEstimate?: () => Promise<{ usage: number; quota: number }>;

  isImplements<K extends Feature, T extends Features[K]>(feature: K): this is this & T {
    try {
      switch (feature) {
        case Feature.NETWORK_INFORMATION:
          return this.connection != null;

        case Feature.CREATE_VIDEO_ELEMENT:
          return this.createVideoElement != null;

        case Feature.CREATE_CANVAS_ELEMENT:
          return this.createCanvasElement != null;

        case Feature.DEBUGGING:
          return this.console != null && this.performance != null;

        case Feature.SCREEN_ORIENTATION:
          return this.screenOrientation != null;

        case Feature.MEDIA_RECORDER:
          return this.MediaRecorder != null;

        case Feature.APPLY_CONSTRAINTS:
          return true;

        case Feature.MEDIA_DEVICE:
          return this.mediaDevices != null;

        case Feature.MEDIA_STREAM:
          return this.MediaStream != null;

        case Feature.MEDIA_SOURCE:
          return this.MediaSource != null && this.SourceBuffer != null;

        case Feature.WEB_SOCKET:
          return this.WebSocket != null;

        case Feature.LOCAL_STORAGE:
          try {
            return this.localStorage != null;
          } catch (err) {
            return false;
          }

        case Feature.AUDIO_CONTEXT:
          return this.AudioContext != null;

        case Feature.CPU_USAGE:
          return false;

        case Feature.HLSJS:
          return true;

        case Feature.PERMISSIONS:
          return this.permissions != null;

        case Feature.MPEGTS:
          return true;

        default:
          return false;
      }
    } catch (err) {
      if (err instanceof NotSupportedError) {
        return false;
      }
      throw err;
    }
  }
}
