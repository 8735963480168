import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { EncoderUiState } from "../../../../../../store";
import { LivelyEncoderUiContext } from "../../../../../context";
import ButtonIcon from "../../../../../ui-lib/Buttons/Icon";
import ButtonIconProps from "../../../../../ui-lib/Buttons/Icon/propTypes";
import Icon, { IconStyleProps } from "../../../../../ui-lib/Icons";
import { RootStyles } from "../../../../../ui-lib/typings/css";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../../ErrorBoundary";

interface SettingsButtonClasses extends RootStyles {
  icon?: IconStyleProps;
}

interface SettingsButtonProps extends Partial<ButtonIconProps> {
  classes?: SettingsButtonClasses;
}

const ModularSettingsButton = observer(
  ({
    active,
    icon: ProvidedIcon,
    label = "Options",
    onClick,
    screenReaderText,
    classes = {
      root: {},
      icon: {},
    },
    ...props
  }: SettingsButtonProps) => {
    const componentName = "<EncoderSettingsButton/>";
    const ctx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);

    useUndefinedStoreError(ctx?.viewSettings != null, componentName);

    const toggleSettings = (): void => {
      ctx.viewSettings = !ctx.viewSettings;
    };

    const handleClick = onClick || useUIEventError(toggleSettings, componentName);

    const activeState: boolean = active ?? ctx.viewSettings ?? false;
    const icon = <Icon iconName="settings" classes={classes?.icon} />;

    return (
      <ButtonIcon
        data-selenium="settings-button"
        active={activeState}
        activeClass={null}
        inactiveClass={null}
        icon={ProvidedIcon || icon}
        label={label}
        onClick={handleClick}
        classes={classes}
        {...props}
      >
        {screenReaderText ?? `Click to ${activeState ? "Hide" : "Show"} Settings`}
      </ButtonIcon>
    );
  },
);

// eslint-disable-next-line react/function-component-definition
const SettingsButtonWithErrorBoundary: FC<Partial<ButtonIconProps>> = ({
  icon: ProvidedIcon,
  classes,
  label = "Options",
  screenReaderText,
  style = {},
  ...props
}: SettingsButtonProps) => {
  const icon = ProvidedIcon || <Icon iconName="settings" classes={classes?.icon} />;

  return (
    <ErrorBoundary
      render={() => (
        <ButtonIcon
          data-selenium="settings-button"
          disabled
          icon={icon}
          label={label}
          classes={classes}
          onClick={undefined}
          active={false}
          {...props}
        >
          {screenReaderText ?? "Click to Show Settings"}
        </ButtonIcon>
      )}
    >
      <ModularSettingsButton
        icon={ProvidedIcon}
        label={label}
        screenReaderText={screenReaderText}
        classes={classes}
        {...props}
      />
    </ErrorBoundary>
  );
};

export default SettingsButtonWithErrorBoundary;
