import React, { forwardRef, useContext } from "react";
import { EncoderUiState, PlayerUiState } from "../../../../store";
import { LivelyEncoderUiContext, LivelyPlayerUiContext } from "../../../context";
import { useStyles } from "../../../styling/livelyStyles";
import type { RootStyles } from "../../typings/css";
import ButtonIconProps from "./propTypes";
import styles from "./styles";

/**
 * Configurable Icon Button component (for an icon button).
 */
const ButtonIcon = forwardRef<HTMLButtonElement, ButtonIconProps>(
  (
    {
      active = true,
      children,
      activeClass = "lv-button--on",
      inactiveClass = "lv-button--off",
      classes = {},
      classNames = "",
      disabled = false,
      icon,
      label = "Default Button",
      onClick,
      style = {},
      ...props
    },
    ref,
  ) => {
    const playerCtx = useContext<PlayerUiState | null>(LivelyPlayerUiContext);
    const encoderCtx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);

    let getStyles: RootStyles;
    if (encoderCtx) {
      getStyles = styles(encoderCtx?.mobileDevice ?? false);
    } else {
      getStyles = styles(playerCtx?.mobileDevice ?? false);
    }

    const mergedClasses = useStyles({ source: classes, target: getStyles },  "livelyIconButton" );
    let loweredLabel;
    if (typeof label !== "undefined") {
      loweredLabel = label.replace(/\s+/g, "-").toLowerCase();
    }

    const defaultButton = (
      <button
        aria-label={label}
        className={`${mergedClasses.root} ${active ? activeClass ?? "" : inactiveClass ?? ""} ${
          classNames && classNames
        }`}
        data-selenium={`${label && loweredLabel}-button`}
        disabled={disabled}
        onClick={onClick ?? undefined}
        ref={ref}
        type="button"
        title={label}
        {...props}
      >
        {icon != null && icon}
      </button>
    );

    return <>{defaultButton}</>;
  },
);

ButtonIcon.displayName = "ButtonIcon";

export default ButtonIcon;
