/* eslint-disable import/no-cycle,no-constructor-return,@typescript-eslint/ban-types */
import { IEventEmitter } from "@livelyvideo/events-typed";
import { LoggerCore } from "@livelyvideo/log-client";
import { action, makeObservable } from "mobx";
import type { CallAPI, CallOptions } from "../api/call";
import { SourceProvider } from "../api/common";
import { isManifest, Manifest } from "../api/manifest";
import type { ManifestPlayerSpecList, PlayerAnySpec, PlayerAPI, PlayerOptionsMap } from "../api/player";
import {
  JoinCallOptions,
  RequestPlayerOptions,
  VideoClientAPI,
  VideoClientEvents,
  VideoClientOptions,
} from "../api/video-client";
import packageJson from "../package-json";
import { contextId, instanceId } from "../utils/common";
import { Call } from "./call";
import { NotSupportedError, ValidationError, VideoClientError } from "./errors";
import { MediaStreamController } from "./media-controller";
import MediaLoader from "./media-loader";
import { MediasoupSource } from "./mediasoup-source";
import { ManifestPlayer } from "./player/manifest";
import { MediasoupPlayer } from "./player/mediasoup";
import type { Join } from "./pvc/call";
import { makeBounded } from "./utils/bind";
import { context } from "./utils/context/context";
import type { VcContext } from "./utils/context/vc-context";
import { ObservableEventEmitter } from "./utils/events/event-emitter";
import { extendContext } from "./utils/logger";
import { addProxy, EventsHandler, removeProxy } from "./utils/proxy/events-handler";
import { Support } from "./utils/support";

const defaultPlayerOptions: RequestPlayerOptions = {
  autoPlay: true,
  refetch: false,
  displayPoster: "preview",
  players: [{ id: "webrtc" }, { id: "hlsjs" }],
  muted: false,
  volume: 0.5,
  driverFailoverSeconds: 10,
};

const proxyEventsHandler = new EventsHandler<VideoClientEvents, VideoClient>();

/**
 * Entry point class for conferences and cam2cam activities
 */
export class VideoClient extends ObservableEventEmitter<VideoClientEvents> implements VideoClientAPI {
  static readonly displayName = "VideoClient";

  [addProxy]!: (target: IEventEmitter<{}>) => void;

  [removeProxy]!: (target: IEventEmitter<{}>) => void;

  options: VideoClientOptions = { livelyEndpoints: [] };

  private readonly ctx: VcContext;

  /**
   * Main endpoint to work with video-client
   *
   * @throws TypeGuardError if options are not correct. See VideoClientOptions
   */
  constructor(options?: VideoClientOptions) {
    super(false);

    this.setMaxListeners(20);

    const logger: LoggerCore = options?.logger ?? new LoggerCore("VDC-core");
    delete options?.logger;

    const client = options?.loggerConfig?.clientName ?? "VDC";
    const prevClient = logger.getLoggerMeta("client");
    const prevRelease = logger.getLoggerMeta("release");
    const prevPackage = logger.getLoggerMeta("package");

    logger
      .setLoggerMeta(
        "package",
        prevPackage != null && prevPackage !== "VDC-core" ? `${prevPackage}/VDC-core` : "VDC-core",
      )
      .setLoggerMeta("client", prevClient != null ? `${prevClient}/${client}` : client)
      .setLoggerMeta("release", prevRelease != null ? `${prevRelease}/${packageJson.version}` : packageJson.version)
      .setLoggerMeta("commitHash", packageJson.commit)
      .setLoggerMeta("contextId", contextId() ?? "")
      .setLoggerMeta("instanceId", instanceId() ?? "")
      .appendChain(VideoClient)
      .setMessageAggregate("displayName", options?.displayName);

    const support = new Support(new LoggerCore("VDC-core").extend(logger).appendChain(Support));
    this.ctx = context({
      logger,
      videoClient: this,
      statsDebugLogs: options?.stats?.debugLogs,
      support,
      chain: VideoClient.displayName,
    });

    makeObservable(this, {
      requestPlayer: action,
    });

    const self = makeBounded(new Proxy(this, proxyEventsHandler));

    self.init(options);

    logger.trace("constructor()", { livelyEndpoints: options?.livelyEndpoints });

    return self;
  }

  private init(options?: VideoClientOptions): void {
    // For backwards compatibility. VideoClient.autoPlay should be deprecated.
    // @todo move to unified option validation (e.g. zog)
    if (options?.autoPlay != null && options?.playerOptions != null && options?.playerOptions?.autoPlay == null) {
      options.playerOptions.autoPlay = options.autoPlay;
    }

    this.updateOptions(options);

    this.on("error", (err) => {
      VideoClientError.log(err, this.ctx.logger);
    });
  }

  /**
   * @throws VideoClientValidationError if options is not correct VideoClientOptions
   */
  private static validateOptions(videoClientOptions: VideoClientOptions): VideoClientOptions {
    return videoClientOptions;
  }

  /**
   * Updates current options
   *
   * @throws TypeGuardError if options is not correct VideoClientOptions
   */
  updateOptions(options?: VideoClientOptions): void {
    if (options == null) {
      return;
    }

    const validatedOpts = VideoClient.validateOptions(options);

    if (validatedOpts.logger != null) {
      this.ctx.logger.warn("using `logger` in updateOptions() is not allowed");
      delete validatedOpts.logger;
    }
    this.options = { ...this.options, ...validatedOpts };
  }

  /**
   * Returns true if Video Client with provided options supports
   * a creating or joining calls
   */
  async callSupported(): Promise<boolean> {
    await this.ctx.support.ready;
    return this.ctx.support.supports("h264");
  }

  /**
   * Creates and establishes a connection to a new call
   * Returns Call which is ready to use (e.g. create broadcasts)
   * but doesn't have any peers yet
   */
  async createCall(callOptions: CallOptions): Promise<CallAPI> {
    await this.ctx.support.ready;

    const { livelyEndpoints, token } = this.options;

    if (!(await this.callSupported())) {
      this.throwError(new ValidationError("h264 is not supported", {}));
    }

    if (livelyEndpoints == null) {
      this.throwError(new ValidationError("livelyEndpoints is not provided", {}));
    }

    const call = new Call(extendContext(this.ctx, Call), true, {
      token,
      livelyEndpoints,
      sfu: { ...(callOptions.sfu ?? {}) },
      stats: callOptions.stats ?? this.options.stats,
      playerOptions: this.options.playerOptions,
      clientReferrer: callOptions.clientReferrer,
      streamKey: callOptions.streamKey,
    });

    this[addProxy](call);

    return call.ready();
  }

  /**
   * Joins to an existing connection
   * Returns Call which is ready to use (e.g. create broadcasts)
   * and already have peers
   */
  async joinCall(callId: string, options: JoinCallOptions = {}): Promise<CallAPI> {
    return this.internalJoinCall(callId, options, this.ctx);
  }

  async internalJoinCall(
    callId: string,
    options: JoinCallOptions = {},
    ctx: VcContext = this.ctx,
    sfuJoinParams: Required<Join> | null = null,
  ): Promise<CallAPI> {
    const { playerOptions, token, ...sfu } = options;
    const { livelyEndpoints } = this.options;
    const { onCallJoinEndpoint, onSFUConnection } = options;

    if (!(await this.callSupported())) {
      this.throwError(new NotSupportedError("h264 is not supported", { critical: true }));
    }

    if ((livelyEndpoints?.length ?? 0) === 0 && options.joinUrl == null) {
      this.throwError(new NotSupportedError("livelyEndpoints or joinUrl are required", {}));
    }

    const call = new Call(
      extendContext(ctx, Call),
      false,
      {
        token: token ?? this.options.token,
        livelyEndpoints,
        callId,
        sfu,
        playerOptions: playerOptions ?? this.options.playerOptions,
        stats: this.options.stats,
      },
      sfuJoinParams,
    );

    call.on("callJoinEndpoint", () => {
      if (onCallJoinEndpoint) {
        onCallJoinEndpoint();
      }
    });
    call.on("sfuConnection", () => {
      if (onSFUConnection) {
        onSFUConnection();
      }
    });

    this[addProxy](call);

    return call.ready();
  }

  requestPlayer(
    source: Manifest | SourceProvider,
    options: Partial<RequestPlayerOptions> = defaultPlayerOptions,
  ): PlayerAPI {
    const playerOptions: RequestPlayerOptions = {
      autoPlay: options.autoPlay != null ? options.autoPlay : defaultPlayerOptions.autoPlay,
      displayPoster: options.displayPoster != null ? options.displayPoster : defaultPlayerOptions.displayPoster,
      players: options.players != null ? options.players : defaultPlayerOptions.players,
      refetch: options.refetch != null ? options.refetch : defaultPlayerOptions.refetch,
      muted: options.muted != null ? options.muted : defaultPlayerOptions.muted,
      volume: options.volume != null ? options.volume : defaultPlayerOptions.volume,
      driverFailoverSeconds:
        options.driverFailoverSeconds != null
          ? options.driverFailoverSeconds
          : defaultPlayerOptions.driverFailoverSeconds,
    };

    // For some reason logger is being attached to the options which is breaking this log.
    const loggerOptions = {
      muted: options.muted,
      autoPlay: options.autoPlay,
      refetch: options.refetch,
      volume: options.volume,
    };

    this.ctx.logger.debug("requestPlayer()", {
      manifest: source,
      mergedPlayerOptions: playerOptions,
      options: loggerOptions,
    });

    let inner: Error | null = null;
    let player: PlayerAPI | null = null;
    try {
      player = this.internalRequestPlayer(source, playerOptions);
    } catch (ex) {
      if (ex instanceof Error) {
        inner = ex;
      }
    }

    if (player == null) {
      this.throwError(new NotSupportedError("No supported players", { inner }));
    }

    this[addProxy](player);

    return player;
  }

  private internalRequestPlayer(
    source: Manifest | SourceProvider,
    playerOptions: RequestPlayerOptions,
  ): PlayerAPI | null {
    if (isManifest(source)) {
      const manifestPlayerCtx = extendContext(this.ctx, ManifestPlayer);
      const mediaLoader = new MediaLoader(extendContext(manifestPlayerCtx, MediaLoader), source, {
        pollingInterval: 5000,
        notFoundPollingInterval: 2000,
        unauthorizedPollingInterval: 10000,
        unauthorizedRecoveryDuration: 10000,
      });

      // Remove mediasoup player as an option
      const filteredPlayers: ManifestPlayerSpecList = playerOptions.players.filter(
        (item) => item === "mediasoup" || (typeof item !== "string" && item.id !== "mediasoup"),
      ) as ManifestPlayerSpecList;

      const defaultPlayerSpecOptions = {
        autoPlay: playerOptions.autoPlay,
        muted: playerOptions.muted,
        volume: playerOptions.volume,
        blurred: playerOptions.blurred,
      };

      // Set player options
      filteredPlayers.forEach((item: PlayerAnySpec | keyof PlayerOptionsMap) => {
        if (typeof item !== "string") {
          item.options = { ...defaultPlayerSpecOptions, ...item.options };
        }
      });

      return new ManifestPlayer(manifestPlayerCtx, mediaLoader, {
        ...playerOptions,
        players: filteredPlayers,
      });
    }

    if (source instanceof MediasoupSource) {
      return new MediasoupPlayer(extendContext(this.ctx, MediasoupPlayer), source, {});
    }

    if (source instanceof MediaStreamController) {
      return new MediasoupPlayer(extendContext(this.ctx, MediasoupPlayer), source, {});
    }

    throw new NotSupportedError("source not supported", {});
  }
}
