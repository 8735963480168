import { types } from "@livelyvideo/video-client-core";
import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { EncoderUiState } from "../../../../../store";
import { LivelyEncoderUiContext } from "../../../../context";
import { useStyles } from "../../../../styling";
import { mergeStylesObjects } from "../../../../styling/utils";
import Select, { SelectProps } from "../../../../ui-lib/Inputs/Select";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../ErrorBoundary";

enum AspectRatios {
  "3:2" = 3 / 2,
  "4:3" = 4 / 3,
  "16:9" = 16 / 9,
  "8:5" = 8 / 5,
}

const DELTA = 0.001;

function aspectRationToString(val: types.MediaStreamControllerAPI["aspectRatio"]): string | null {
  if (val == null) {
    return null;
  }

  const ar = Array.isArray(val) ? val[0] : val;
  for (const key of Object.keys(AspectRatios) as Array<keyof typeof AspectRatios>) {
    const diff = Math.abs(AspectRatios[key] - ar);
    if (diff < DELTA) {
      return key;
    }
  }
  return null;
}

/**
 * @todo: Possibly refactor Select Components
 *
 */
const ModularAspectRatioSelect = observer(({ classes = {}, ...props }: Partial<SelectProps>) => {
  /**
   * Component Name (for error msg)
   */
  const componentName = "<AspectRatioSelect/>";

  const availableAspectRatios: Array<string> = Object.keys(AspectRatios) as Array<keyof typeof AspectRatios>;

  const mergedClasses = useStyles({ source: classes, target: {} }, "select");
  const mergedStyles = mergeStylesObjects(classes, {});

  /**
   * Access LivelyContext & destructure API state
   */
  const ctx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);

  /**
   * Throw error (and trigger ErrorBoundary) if store is undefined.
   * */
  useUndefinedStoreError(ctx?.mediaStreamController != null, componentName);

  /**
   * On input change handler to (1) update API and (2) update component
   * <select> value with user selected audio device.
   */
  const selectAspectRatio = (ev: React.ChangeEvent<HTMLSelectElement>): void => {
    const ar = AspectRatios[ev.target.value as keyof typeof AspectRatios];
    if (ar == null) {
      throw new Error(`${componentName}: Invalid aspect ratio: ${ev.target.value}`);
    }

    ctx.mediaStreamController.aspectRatio = ar;
  };

  /**
   * Wrap onChange function in global Error handler (to trigger ErrorBoundary).
   * */
  const handleInputChange = useUIEventError(selectAspectRatio, componentName);

  return (
    <Select
      classes={mergedStyles}
      label={`Aspect Ratio: ${aspectRationToString(ctx.mediaStreamController.aspectRatio)}`}
      onChange={handleInputChange}
      value={aspectRationToString(ctx.mediaStreamController.aspectRatio) ?? undefined}
    >
      <option disabled value="">
        Select an aspect ratio
      </option>
      {availableAspectRatios.map((ratio) => (
        <option key={ratio} value={ratio} className={mergedClasses.option}>
          {ratio}
        </option>
      ))}
    </Select>
  );
});

// eslint-disable-next-line react/function-component-definition
const AspectRatioSelectWithErrorBoundary: FC<Partial<SelectProps>> = ({ classes = {}, ...props }) => {
  const mergedClasses = useStyles({ source: classes, target: {} }, "select");
  const mergedStyles = mergeStylesObjects(classes, {});

  return (
    <ErrorBoundary
      render={() => (
        <Select {...props} value="" classes={mergedStyles} disabled>
          <option value="" className={mergedClasses?.options}>
            {props.fallbackText ?? "Aspect Ratio Unavailable"}
          </option>
        </Select>
      )}
    >
      <ModularAspectRatioSelect classes={classes} {...props} />
    </ErrorBoundary>
  );
};

export default AspectRatioSelectWithErrorBoundary;
