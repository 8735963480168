import { LoggerCore } from "@livelyvideo/log-client";
import { Json, Serializable } from "@livelyvideo/log-node";
import { IObservableArray } from "mobx";
import { device, Feature as AdapterFeature } from "../../api/adapter";
import { DebuggingFeature } from "../../api/adapter/features/debugging";
import { LocalStorageFeature } from "../../api/adapter/features/local-storage";
import { MediaDeviceFeature } from "../../api/adapter/features/media-device";
import { MediaSourceFeature } from "../../api/adapter/features/media-source";
import { DeviceAPI } from "../../api/device";
import type { Encoding, FormatMp4Ws, ManifestFormats, ManifestJson } from "../../api/manifest";
import type { PlayerEvents } from "../../api/player";
import { BitrateSwitchingEvents, BitrateSwitchingFeature, Quality, TranscodeScoreLevel } from "../../api/player/features/bitrate-switching";
import { Feature as PlayerFeature } from "../../api/player/features/feature";
import { VideoElement } from "../../api/typings/video-element";
import { PACKAGE_NAME } from "../../utils/common";
import {
  DriverNotSupportedError,
  InternalError,
  MediaError,
  MeowDriverError,
  Mp4BufferError,
  PlayingIssueError,
  wrapNativeError,
} from "../errors";
import MediaLoader from "../media-loader";
import { supportsMp4 } from "../utils/browser-support";
import { onceCanceled } from "../utils/context/context";
import { VcContext } from "../utils/context/vc-context";
import { InstanceCollector } from "../utils/debug/instance-collector";
import { ObservableEventEmitter } from "../utils/events/event-emitter";
import { extendContext } from "../utils/logger";
import { uuidv4 } from "../utils/uuid";
import { CorePlayer } from "./core";
import { ERRORS, fetchManifestQualities } from "./helper";

const STORAGE_VALUES = {
  muted: "lv_muted",
  volume: "lv_volume",
  lockedKbps: "lv_locked_kbps", // [null]
  estimatedKbps: "lv_estimated_kbps", // [1800]
  autoLastKbps: "lv_auto_last_kbps", // [1800]
  hideSuggestion: "lv_hide_suggestion",
  origin: "lv_origin",
  profile: "lv_origin",
};

export interface Mp4WsPlayerEvents extends PlayerEvents, BitrateSwitchingEvents {
  /**
   * @description Is emitted when the bitrate is switched.
   * @example player.on("bitrate-switch", () => { // handle bitrate switch})
   */
  "bitrate-switch": void;
  /**
   * @description Is emitted when the player has no audio.
   * @example player.on("no-audio", () => { // handle no audio})
   */
  "no-audio": void;
  currentQuality: Quality;
}

export type Modes = "A" | "B" | "N";

const ACCEPTABLE_MODES: Modes[] = ["A", "B", "N"];
const DELTA = 0.0001;

let sessionKeyCount = 0;

export type Mp4WsPlayerOptions = {
  autoPlay?: boolean;
  muted?: boolean;
  maxShifts?: number;
  requiredBuffer?: number;
  requiredBufferBeforeRemove?: number;
  backFill?: number;
  maxGap?: number;
  retry?: number;
  mode?: Modes;
  download?: boolean;
  segmentDuration?: number;
  initFailTimeout?: number;
  playbackFailTimeout?: number;
  skipWatchInterval?: number;
  skipForwardThreshold?: number;
  bitrate?: number;
  estimatedKbps?: number;
  origin?: boolean;
};

export class Mp4WsPlayer
  extends CorePlayer<Mp4WsPlayerOptions, ManifestJson, Mp4WsPlayerEvents>
  implements BitrateSwitchingFeature
{
  static readonly displayName = "Mp4WsPlayer";

  public data: FormatMp4Ws = { encodings: [], audioCodec: "", videoCodec: "" };

  private readonly sessionKey: string = uuidv4();

  private readonly origin: { location: string } | null = null;

  player: Player | null = null;

  static async isSupported(logger?: LoggerCore): Promise<boolean> {
    return supportsMp4(logger);
  }

  async isSupported(): Promise<boolean> {
    return Mp4WsPlayer.isSupported();
  }

  static get format(): keyof ManifestFormats {
    return "mp4-ws";
  }

  get format(): keyof ManifestFormats {
    return Mp4WsPlayer.format;
  }

  protected handleSource(manifest: ManifestJson | null): void {
    this.ctx.logger.debug("mp4-ws: handleSource()");

    const format = manifest?.formats["mp4-ws"];
    if (format == null) {
      this.emitError(new DriverNotSupportedError("manifest doesn't contains 'mp4-hls' format", { manifest }));
      return;
    }

    if (this.data.encodings.length === 0) {
      this.data = format;
    }

    const [encodings, needToUpdate] = this.handleEncodings(format);

    const qualities = this.availableQualities as IObservableArray;
    
    qualities.replace(fetchManifestQualities(encodings, format.origin?.location ?? null));

    if (needToUpdate) {
      this.setPreferredLevel(TranscodeScoreLevel.Highest);
    }
  }

  protected get implementedFeatures(): PlayerFeature[] {
    return [PlayerFeature.BITRATE_SWITCHING, PlayerFeature.MUTED_AUTOPLAY];
  }

  private readonly mse: Required<Mp4WsPlayerOptions>;

  private readonly currentManifest: string | null = null;

  private readonly seekingOverHole = false;

  private activeEncoding: Encoding | null = null;

  private estimatedKbps: number | null = null;

  private playbackLock = false;

  private nudgeInterval: number | undefined = undefined;

  private nudgeTime: number | null = null;

  private readonly lastBufferEnd: number | null = null;

  private readonly fragFetchTime = 0;

  private readonly device: DeviceAPI & DebuggingFeature & MediaDeviceFeature;

  constructor(ctx: VcContext, provider: MediaLoader, options: Mp4WsPlayerOptions) {
    super(ctx, provider, options);

    if (!device.isImplements(AdapterFeature.DEBUGGING) || !device.isImplements(AdapterFeature.MEDIA_DEVICE)) {
      throw new Error("Device is not supported");
    }

    onceCanceled(this.ctx).then((reason) => this.dispose(`Mp4Ws Player Context Cancelled: ${reason}`));
    this.addInnerDisposer(this.stop);

    this.device = device;

    const mse = this.options;

    if (mse.backFill == null || Number.isNaN(mse.backFill) || mse.backFill < 0 || mse.backFill > 10) {
      mse.backFill = 2;
    }

    if (mse.maxGap == null || Number.isNaN(mse.maxGap) || mse.maxGap < 0 || mse.maxGap > 10000) {
      mse.maxGap = 500;
    }

    if (mse.retry == null || Number.isNaN(mse.retry) || mse.retry < 0 || mse.retry > 30) {
      mse.retry = 20;
    }

    if (
      mse.requiredBuffer == null ||
      Number.isNaN(mse.requiredBuffer) ||
      mse.requiredBuffer < 0 ||
      mse.requiredBuffer > 10
    ) {
      // seconds of buffer required for play to start
      mse.requiredBuffer = 1;
    }

    if (this.ctx.support.browserInfo.name === "edge" || this.ctx.support.browserInfo.name === "ie") {
      mse.backFill = Math.max(mse.backFill, 4);
      mse.requiredBuffer = Math.max(mse.requiredBuffer, 3);
    }

    if (mse.initFailTimeout == null || Number.isNaN(mse.initFailTimeout) || mse.initFailTimeout < 0) {
      mse.initFailTimeout = 30000;
    }

    if (mse.playbackFailTimeout == null || Number.isNaN(mse.playbackFailTimeout) || mse.playbackFailTimeout < 0) {
      mse.playbackFailTimeout = 60000;
    }

    if (mse.mode == null || !ACCEPTABLE_MODES.includes(mse.mode)) {
      mse.mode = "B";
    }
    // seconds of buffer required before removing buffer
    mse.requiredBufferBeforeRemove = mse.requiredBuffer;
    // ms interval to check current time against buffer and skip forward
    mse.skipWatchInterval = Math.floor(mse.requiredBuffer * 400);
    // seconds behind end of buffer before skipping forward to end of buffer - this.options.mse.requiredBuffer
    mse.skipForwardThreshold = 25;
    // download each playback attempt

    // adaptive, max shifts
    if (Number.isNaN(mse.maxShifts ?? NaN)) {
      mse.maxShifts = Infinity;
    }

    this.mse = this.options as Required<Mp4WsPlayerOptions>;

    this.on("currentQuality", (qty) => {
      this.setQuality(qty);
      this.player?.setupTransport();
    });

    this.on("hostElementAttached", this.init);
  }

  init(): void {
    const el = this.hostEl;
    if (el == null) {
      throw new InternalError("hostEl is null", {});
    }

    el.addEventListener("timeupdate", () => {
      const { buffered } = el;
      if (buffered.length === 0) {
        return;
      }

      if (buffered.end(buffered.length - 1) - el.currentTime > this.mse.requiredBuffer) {
        if (this.ctx.support.browserInfo.name === "safari") {
          el.playbackRate = 1.01;
        } else {
          el.playbackRate = 1.05;
        }
      } else {
        el.playbackRate = 1;
      }
    });

    el.addEventListener("error", (ev) => {
      // what's code?
      const code = ev.target?.code;
      switch (code) {
        case 1:
          this.emitError(new MediaError("MEDIA_ERR_ABORTED", { critical: true, eventTargetCode: code }));
          break;
        case 2:
          this.emitError(new MediaError("MEDIA_ERR_NETWORK", { critical: true, eventTargetCode: code }));
          break;
        case 3:
          this.emitError(new DriverNotSupportedError("MEDIA_ERR_DECODE", { critical: true, eventTargetCode: code }));
          break;
        case 4:
          this.emitError(
            new DriverNotSupportedError("MEDIA_ERR_SRC_NOT_SUPPORTED", { critical: true, eventTargetCode: code }),
          );
          break;
        default:
          if (el.src === "") {
            this.ctx.logger.error("meow unknown element error", {
              manifest: this.manifest,
            });
          }
          if (!this.localVideoPaused && !this.isDisposed) {
            this.restart(false);
          }
      }
    });

    // when video pauses, check if offline and cleanup
    el.addEventListener("pause", () => {
      if ((this.provider as MediaLoader).currentState === "offline") {
        this.stop();
      }
    });
  }

  getLevelForBitrate(bitrate: number): number | null {
    const { encodings } = this.data;

    if (encodings.length === 0) {
      return null;
    }

    let i;
    for (i = 0; i < encodings.length; i++) {
      const encoding = encodings[i];
      if (encoding == null || encoding.videoWidth == null) {
        // eslint-disable-next-line no-continue
        continue;
      }
      if ((encodings[i].videoKbps ?? 0) + (encodings[i].audioKbps ?? 0) > bitrate) {
        break;
      }
    }
    i = Math.max(i - 1, 0);
    return i;
  }

  get currentLevel(): number | null {
    if (this.options.bitrate != null) {
      return this.getLevelForBitrate(this.options.bitrate);
    }

    if (this.activeEncoding == null) {
      return this.getLevelForBitrate(this.options.estimatedKbps ?? 0);
    }

    return this.getLevelForBitrate((this.activeEncoding?.videoKbps ?? 0) + (this.activeEncoding?.audioKbps ?? 0));
  }

  /**
   * @param {number} [bitrate_] bitrate, defaults to options.bitrate || options.estimatedKbps
   * @return {object} encoding object
   */
  pickEncoding(bitrate_?: number): Encoding | null {
    const bitrate = bitrate_ ?? this.options.bitrate;

    let { encodings } = this.data;

    if (encodings.length === 0) {
      return null;
    }

    if (bitrate == null) {
      return encodings[0];
    }

    encodings = encodings.sort((a, b) =>
      (a.audioKbps ?? 0) + (a.videoKbps ?? 0) < (b.audioKbps ?? 0) + (b.videoKbps ?? 0) ? 1 : -1,
    );
    const bestEncoding = encodings.find((e) => (e.audioKbps ?? 0) + (e.videoKbps ?? 0) <= bitrate);

    if (bestEncoding != null) {
      return bestEncoding;
    }
    return encodings[this.currentLevel ?? 0];
  }

  setQuality(qty: Quality): void {
    const encoding = this.data.encodings.find((e) => (e.audioKbps ?? 0) + (e.videoKbps ?? 0) === qty.layer.bitrate);
    if (encoding == null) {
      this.ctx.logger.warn("encoding not found", { encoding });
      return;
    }

    this.setEncoding(encoding);
  }

  /**
   * calls to the transport to set the encoding
   * @param {object} encoding
   * @return {void}
   */
  setEncoding(encoding: Encoding): void {
    if (
      encoding == null ||
      (this.activeEncoding?.videoKbps === encoding.videoKbps && this.activeEncoding?.audioKbps === encoding.audioKbps)
    ) {
      return;
    }

    this.activeEncoding = encoding;
    this.estimatedKbps = (encoding.videoKbps ?? 0) + (encoding.audioKbps ?? 0);

    // safari does not handle
    if (this.ctx.support.browserInfo.name === "safari") {
      this.stop();
      try {
        this.play().then(() => {
          this.emit("bitrate-switch");
        });
      } catch (err) {
        const inner = err instanceof Error ? err : null;
        this.emitError(
          new PlayingIssueError("play failed after setEncoding()", {
            inner,
            player: this,
          }),
        );
      }

      return;
    }

    const x = encoding.location.split("/");

    this.player?.switchBitrate(x[x.length - 1]);
    this.emit("bitrate-switch");
  }

  /**
   * @param {number} bitrate
   * @return {void}
   */
  switchBitrate(bitrate: number): void {
    this.ctx.logger.debug("meow switch bitrate", { bitrate });
    this.options.origin = false;
    this.options.bitrate = bitrate;

    this.emit("bitrate-switch");
    this.stop();
    try {
      this.play().then(() => {
        this.emit("bitrate-switch");
      });
    } catch (err) {
      const inner = err instanceof Error ? err : null;
      this.emitError(
        new PlayingIssueError("play failed after switchBitrate()", {
          inner,
          player: this,
        }),
      );
    }

    // smooth shift.. maybe unnecessary
    // if (!bitrate) {
    // 	this.options.bitrate = null;
    // 	this.stop(() => {
    // 		this.play();
    // 	});
    // 	return;
    // }
    // this.setEncoding(this.pickEncoding(bitrate));
  }

  addSessionKey(l: string): string {
    if (l.includes("sid")) {
      return l;
    }

    sessionKeyCount++;
    const delimiter = !l.includes("?") ? "?" : "&";
    const newLStr = `${l}${delimiter}sid=${this.sessionKey}&c=${sessionKeyCount}`;
    return newLStr;
  }

  get originLocation(): string | null {
    const o = this.origin;

    if (o == null) {
      return null;
    }

    return this.addSessionKey(o.location);
  }

  /**
   * returns null if there is no ws endpoint available, otherwise returns the active ws url
   * @return {string} manifest url
   */
  get manifest(): string | null {
    let l;

    if (this.options.origin) {
      l = this.originLocation;
      if (l == null) {
        this.options.origin = false;
        return this.manifest;
      }
    } else {
      const d = this.data;
      if (d.encodings.length === 0) {
        const o = this.originLocation;
        if (o != null) {
          this.options.origin = true;
        }
        return o;
      }

      const e = this.pickEncoding(this.options.bitrate ?? this.estimatedKbps ?? this.options.estimatedKbps);

      if (e == null) {
        return null;
      }

      l = e.location;
    }

    if (l == null) {
      return null;
    }

    return this.addSessionKey(l);
  }

  pause(): void {
    this.stop();
  }

  stop(): void {
    this.playbackLock = false;
    clearInterval(this.nudgeInterval);
    if (this.player == null) {
      return;
    }

    this.player.once("disposed", () => {
      this.player = null;
    });
  }

  get location(): string | null {
    if (this.player == null) {
      return null;
    }

    return this.player.manifest;
  }

  set location(value: string | null) {
    // do nothing
  }

  // call play on the parent class that interacts with the video element
  async corePlay(): Promise<boolean> {
    return super.play(true);
  }

  async play(): Promise<boolean> {
    const el = this.hostEl;
    if (el == null) {
      throw new Error(ERRORS.ELEMENT_REQUIRED);
    }

    if (this.playbackLock) {
      return !el.paused;
    }

    this.playbackLock = true;
    if (this.options.autoPlay && this.localVideoPaused) {
      setTimeout(() => {
        this.localVideoPaused = false;
      });
    }

    if (this.player != null) {
      return this.player.play();
    }

    this.ctx.logger.debug("meow driver play received");

    this.provider.once("source", () => {
      clearInterval(this.nudgeInterval);
      this.nudgeTime = null;
      let nudges = 0;
      this.nudgeInterval = device.setInterval(() => {
        if (this.localVideoPaused || el.currentTime === 0) {
          return;
        }

        if (
          this.nudgeTime != null &&
          el.currentTime === this.nudgeTime &&
          (this.lastBufferEnd == null || el.currentTime < this.lastBufferEnd)
        ) {
          this.ctx.logger.debug("meow playback halted, nudging");
          nudges++;
          el.currentTime += 0.1;

          const promise = this.corePlay();
          if (promise.then != null) {
            promise.catch(() => {
              // its ok, it didn't work out this time
            });
          }
        } else {
          nudges = 0;
          this.nudgeTime = el.currentTime;
        }

        if (nudges > 29) {
          clearInterval(this.nudgeInterval);
          this.ctx.logger.debug("meow too many halt nudges, restart");
          this.restart(false);
        }
      }, 500);

      this.ctx.logger.debug("meow manifest verified, player created");

      this.player = new Player(this.ctx, this, this.options, this.provider as MediaLoader, this.hostEl as VideoElement);

      this.counters.upshift = 0;
      this.counters.downshift = 0;
      this.counters.currentErrorCount = 0;
      this.counters.fragCounts = 0;
      this.counters.fragSize = 0;
      this.counters.fragFetchTime = 0;
      this.counters.fragMaxTime = 0;
      this.counters.fragMinTime = 0;

      this.player.on("stop", () => {
        this.stop();
      });

      this.player.on("no-audio", () => {
        this.emit("no-audio");
      });

      this.player.on("upshift", () => {
        this.counters.upshift++;
      });

      this.player.on("downshift", () => {
        this.counters.downshift++;
      });

      this.player.on("fragment", (data) => {
        this.counters.fragCounts++;
        this.counters.fragSize += data.size;
        this.counters.fragFetchTime += data.time;
        this.counters.fragMaxTime = Math.max(this.counters.fragMaxTime, data.time);
        this.counters.fragMinTime = Math.min(this.counters.fragMinTime ?? data.time, data.time);
      });

      this.player.on("error", (data) => {
        this.counters.currentErrorCount++;

        if (data.notSupported) {
          this.emitError(new DriverNotSupportedError("player error", { data }));
          return;
        }

        if (data.fatal) {
          this.ctx.logger.debug("meow player fatal error, restarting");
          this.restart(false);
        }
      });

      if (this.device.hidden) {
        this.device.addEventListener("visibilitychange", () => {
          try {
            this.player?.play();
          } catch (err) {
            const inner = err instanceof Error ? err : null;
            this.emitError(
              new PlayingIssueError("play failed after visibilitychange event triggered", {
                inner,
                player: this,
              }),
            );
          }
        });
      } else {
        try {
          this.player.play();
        } catch (err) {
          const inner = err instanceof Error ? err : null;
          this.emitError(
            new PlayingIssueError("play failed", {
              inner,
              player: this,
            }),
          );
        }
      }
    });

    return !this.localVideoPaused;
  }
}

const PLAYBACK_MAX_LATENCY = 25;

interface SourceBufferMeta {
  mimetype: string;
}

// we don't have types for Buffer and ArrayBuffer types for now
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sbMeta = new WeakMap<any, SourceBufferMeta>();

export interface PlayerFragment {
  size: number;
  time: number;
  duration: number;
}

interface IntPlayerEvents {
  fragment: PlayerFragment;
  upshift: void;
  downshift: void;
  mute: void;
  "no-audio": void;
  error: {
    message?: string;
    err?: Error;
    notSupported?: boolean;
    fatal?: boolean;
    [key: string]: unknown;
  };
  stop: void;
}

interface OpAppend {
  type: "append";
  blob: Blob;
  time: number;
}

interface OpRemove {
  type: "remove";
  sb?: any;
  start: number;
  end: number;
}

type Op = OpAppend | OpRemove;

interface BufferDuration {
  sourceBufferIndex: number;
  index: number;
  start: number;
  end: number;
}

class Player extends ObservableEventEmitter<IntPlayerEvents> {
  static readonly displayName = "Mp4WsPlayer";

  private readonly livelyPlayer: Mp4WsPlayer;

  private readonly mediaLoader: MediaLoader;

  private readonly options: Mp4WsPlayerOptions;

  private readonly el: VideoElement | null;

  private buffering = false;

  private queue: Op[] = [];

  private readonly sourceBuffers: Array<any | null> = [];

  private receivedSegments = 0;

  private readonly abrController: AbrController | null = null;

  private readonly encoding: Encoding | null = null;

  private transport: Transport | null = null;

  private version: number | null = null;

  private ms: any | null = null;

  private mediaSourceTime: number | null = null;

  manifest: string | null = null;

  private lastMessageReceived: number | null = null;

  private logInterval: number | undefined = undefined;

  private gcInterval: number | undefined = undefined;

  private destroyed = false;

  private readonly device: DeviceAPI & DebuggingFeature & MediaSourceFeature & LocalStorageFeature;

  private readonly ctx: VcContext;

  constructor(
    ctx: VcContext,
    player: Mp4WsPlayer,
    options: Mp4WsPlayerOptions,
    mediaLoader: MediaLoader,
    el: VideoElement,
  ) {
    super();

    this.ctx = ctx;

    onceCanceled(ctx).then((reason) => this.dispose(`Mp4ws Context Cancelled: ${reason}`));
    this.addInnerDisposer(this.destroy);

    if (
      !device.isImplements(AdapterFeature.DEBUGGING) ||
      !device.isImplements(AdapterFeature.MEDIA_SOURCE) ||
      !device.isImplements(AdapterFeature.LOCAL_STORAGE)
    ) {
      throw new Error("Device is not supported");
    }

    this.device = device;

    this.livelyPlayer = player;
    this.mediaLoader = mediaLoader;
    this.options = options;
    this.el = el;

    // set up the ABR Controller
    if (this.options.bitrate == null) {
      const profile: string = this.device.localStorage.getItem(STORAGE_VALUES.profile) ?? "normal";

      const abrController = new AbrController(
        extendContext(ctx, AbrController),
        this,
        this.livelyPlayer,
        profile,
        false,
        this.options.maxShifts ?? Infinity,
        this.options,
      );
      this.abrController = abrController;
      abrController.on("profile", (data) => {
        // bug: this.livelyPlayer.store doesn't use TTL
        // const ttl = detect.any() ? 6 * 1000 * 60 * 60 : 1000 * 60 * 60 * 24 * 60;

        this.device.localStorage.setItem(STORAGE_VALUES.estimatedKbps, data.estimatedKbps.toString() /* , ttl */);
        this.device.localStorage.setItem(STORAGE_VALUES.profile, data.profile.key /* , ttl */);
      });

      this.abrController.on("upshift", () => {
        this.emit("upshift");
      });

      this.abrController.on("downshift", () => {
        this.emit("downshift");
      });
    }

    let encoding;
    if (this.options.origin) {
      encoding = this.livelyPlayer.data.origin as Encoding;
    } else {
      encoding = this.livelyPlayer.pickEncoding();
    }

    if (encoding == null && this.livelyPlayer.data.encodings.length > 0) {
      [encoding] = this.livelyPlayer.data.encodings;
    }
    if (encoding != null) {
      this.encoding = encoding;
    }

    this.setupMSE();
  }

  appendBuffer(): void {
    if (this.buffering) {
      return;
    }

    const wait = (): void => {
      this.buffering = true;
      device.setTimeout(() => {
        this.buffering = false;
        this.appendBuffer();
      }, 20);
    };

    if (this.readyState !== "open") {
      wait();
      return;
    }

    const op = this.queue.shift();

    if (op == null) {
      wait();
      return;
    }

    this.buffering = true;
    if (op.type === "remove") {
      if (op.sb == null) {
        this.buffering = false;
        this.appendBuffer();
        return;
      }
      this.ctx.logger.debug("remove buffer", {
        buffer: `${op.start}-${op.end} buffered until ${op.sb.buffered.end(0)}`,
      });
      op.sb.remove(op.start, op.end);
    } else {
      op.blob.arrayBuffer().then((ab) => {
        if (this.transport == null) {
          throw new Error("Transport is not defined");
        }
        const currentSegmentInfo = this.transport.getSegmentInfo(ab);

        // backwards compat
        if (this.version === 1) {
          currentSegmentInfo.contentTag = 0;
        }

        const ctsb = this.sourceBuffers[currentSegmentInfo.contentTag];

        this.emit("fragment", {
          size: op.blob.size,
          time: op.time,
          duration: 300,
        });

        if (this.readyState !== "open") {
          this.ctx.logger.debug("meow mse not ready", {
            readyState: this.readyState,
          });
          this.queue.unshift(op);
          wait();
          return;
        }

        if (ctsb == null) {
          this.queue.unshift(op);
          wait();
          return;
        }

        try {
          ctsb.appendBuffer(ab);
        } catch (err) {
          this.emitError(
            new Mp4BufferError("failed to append source buffer in appendBuffer()", { inner: wrapNativeError(err) }),
          );
        }
      });
    }
  }

  get readyState(): any {
    if (this.ms == null) {
      return null;
    }

    const { encoding } = this;
    if (encoding == null || encoding.channels?.length !== this.sourceBuffers.length) {
      return null;
    }

    return this.ms.readyState;
  }

  checkSourceBuffers(): boolean {
    for (const sourceBuffer of this.sourceBuffers) {
      if ((sourceBuffer?.buffered.length ?? 0) === 0) {
        return false;
      }
    }

    return (this.el?.buffered.length ?? 0) !== 0;
  }

  newSourceBuffer(mimetype?: string): void {
    if (mimetype == null || !this.device.MediaSource.isTypeSupported(mimetype)) {
      this.sourceBuffers.push(null);
      return;
    }

    if (this.ms == null) {
      return;
    }

    const sb = this.ms.addSourceBuffer(mimetype);
    this.ctx.logger.debug("meow adding sourcebuffer", { mimetype });

    sbMeta.set(sb, { mimetype });
    sb.addEventListener("error", (err: Error) => {
      this.emitError(new Mp4BufferError("failed to append source buffer in newSourceBffer()", { inner: err }));
      this.buffering = false;
      this.appendBuffer();
    });

    sb.addEventListener("updateend", () => {
      this.buffering = false;
      this.appendBuffer();
      const { el } = this;
      if (el == null) {
        return;
      }

      if (el.buffered.length > 0) {
        const playbackDifference = el.buffered.end(el.buffered.length - 1) - el.currentTime;
        if (el.currentTime === 0) {
          const bufferDifference = el.buffered.end(el.buffered.length - 1) - el.buffered.start(0);
          if (bufferDifference > (this.options.requiredBuffer ?? 0)) {
            const startTime = Math.max(
              el.buffered.end(el.buffered.length - 1) - (this.options.requiredBuffer ?? 0),
              el.buffered.start(0),
            );
            el.currentTime = startTime;
            this.ctx.logger.debug("meow attempting to start", {
              startTime,
            });
            this.play();
          }
        } else if (playbackDifference > PLAYBACK_MAX_LATENCY) {
          const ct = el.currentTime;
          const nt = Math.max(
            el.buffered.end(el.buffered.length - 1) - (this.options.requiredBuffer ?? 0),
            el.buffered.start(0),
          );

          el.currentTime = nt;
          this.ctx.logger.warn("meow buffer max reached, moving to playback head", {
            max: PLAYBACK_MAX_LATENCY,
            ct,
            nt,
          });
        }
      }

      this.receivedSegments++;

      if (this.receivedSegments > 60 && !this.checkSourceBuffers()) {
        this.ctx.logger.error("no buffer after 60 segments");
        this.emit("error", {
          message: "media-error",
          fatal: true,
          notSupported: true,
        });
        return;
      }

      /** logs */
      const bufferDurations: BufferDuration[] = [];
      this.sourceBuffers.forEach((bsb, k) => {
        if (bsb == null || this.readyState !== "open") {
          return;
        }

        for (let i = 0; i < bsb.buffered.length; i++) {
          bufferDurations.push({
            sourceBufferIndex: k,
            index: i,
            start: bsb.buffered.start(i),
            end: bsb.buffered.end(i),
          });
        }
      });

      if (bufferDurations.length > 0) {
        // this.ctx.logger.debug("meow buffer appended", {
        //   currentTime: this.el?.currentTime,
        //   bufferDurations,
        // });
      }
      /** end logs */
    });
    this.sourceBuffers.push(sb);
  }

  setupMSE(): void {
    this.ms = new this.device.MediaSource();

    this.ms.addEventListener("error", () => {
      this.ctx.logger.error("meow mediasource dom error");
      // if it errors, its because of a fatal decoding error
      this.emit("error", {
        message: "media-error",
        fatal: true,
      });
    });

    this.ms.addEventListener("abort", () => {
      this.ctx.logger.error("meow mediasource dom abort");
      this.emit("error", {
        message: "media-error",
        fatal: true,
      });
    });

    this.ms.addEventListener("sourceended", () => {
      this.ctx.logger.debug("meow mediasource source ended");
    });

    this.ms.addEventListener("sourceclose", () => {
      this.ctx.logger.debug("meow mediasource source closed");
    });

    this.ms.addEventListener("sourceopen", this.onSourceOpen);

    this.ctx.logger.debug("meow attempting to create media source");
    this.mediaSourceTime = this.device.performance.now();
    // set the source so that the ready() check in the shared driver logic passes.
    this.livelyPlayer.source = URL.createObjectURL(this.ms);

    if (this.el != null) {
      this.el.src = this.livelyPlayer.source;
    }
  }

  onSourceOpen(): void {
    this.ms.removeEventListener("sourceopen", this.onSourceOpen);

    this.ctx.logger.debug("meow media source opened");
    const { encoding } = this;

    if (encoding == null) {
      this.emit("error", {
        message: "media-error",
        fatal: true,
      });
      return;
    }

    try {
      if (encoding?.channels != null) {
        if (encoding.channels.length === 0) {
          this.emit("error", {
            message: "media-error",
            fatal: true,
            notSupported: true,
          });
          return;
        }

        this.version = 2;
        for (const chan of encoding.channels) {
          this.newSourceBuffer(chan);
        }
      } else {
        this.version = 1;
        const { origin } = this.livelyPlayer.data;
        let mimetype;
        if (
          (this.options.origin != null && origin != null && origin.audioCodec !== "aac") ||
          (!this.options.origin && this.livelyPlayer.data.audioCodec !== "aac")
        ) {
          mimetype = 'video/mp4; codecs="avc1.4d4028"';
        } else {
          mimetype = 'video/mp4; codecs="avc1.4d4028, mp4a.40.2"';
        }

        this.ctx.logger.debug("meow adding sourcebuffer", { mimetype });

        this.newSourceBuffer(mimetype);
      }
    } catch (err) {
      const inner = err instanceof Error ? err : null;
      this.emitError(
        new MeowDriverError("meow error in newSourceBuffer(). restarting player", {
          inner,
        }),
      );
      this.destroy();
      this.livelyPlayer.player = null;
      this.livelyPlayer.restart(false);
    }
  }

  setupTransport(): void {
    const channelCount = (this.encoding?.channels?.length ?? 0) > 0 ? this.encoding?.channels?.length ?? 0 : 1;
    const { manifest } = this.livelyPlayer;
    this.manifest = manifest;

    if (manifest == null) {
      this.emit("error", { message: ERRORS.INVALID_MEDIA_URL, fatal: true });
      return;
    }

    if (this.transport != null) {
      this.transport.removeAllListeners();
      this.transport.destroy();
    }

    this.transport = new Transport(
      extendContext(this.ctx, Transport),
      manifest,
      this.options.backFill,
      this.options.maxGap,
      this.options.retry,
      this.options.mode,
      this.options.download,
      this.options.segmentDuration,
      channelCount,
    );
    this.transport.on("error", () => {
      if (this.transport?.disconnected) {
        this.emit("error", { message: ERRORS.WS_NETWORK_ERROR, fatal: false });
      } else {
        this.emit("error", {
          message: "websocket-connection",
          notSupported: true,
        });
      }
    });

    this.transport.on("destroy", () => {
      this.emit("error", { message: "websocket-connection", fatal: true });
    });

    this.transport.on("message", (ev) => {
      this.lastMessageReceived = this.device.performance.now();
      this.queue.push({
        type: "append",
        blob: ev.data,
        time: ev.fetchTime,
      });
      this.appendBuffer();
    });
  }

  switchBitrate(name: string): void {
    this.ctx.logger.debug("meow switching bitrate to ", {
      name,
    });

    // not sure why we have to destroy abrController after first bitrate switching?
    // this.abrController?.destroy();
    this.transport?.switchBitrate(name);
  }

  async play(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const { el } = this;
      if (el == null) {
        throw new Error(ERRORS.ELEMENT_REQUIRED);
      }

      this.queue = [];
      if (this.transport == null) {
        this.setupTransport();
      }

      this.lastMessageReceived = this.device.performance.now();
      this.logInterval = device.setInterval(() => {
        const diff = this.device.performance.now() - (this.lastMessageReceived ?? 0);
        if (diff > 2000) {
          this.emit("error", {
            message: "stall",
            timeSinceLastFrag: diff,
          });
        }
      }, 1000);

      this.gcInterval = device.setInterval(() => {
        if (el?.currentTime == null) {
          return;
        }

        for (const sb of this.sourceBuffers) {
          this.queue.push({
            type: "remove",
            sb: sb ?? undefined,
            start: 0,
            end: el.currentTime - 15,
          });
        }
      }, 30 * 1000);

      this.ctx.logger.info("meow player play attempted");
      return this.livelyPlayer.corePlay().catch((e: Error) => {
        this.ctx.logger.warn("meow player play attempt failed", {
          errName: e.name,
          errMessage: e.message,
        });
        this.stop();
      });
    });
  }

  stop(): void {
    const { el } = this;
    if (el == null) {
      throw new Error(ERRORS.ELEMENT_REQUIRED);
    }

    this.ctx.logger.debug("meow player stop");
    clearInterval(this.gcInterval);
    clearInterval(this.logInterval);

    this.transport?.removeAllListeners("message");

    this.buffering = false;
    el.pause();
    if (el.src !== "") {
      this.ctx.logger.debug("meow revoking media source url");
      URL.revokeObjectURL(el.src);
      el.src = "";
    }
    this.emit("stop");
  }

  destroy(): void {
    this.destroyed = true;
    if (this.transport != null) {
      this.transport.removeAllListeners("destroy");
      this.transport.removeAllListeners("message");
      this.transport.destroy();
    }

    this.abrController?.destroy();
    this.stop();
  }
}

const BOX_NAMES = ["moof", "mfhd"];
const INIT_BOX = "ftyp";

function sFCC(dv: DataView, n: number): string {
  return String.fromCharCode(dv.getUint8(n));
}

function getContentTag(ab: ArrayBuffer, dv: DataView): number {
  if (ab.byteLength < 9) {
    return 1;
  }

  // last box should be skip
  if (dv.getUint32(ab.byteLength - 5) !== 0x736b6970) {
    return 1;
  }
  return dv.getUint8(ab.byteLength - 1);
}

class Latency {
  public lastTime: number = device.isImplements(AdapterFeature.DEBUGGING) ? device.performance.now() : Date.now();

  public lastSegmentTime: number = device.isImplements(AdapterFeature.DEBUGGING)
    ? device.performance.now()
    : Date.now();

  public lastSegmentDuration = 0;
}

interface SegmentInfo {
  init: boolean;
  contentTag: number;
  segment: number;
}

interface ExtendedMessageEvent extends MessageEvent {
  fetchTime: number;
  segmentDuration: number;
}

interface TransportEventsMap {
  message: ExtendedMessageEvent;
  close: { code: CloseEvent };
  destroy: void;
  error: void;
}

class Transport extends ObservableEventEmitter<TransportEventsMap> implements Serializable {
  static readonly displayName = "Mp4WsTransport";

  private readonly uri: string;

  private readonly backFill: number;

  private readonly maxGap: number;

  private readonly retry: number;

  private readonly mode: string;

  private readonly segmentDuration: number;

  private readonly saveToDownload: boolean;

  private downloadedSegments: any[] = [];

  private introFrag: Record<string, unknown> = {};

  private readonly ws: WebSocket;

  private channelTracker = 0;

  private readonly channelLatency: Latency[] = [];

  private readonly pingInterval: number | undefined = undefined;

  private readonly device: DeviceAPI & DebuggingFeature & MediaSourceFeature & LocalStorageFeature;

  private readonly ctx: VcContext;

  /**
   * @param uri
   * @param {int} backFill
   * @param {int} maxGap
   * @param {int} retry
   * @param mode
   * @param download
   * @param segmentDuration
   * @param channelCount
   */
  constructor(
    ctx: VcContext,
    uri: string,
    backFill = 0,
    maxGap = 500,
    retry = 20,
    mode = "B",
    download = false,
    segmentDuration = 300,
    channelCount = 1,
  ) {
    super();

    this.ctx = ctx;
    onceCanceled(ctx).then((reason) => this.dispose(`Transport Class Context Cancelled: ${reason}`));
    this.addInnerDisposer(this.destroy);

    if (
      !device.isImplements(AdapterFeature.DEBUGGING) ||
      !device.isImplements(AdapterFeature.MEDIA_SOURCE) ||
      !device.isImplements(AdapterFeature.LOCAL_STORAGE)
    ) {
      throw new Error("Device is not supported");
    }

    this.device = device;

    this.uri = uri;
    this.backFill = backFill;
    this.maxGap = maxGap;
    this.retry = retry;
    this.mode = mode;
    this.segmentDuration = segmentDuration;
    this.saveToDownload = download;
    this.introFrag = {};
    const url = `${this.uri}&bckfil=${this.backFill}`;
    this.ws = new WebSocket(url);
    InstanceCollector.reportNewInstance("websocket", this.ws, { file: "player/mp4-ws.ts" });

    this.channelLatency = [];
    for (let i = 0; i < channelCount; i++) {
      this.channelLatency.push(new Latency());
    }

    this.ctx.logger.debug("meow ws connecting", { url });
    this.ws.onopen = () => {
      this.ctx.logger.debug("meow ws open");
    };

    this.ws.onmessage = (ev) => {
      // this.ctx.logger.debug("meow ws frame received", { datatype: typeof ev.data });
      if (typeof ev.data === "object") {
        this.emit("message", {
          ...ev,
          data: ev.data,
          fetchTime: this.device.performance.now() - this.lastTime + 10,
          segmentDuration: this.lastSegmentDuration ?? this.segmentDuration,
        });

        this.lastSegmentTime = this.device.performance.now();
        if (this.saveToDownload) {
          this.downloadedSegments.push(ev.data);
        }

        this.nextChannel();
        return;
      }

      const parts = ev.data.split(/\s/g);
      const mSegmentDuration = parseInt(parts[0], 10);
      if (Number.isNaN(mSegmentDuration)) {
        try {
          this.introFrag = JSON.parse(ev.data);
        } catch (err) {
          this.ctx.logger.error("unhandled websocket frame - attempted to parse and failed", { event: ev.data });
        }
        return;
      }

      this.lastTime = this.device.performance.now();
      this.lastSegmentDuration =
        mSegmentDuration < 2000 && mSegmentDuration > 0 ? mSegmentDuration : this.segmentDuration;
    };

    this.ws.onclose = (code) => {
      this.ctx.logger.debug("meow ws closing", { reason: code.reason, code: code.code });
      InstanceCollector.disposeInstance("websocket", this.ws);
      this.emit("close", { code });
      this.emit("destroy");
    };

    this.ws.onerror = () => {
      this.emit("error");
    };

    ctx.logger.attachObject(this);
    ctx.logger.trace("constructor()");
  }

  get lastTime(): number {
    return this.channelLatency[this.channelTracker].lastTime;
  }

  set lastTime(v: number) {
    this.channelLatency[this.channelTracker].lastTime = v;
  }

  get lastSegmentTime(): number {
    return this.channelLatency[this.channelTracker].lastSegmentTime;
  }

  set lastSegmentTime(v: number) {
    this.channelLatency[this.channelTracker].lastSegmentTime = v;
  }

  get lastSegmentDuration(): number {
    return this.channelLatency[this.channelTracker].lastSegmentDuration;
  }

  set lastSegmentDuration(v: number) {
    this.channelLatency[this.channelTracker].lastSegmentDuration = v;
  }

  nextChannel(): void {
    this.channelTracker++;
    if (this.channelLatency.length >= this.channelTracker) {
      this.channelTracker = 0;
    }
  }

  download(): void {
    // const a = document.createElement("a");
    // document.body.appendChild(a);
    // a.style.display = "none";
    //
    // const blob = new window.Blob(this.downloadedSegments, {
    //   type: "octet/stream",
    // });
    // const url = window.URL.createObjectURL(blob);
    // a.href = url;
    // a.download = "blah.mp4";
    // a.click();
    // window.URL.revokeObjectURL(url);
  }

  get disconnected(): boolean {
    return this.ws.readyState > 1;
  }

  // get latency() {
  // 	if (!this.latencyCount) {
  // 		return 0;
  // 	}
  // 	return this.latencySum / this.latencyCount * 0.75;
  // }

  /**
   * parse out current segment number from skip boxes
   * @return {int} current segment number
   */
  getSegmentInfo(ab: ArrayBuffer): SegmentInfo {
    const dv = new DataView(ab);

    const contentTag = getContentTag(ab, dv);

    let boxSize = 0;
    let i = 0;
    let j = 0;
    let len = ab.byteLength;

    let segmentNo = 0;
    let init = false;

    if (`${sFCC(dv, 4)}${sFCC(dv, 5)}${sFCC(dv, 6)}${sFCC(dv, 7)}` === INIT_BOX) {
      init = true;
    }

    for (i = 0; i < len && j < BOX_NAMES.length; ) {
      boxSize = dv.getUint32(i);
      // step into box
      if (
        sFCC(dv, i + 4) === BOX_NAMES[j].charAt(0) &&
        sFCC(dv, i + 5) === BOX_NAMES[j].charAt(1) &&
        sFCC(dv, i + 6) === BOX_NAMES[j].charAt(2) &&
        sFCC(dv, i + 7) === BOX_NAMES[j].charAt(3)
      ) {
        len = i + boxSize;
        j++;
        i += 8; // works only for Box, not for extended box
      } else {
        i += boxSize;
      }
    }

    // we found it
    if (j === BOX_NAMES.length) {
      segmentNo = dv.getUint32(i + 4);
    }

    return {
      contentTag,
      segment: segmentNo,
      init,
    };
  }

  /**
   * Switches the current active bitrate
   *
   * @param name stream name
   */
  switchBitrate(name: string): void {
    this.ws.send(
      `switch_stream?name=${name}&mode=${this.mode}&bckfil=${this.backFill}&retry=${this.retry}&mxgap=${this.maxGap}`,
    );
  }

  /**
   * Destroys the websocket connection
   */
  destroy(): void {
    this.ctx.logger.info("meow transport destroy");
    this.downloadedSegments = [];

    clearInterval(this.pingInterval);
    if (this.ws == null) {
      return;
    }

    this.ctx.logger.info("meow ws close");
    this.ws.onerror = null;
    this.ws.close();
    InstanceCollector.disposeInstance("websocket", this.ws);
    this.dispose("Transport was destroyed");
  }

  toJSON(): Json {
    return {
      uri: this.uri,
      disconnected: this.disconnected,
      retry: this.retry,

      aggregates: {
        support: this.ctx.support.hash,
        wsReadyState: this.ws.readyState,
      },
    };
  }
}

const SAMPLE_SIZE = 300; // sample size for estimating bandwidth
const RECENT_KBPS = 120;
const PROFILE_INTERVAL = 10000;
const RECENT_KBPS_INTERVAL = 1000;
const MAX_BW_ESTIMATE = 50000 * 8;
const BASE_SWITCH_THRESHOLD = 8 * 1000; // 8 seconds
const MIN_SIZE = 2000; // minimum size to use in estimated bw
const MIN_MS_TIME = 2; // minimum required ms download time
const IGNORE = 0;
const MIN_SAMPLE_SIZE = 25;
const EMERGENCY_RELEASE = 900;

interface Profile {
  key: string;

  bwModifier(bytes: number): number;

  resistUpshift(kbps: number, encodingKbps: number): boolean;

  resistDownshift(kbps: number, encodingKbps: number): boolean;
}

type ProfileTypes = { [key: string]: Profile };

const profileTypes: ProfileTypes = {
  stable: {
    key: "stable",
    bwModifier(bytes) {
      return Math.min(bytes, bytes - (bytes * 0.01 + Math.log(bytes * bytes) / Math.log(2)));
    },
    resistUpshift(kbps, encodingKbps) {
      return encodingKbps * 2.5 + Math.log(encodingKbps * 2) ** 3.2 > kbps * 2;
    },
    resistDownshift(kbps, encodingKbps) {
      return kbps * 2 + Math.log(kbps * 2) ** 3.2 > encodingKbps * 2;
    },
  },

  normal: {
    key: "normal",
    bwModifier(bytes) {
      return Math.min(bytes, bytes - (bytes * 0.06 + Math.log(bytes * bytes) / Math.log(2)));
    },
    resistUpshift(kbps, encodingKbps) {
      return encodingKbps * 2.5 + Math.log(encodingKbps * 2) ** 3.4 > kbps * 2;
    },
    resistDownshift(kbps, encodingKbps) {
      return kbps * 2 + Math.log(kbps * 2) ** 3.4 > encodingKbps * 2;
    },
  },

  uncertain: {
    key: "uncertain",
    bwModifier(bytes: number) {
      return Math.min(bytes, bytes - (bytes * 0.3 + Math.log(bytes * bytes) / Math.log(2)));
    },
    resistUpshift(kbps, encodingKbps) {
      return encodingKbps * 2.5 + Math.log(encodingKbps * 2) ** 3.6 > kbps * 2;
    },
    resistDownshift(kbps, encodingKbps) {
      return kbps * 2 + Math.log(kbps * 2) ** 3.4 > encodingKbps * 2;
    },
  },

  sporadic: {
    key: "sporadic",
    bwModifier(bytes) {
      return Math.min(bytes, bytes - (bytes * 0.5 + Math.log(bytes * bytes) / Math.log(2)));
    },
    resistUpshift(kbps, encodingKbps) {
      return encodingKbps * 2.5 + Math.log(encodingKbps * 2) ** 4 > kbps * 2;
    },
    resistDownshift(kbps, encodingKbps) {
      return kbps * 2 + Math.log(kbps * 2) ** 3.4 > encodingKbps * 2;
    },
  },
};

export type ProfileName = keyof typeof profileTypes;

// type Driver = BaseDriver<ExtEncoding>;

interface AbrControllerEventsMap {
  profile: {
    estimatedKbps: number;
    profile: Profile;
  };
  upshift: void;
  downshift: void;
  "meow profile": {
    estimatedKbps: number;
    profile: Profile;
  };
}

class AbrController extends ObservableEventEmitter<AbrControllerEventsMap> {
  static readonly displayName = "AbrController";

  private readonly player: Player;

  private readonly driver: Mp4WsPlayer;

  private switchImmediately: boolean;

  private readonly switchThreshold: number = BASE_SWITCH_THRESHOLD;

  private count = 0;

  private dropCount = 0;

  private totalShifts = 0;

  private profile: Profile;

  private readonly maxShifts: number;

  private currentKbps: number;

  private rawKbps: number[] = [];

  private readonly recentKbps: number[] = [];

  private readonly sampleBws: number[] = [];

  private selectedEncoding: Encoding | null = null;

  private lastMessageTime: number;

  private lastSwitch: number;

  private emergencyModifier = 1;

  private readonly releaseEmergencyTimeout: number | undefined = undefined;

  private profileInterval: number | undefined = undefined;

  private trackRecentKbpsInterval: number | undefined = undefined;

  private estimatedKbpsInterval: number | undefined = undefined;

  private destroyed = false;

  private readonly spinTimeout: number | undefined = undefined;

  private readonly device: DeviceAPI & DebuggingFeature & MediaSourceFeature & LocalStorageFeature;

  private readonly options: Mp4WsPlayerOptions;

  private readonly ctx: VcContext;

  private readonly logger: LoggerCore;

  constructor(
    ctx: VcContext,
    player: Player,
    driver: Mp4WsPlayer,
    profile: ProfileName,
    switchImmediately: boolean,
    maxShifts: number,
    options: Mp4WsPlayerOptions,
  ) {
    super();

    this.ctx = ctx;

    onceCanceled(ctx).then((reason) => this.dispose(`AbrController Class Context Cancelled: ${reason}`));
    this.addInnerDisposer(this.destroy);

    if (
      !device.isImplements(AdapterFeature.DEBUGGING) ||
      !device.isImplements(AdapterFeature.MEDIA_SOURCE) ||
      !device.isImplements(AdapterFeature.LOCAL_STORAGE)
    ) {
      throw new Error("Device is not supported");
    }

    this.logger = new LoggerCore(PACKAGE_NAME).extend(ctx.logger);
    this.device = device;
    this.options = options;

    this.player = player;
    this.driver = driver;
    this.switchImmediately = switchImmediately;
    this.profile = profileTypes[profile] ?? profileTypes.normal;
    this.maxShifts = maxShifts ?? Infinity;
    this.currentKbps = this.options.bitrate ?? Math.max(this.options.estimatedKbps ?? 0, 244);
    this.lastMessageTime = this.device.performance.now();
    this.lastSwitch = -1 * BASE_SWITCH_THRESHOLD;

    this.init();
  }

  init(): void {
    this.player.on("fragment", this.onMessage);
    this.profileInterval = device.setInterval(this.profileStream.bind(this), PROFILE_INTERVAL);
    this.trackRecentKbpsInterval = device.setInterval(() => {
      let total = 0;
      for (let i = 0; i < this.rawKbps.length; i++) {
        total += this.rawKbps[i];
      }
      const sample = total / this.rawKbps.length;
      this.rawKbps = [];
      this.recentKbps.unshift(sample);
      this.recentKbps.splice(RECENT_KBPS);
    }, RECENT_KBPS_INTERVAL);

    this.estimatedKbpsInterval = device.setInterval(() => {
      this.emit("profile", {
        estimatedKbps: this.currentKbps,
        profile: this.profile,
      });

      if (this.selectedEncoding == null) {
        this.logger.debug("meow abr estimated", {
          KBPS: this.currentKbps,
          modifier: this.emergencyModifier,
          lastSwitch: this.lastSwitch,
          shiftable: this.shiftable,
          profile: this.profile.key,
        });
        return;
      }
      this.logger.debug("meow abr estimated", {
        KBPS: this.currentKbps,
        modifier: this.emergencyModifier,
        lastSwitch: this.lastSwitch,
        shiftable: this.shiftable,
        profile: this.profile.key,
        encoding: this.selectedEncoding,
      });
    }, 4000);
  }

  get shiftable(): boolean {
    return (
      this.totalShifts < this.maxShifts &&
      this.sampleBws.length > MIN_SAMPLE_SIZE &&
      this.device.performance.now() - this.lastSwitch > this.switchThreshold
    );
  }

  handleEmergencyDrop(modifier: number, remainingSample: number): void {
    this.dropCount++;

    if (this.dropCount < 2) {
      return;
    }
    this.dropCount = 0;
    this.emergencyModifier = modifier;
    this.sampleBws.splice(remainingSample);

    device.clearTimeout(this.releaseEmergencyTimeout);
    device.setTimeout(() => {
      this.emergencyModifier = 1;
    }, EMERGENCY_RELEASE);
  }

  profileStream(): void {
    const sortedKbps: number[] = [];
    sortedKbps.push(...this.recentKbps);
    sortedKbps.sort((a, b) => {
      if (a > b) {
        return 1;
      }
      return -1;
    });

    const quartile = Math.floor(sortedKbps.length / 4);
    const percentDiff1 = (sortedKbps[quartile * 2] - sortedKbps[quartile]) / sortedKbps[quartile * 2];
    const percentDiff2 = (sortedKbps[quartile * 3] - sortedKbps[quartile * 2]) / sortedKbps[quartile * 3];
    const sum = percentDiff1 + percentDiff2;

    if (sum > 1) {
      this.profile = profileTypes.sporadic;
    } else if (sum > 0.65) {
      this.profile = profileTypes.uncertain;
    } else if (sum > 0.3) {
      this.profile = profileTypes.normal;
    } else {
      this.profile = profileTypes.stable;
    }
  }

  onMessage(ev: PlayerFragment): void {
    if (this.destroyed) {
      return;
    }

    this.lastMessageTime = this.device.performance.now();
    if (ev.time > ev.duration * 2.5) {
      this.dropCount++;
      this.handleEmergencyDrop(0.6, (MIN_SAMPLE_SIZE - 1) * 3);
    } else if (ev.time > ev.duration * 2.25) {
      this.handleEmergencyDrop(0.7, Math.max(MIN_SAMPLE_SIZE - 1, Math.floor(SAMPLE_SIZE * 0.5)));
    } else if (ev.time > ev.duration * 2) {
      this.handleEmergencyDrop(0.8, Math.max(MIN_SAMPLE_SIZE - 1, Math.floor(SAMPLE_SIZE * 0.7)));
    } else if (ev.time > ev.duration * 1.75) {
      this.handleEmergencyDrop(0.9, Math.max(MIN_SAMPLE_SIZE - 1, Math.floor(SAMPLE_SIZE * 0.8)));
    } else {
      this.dropCount = 0;
    }

    const bw = (this.profile.bwModifier(ev.size * 8) * this.emergencyModifier) / ev.time;
    this.rawKbps.push((ev.size * 8) / ev.time);

    if (ev.time < MIN_MS_TIME) {
      ev.time = MIN_MS_TIME;
    }

    // Init
    if (ev.size < MIN_SIZE) {
      if (this.count > IGNORE) {
        this.count = Math.floor(IGNORE / 2);
      }
      return;
    }

    // if (this.currentKbps && bw > this.currentKbps * 6 && this.sampleBws.length * 2 < MIN_SIZE) {
    // 	bw = this.currentKbps * 6;
    // }

    this.count++;
    if (!Number.isNaN(bw) && this.count > IGNORE && bw < MAX_BW_ESTIMATE) {
      this.sampleBws.unshift(bw);
      this.sampleBws.splice(SAMPLE_SIZE);
      this.currentKbps = this.calculateCurrentKbps();
    }

    if (this.shiftable) {
      this.shiftIfNecessary();
    }
  }

  shiftIfNecessary(): void {
    if (this.driver.currentLevel == null) {
      return;
    }

    const { activeLayer } = this.driver;
    const encodings = this.driver.data.encodings.filter((e) => {
      if (activeLayer == null) {
        return true;
      }

      return (e.videoKbps ?? 0) + (e.audioKbps ?? 0) < (activeLayer.bitrate ?? 0) + DELTA;
    });
    const currentEncoding = encodings[this.driver.currentLevel];

    let suggestedEncoding = encodings[0];
    let chosenI = 0;
    for (let i = 0; i < encodings.length; i++) {
      if ((encodings[i].videoKbps ?? 0) + (encodings[i].audioKbps ?? 0) < this.currentKbps) {
        suggestedEncoding = encodings[i];
        chosenI = i;
      } else {
        break;
      }
    }

    if (this.switchImmediately) {
      this.switchBitrate(suggestedEncoding);
      this.switchImmediately = false;
      this.logger.debug("meowpick first adaptive encoding", { suggestedEncoding });
      return;
    }

    if (currentEncoding == null || currentEncoding.videoKbps === suggestedEncoding.videoKbps) {
      return;
    }

    suggestedEncoding.kbps = (suggestedEncoding.videoKbps ?? 0) + (suggestedEncoding.audioKbps ?? 0);
    currentEncoding.kbps = (currentEncoding.videoKbps ?? 0) + (currentEncoding.audioKbps ?? 0);

    // Stiffness criteria
    if (suggestedEncoding.kbps > currentEncoding.kbps) {
      while (
        this.profile.resistUpshift(this.currentKbps, suggestedEncoding.kbps ?? 0) &&
        (suggestedEncoding.kbps ?? 0) > currentEncoding.kbps
      ) {
        suggestedEncoding = encodings[--chosenI];
        if (suggestedEncoding == null || (suggestedEncoding.kbps ?? 0) < currentEncoding.kbps) {
          this.logger.debug("meow tried to upshift - too stiff");
          return;
        }
      }

      this.emit("upshift");
    } else {
      while (
        this.profile.resistDownshift(this.currentKbps, suggestedEncoding.kbps ?? 0) &&
        (suggestedEncoding.kbps ?? 0) < currentEncoding.kbps
      ) {
        suggestedEncoding = encodings[++chosenI];
        if (suggestedEncoding == null || (suggestedEncoding.kbps ?? 0) > currentEncoding.kbps) {
          this.logger.debug("meow tried to downshift - too stiff");
          return;
        }
      }

      this.emit("downshift");
    }

    if (currentEncoding.videoKbps === suggestedEncoding.videoKbps) {
      return;
    }

    this.logger.debug("meow switching bitrate", {
      currentEncoding,
      suggestedEncoding: currentEncoding,
      currentKbps: this.currentKbps,
    });

    this.lastSwitch = this.device.performance.now();
    this.selectedEncoding = suggestedEncoding;
    this.switchBitrate(suggestedEncoding);
    this.totalShifts++;
  }

  switchBitrate(suggestedEncoding: Encoding): void {
    this.emit("meow profile", {
      estimatedKbps: this.currentKbps,
      profile: this.profile,
    });
    this.driver.setEncoding(suggestedEncoding);
  }

  calculateCurrentKbps(): number {
    let total = 0;
    for (let i = 0; i < this.sampleBws.length; i++) {
      total += this.sampleBws[i];
    }

    return total / this.sampleBws.length;
  }

  destroy(): void {
    device.clearTimeout(this.spinTimeout);
    device.clearTimeout(this.releaseEmergencyTimeout);
    clearInterval(this.estimatedKbpsInterval);
    this.destroyed = true;
    this.player?.removeListener("fragment", this.onMessage);
  }
}
