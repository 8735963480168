/* eslint-disable @typescript-eslint/no-explicit-any */
import type { IEventEmitter } from "@livelyvideo/events-typed";
import type { LoggerCore } from "@livelyvideo/log-client";
import type { Serializable } from "@livelyvideo/log-node";
import { FlvHttpPlayer, FlvHttpPlayerOptions } from "../internal/player/flv-http";
import { HlsJsPlayer, HlsJsPlayerOptions } from "../internal/player/hlsjs";
import { MediasoupPlayer, MediasoupPlayerOptions } from "../internal/player/mediasoup";
import { Mp4WsPlayer, Mp4WsPlayerOptions } from "../internal/player/mp4-ws";
import { NativeHlsPlayer, NativeHlsPlayerOptions } from "../internal/player/native-hls";
import { WebrtcPlayer, WebrtcPlayerOptions } from "../internal/player/webrtc";
import type { VcContext } from "../internal/utils/context/vc-context";
import type { Disposable, Merge } from "./common";
import type { IVideoClientError } from "./error";
import type { ManifestFormats } from "./manifest";
import type { Quality } from "./player/features/bitrate-switching";
import type { Features as PlayerFeatures } from "./player/features/feature";
import type { VideoElement } from "./typings/video-element";

export type PlayerEvents<HostElement extends VideoElement = VideoElement> = {
  /**
   * @description Is emitted when the video is paused.
   * @example player.on("localVideoPaused", (bool) => { if(bool) { // show a paused icon } })
   */
  localVideoPaused: boolean;

  /**
   * @description Is emitted when the audio is muted.
   * @example player.on("localAudioMuted", (bool) => { if(bool) { // show a no-audio icon } })
   */
  localAudioMuted: boolean;

  /**
   * @description Is emitted when the player volume is changed.
   * @example player.on("localAudioVolume", (number) => { //set volume range to number})
   */
  localAudioVolume: number;

  /**
   * @description Is emitted when the HTMLVideoElement supplied receives a src or srcObject.
   * @example player.on("hostElementAttached", ({el}) => { // display element})
   */
  hostElementAttached: { el: HostElement };

  /**
   * @description Is emitted as the player progresses.
   * @example player.on("progress", () => { console.log("progress")})
   */
  progress: void;

  /**
   * @description Is emitted when the player's time updates.
   * @example player.on("timeupdate", () => { console.log("timeupdate")})
   */
  timeupdate: void;

  /**
   * @description Is emitted when the player is played for the first time.
   * @example player.on("videoFirstPlay", () => { //do some cleanup})
   */
  videoFirstPlay: void;

  /**
   * @description Is emitted when an autoplaying player has been forced to mute by the browser.
   * @example player.on("forcedMute", () => { // show unmute button)
   */
  forcedMute: boolean;

  /**
   * @description Is emitted when a timeout has occurred and driverFailover is true.
   * @example player.on("driverFailover", () => { // switch to next driver)
   */
  driverFailover: boolean;

  /**
   * @description Is emitted when the player is disposed.
   * @example player.on("disposed", () => { //do some cleanup})
   */
  availableQualities: Quality[];

  currentQuality: Quality | null;

  disposed: void;

  restartDriver: { timeout: number };

  /**
   * @description Emits the current driver in use by a manifestPlayer
   * @example player.on("driver", (driver) => { console.log(driver)})
   */
  driver: string;

  error: IVideoClientError;

  /**
   * Is emitted when the player changes its implementation.
   */
  implementation: void;

  /**
   * @description Is emitted when the remote peer's audio track active state changes.
   * @example player.on("consumerAudioEnabled", (bool) => { if(!bool) { // handle no audio } })
   */
  consumerAudioEnabled: boolean;
  /**
   * @description Is emitted when the remote peer's video track active state changes.
   * @example player.on("consumerVideoEnabled", (bool) => { if(!bool) { // handle no video } })
   */
  consumerVideoEnabled: boolean;
};

export interface PlayerConstructor<HostElement extends VideoElement = VideoElement> {
  new (ctx: VcContext, provider: any, options: any): PlayerAPI<HostElement>;

  readonly displayName: string;

  readonly format: keyof ManifestFormats | null;

  isSupported(): Promise<boolean>;
}

/**
 * Represents a media player which controls VideoElement
 */
export interface PlayerAPI<HostElement extends VideoElement = VideoElement>
  extends Merge<IEventEmitter<PlayerEvents>, Disposable>,
    Serializable {
  driverFailover?: boolean;
  localVideoPaused?: boolean;

  /**
   * True if local audio is muted
   * If WebRTC is used then it also stops all audio traffics
   *
   * @emits localAudioMuted if localAudioMuted has changed
   */
  localAudioMuted?: boolean;

  /**
   * Current local volume
   *
   * @emits localAudioVolume if localAudioVolume has changed
   */
  localAudioVolume?: number;

  poster?: string | null;

  autoPlay?: boolean;
  /**
   * Returns true if VideoElement is attached
   */
  readonly attached?: boolean;

  readonly format?: keyof ManifestFormats | null;

  isSupported(): Promise<boolean>;

  /**
   * Attaches player to an existing video element
   */
  attachTo(el: HostElement): void;

  isImplements<K extends keyof PlayerFeatures, T extends PlayerFeatures[K]>(feature: K): this is this & T;

  logger: LoggerCore | undefined;

  forcedMuted?: boolean;

  blurred?: boolean;
}

export type PlayerOptionsMap = {
  readonly ["native-hls"]: NativeHlsPlayerOptions;
  readonly hlsjs: HlsJsPlayerOptions;
  readonly webrtc: WebrtcPlayerOptions;
  readonly mp4ws: Mp4WsPlayerOptions;
  readonly mediasoup: MediasoupPlayerOptions;
  readonly flvhttp: FlvHttpPlayerOptions;
};

export type PlayerOptions = {
  readonly autoPlay?: boolean;
  readonly muted?: boolean;
  readonly volume?: number;
  readonly players: PlayerSpecList;
  readonly displayPoster?: DisplayPosterOption;
  readonly driverFailoverSeconds?: number;
  readonly mutedAutoplayFallback?: boolean;
  readonly blurred?: boolean;
};

export type DisplayPosterOption =
  | boolean
  | "preview"
  | {
      videoHeight: number;
      videoWidth: number;
    };

export type GenericDriverOptions = Pick<PlayerOptions, "muted" | "autoPlay" | "volume">;

export type PlayerSpec<T extends keyof PlayerOptionsMap> = {
  id: T;
  supported?: boolean;
  options?: PlayerOptionsMap[T];
};

export type PlayerName = keyof PlayerOptionsMap;
export type ManifestPlayerName = Exclude<keyof PlayerOptionsMap, "mediasoup">;

export type PlayerAnySpec =
  | PlayerSpec<"webrtc">
  | PlayerSpec<"native-hls">
  | PlayerSpec<"hlsjs">
  | PlayerSpec<"mediasoup">
  | PlayerSpec<"mp4ws">
  | PlayerSpec<"flvhttp">;

export type ManifestPlayerAnySpec = Exclude<PlayerAnySpec, PlayerSpec<"mediasoup">>;

export type PlayerSpecList = Array<PlayerAnySpec | PlayerName>;
export type ManifestPlayerSpecList = Array<ManifestPlayerAnySpec | ManifestPlayerName>;

export interface PlayerMap {
  readonly ["native-hls"]: typeof NativeHlsPlayer;
  readonly hlsjs: typeof HlsJsPlayer;
  readonly webrtc: typeof WebrtcPlayer;
  readonly mediasoup: typeof MediasoupPlayer;
  readonly mp4ws: typeof Mp4WsPlayer;
  readonly flvhttp: typeof FlvHttpPlayer;
}

export const players: PlayerMap = {
  "native-hls": NativeHlsPlayer,
  hlsjs: HlsJsPlayer,
  webrtc: WebrtcPlayer,
  mediasoup: MediasoupPlayer,
  mp4ws: Mp4WsPlayer,
  flvhttp: FlvHttpPlayer,
};
