import { create } from "jss";
import camelCase from "jss-plugin-camel-case";
import defaultUnit from "jss-plugin-default-unit";
import nested from "jss-plugin-nested";
import { useLayoutEffect } from "react";
import { livelyStylesNamespace } from "../../store/utils";
import { StylesObject } from "../ui-lib/typings/css";
import { mergeStylesObjects } from "./utils";

interface ClassesObject {
  source: StylesObject;
  target: StylesObject;
}

const jss = create({ plugins: [camelCase(), nested(), defaultUnit()] });

export const useStyles = ({ target = {}, source = {} }: ClassesObject, componentNamespace: string): any => {
  const styles = mergeStylesObjects(target, source);
  const sheet = jss.createStyleSheet(styles, { classNamePrefix: `${livelyStylesNamespace}-${componentNamespace}-` });

  useLayoutEffect(() => {
    sheet.attach();
    return () => {
      sheet.detach();
    };
  }, [sheet]);

  return sheet.classes;
};

/**
 * @deprecated use useStyles() instead
 */
export const mergeStyles = useStyles;

/**
 * @deprecated
 */
type MakeStylesNamespace = {
  classNamePrefix: string;
  name: string;
};

/**
 * @deprecated use useStyles() instead
 */
export const makeStyles = (styles: any, namespace: MakeStylesNamespace): any =>
  useStyles({ target: styles, source: {} }, namespace.classNamePrefix);
