/* eslint-disable no-constructor-return,@typescript-eslint/ban-types */
import { IEventEmitter } from "@livelyvideo/events-typed";
import { LoggerCore } from "@livelyvideo/log-client/lib";
import type { Json } from "@livelyvideo/log-node";
import { extractAggregates } from "@livelyvideo/log-node/lib";
import { makeObservable, observable } from "mobx";
import type {
  Auth,
  BroadcastAPI,
  BroadcastOptions,
  CallAPI,
  CallEvents,
  ConsumerStats,
  DominantSpeaker,
  MediaStream,
  MediaStreamControllerAPI,
  PeerAPI,
  ProducerStats,
  SFUOptions,
  SourceProvider,
  TokenGetter,
  VideoClientStats,
} from "../api";
import { PACKAGE_NAME } from "../utils/common";
import { Broadcast } from "./broadcast";
import { ClientStats } from "./client-stats/client-stats";
import { InternalCallError, JoinCallError, StreamExistsError, ValidationError } from "./errors";
import { Peer } from "./peer";
import { WebrtcPlayerOptions } from "./player/webrtc";
import call, { Call as InternalCall, Join, SFUCallOptions } from "./pvc/call/call";
import type { CallEvents as InternalCallEvents } from "./pvc/call/call.events";
import { makeBounded } from "./utils/bind";
import { cancel, onceCanceled } from "./utils/context/context";
import type { VcContext } from "./utils/context/vc-context";
import { ObservableEventEmitter } from "./utils/events/event-emitter";
import { extendContext } from "./utils/logger";
import { addProxy, EventsHandler, removeProxy } from "./utils/proxy/events-handler";
import request from "./utils/request/request";

// Disabling this line as resolution requires a type file we don't have
const LivelyAuthorization = require("@livelyvideo/auth-core/lib/source");

const callUrlVersion = "v2";

export type BroadcasterCallBody = {
  mode?: string;
  region?: string;
  message?: string;
  environment?: string;
  organization?: string;
  msVersion?: number[] | number;
  clientReferrer?: string;
  streamKey?: string;
};

export type CallOptions = {
  token?: TokenGetter;
  livelyEndpoints?: string[];
  callId?: string;
  sfu: SFUOptions;
  stats?: VideoClientStats;
  playerOptions?: WebrtcPlayerOptions;
  clientReferrer?: string;
  streamKey?: string;
};

const proxyEventsHandler = new EventsHandler<CallEvents, Call>();

export class Call extends ObservableEventEmitter<CallEvents> implements CallAPI {
  static readonly displayName = "Call";

  [addProxy]!: (target: IEventEmitter<{}>) => void;

  [removeProxy]!: (target: IEventEmitter<{}>) => void;

  options!: CallOptions;

  state: "active" | "closed" | "stall" | "error" = "active";

  private livelyEndpoint?: string;

  private failover: string[] = [];

  private failoverCallUrls: string[] = [];

  private readonly region: string | null = null;

  private callUrl?: string;

  private auth?: Auth = undefined;

  private token?: string;

  private tokenRefresher?: () => Promise<string>;

  public pvcCall: InternalCall | null = null;

  private readyPromise!: Promise<void>;

  private readonly ctx: VcContext;

  private readonly connectedPeers: PeerAPI[] = [];

  private joinUrl: string | null = null;

  private failoverJoinUrls: string[] = [];

  broadcasts: Map<string, BroadcastAPI> = new Map();

  private clientStats?: ClientStats | null;

  isOwner = false;

  constructor(ctx: VcContext, owner: boolean, callOptions: CallOptions, sfuJoinParams: Required<Join> | null = null) {
    super(false);

    makeObservable(this, {
      broadcasts: observable,
    });

    this.ctx = ctx;
    onceCanceled(ctx).then((reason) => this.dispose(`VideoClient Context Cancelled: ${reason}`));

    const self = makeBounded(new Proxy(this, proxyEventsHandler));

    self.init(owner, callOptions, sfuJoinParams);

    ctx.logger.attachObject(self);
    ctx.logger.trace("constructor()");

    return self;
  }

  init(owner: boolean, callOptions: CallOptions, sfuJoinParams: Required<Join> | null): void {
    this.isOwner = owner;
    this.options = callOptions;
    if ((callOptions.livelyEndpoints?.length ?? 0) === 0 && this.options.sfu.joinUrl == null) {
      this.throwError(new ValidationError("livelyEndpoints or joinUrl are required", {}));
    }

    this.joinUrl = this.options.sfu.joinUrl ?? null;

    if (callOptions.livelyEndpoints != null) {
      const [livelyEndpoint, ...failover] = callOptions.livelyEndpoints;

      this.livelyEndpoint = livelyEndpoint;
      this.failover = failover;
      this.callUrl = `${livelyEndpoint}/lb/calls`;
      this.failoverCallUrls = failover.map((lb) => `${lb}/lb/calls`);
      if (this.joinUrl == null) {
        this.joinUrl = `${this.livelyEndpoint}/lb/${callUrlVersion}/calls/${this.options.callId}/join`;
        this.failoverJoinUrls = this.failover.map(
          (lb) => `${lb}/lb/${callUrlVersion}/calls/${this.options.callId}/join`,
        );
      }
    }

    if (typeof callOptions.token === "string") {
      this.token = callOptions.token;
    } else {
      this.tokenRefresher = callOptions.token;
    }

    if (callOptions.callId != null) {
      this.emit("callId", { callId: callOptions.callId, user: this.pvcCall?.user ?? null });
    }

    this.auth = new LivelyAuthorization({
      bootstrap: {
        token: this.token,
        refreshToken: this.tokenRefresher,
      },
    });

    const { callId } = this.options;

    this.clientStats = null;

    const sfuOptions: Partial<SFUCallOptions> = callOptions.sfu ?? {};

    this.readyPromise = (async () => {
      if (callId == null) {
        if (this.callUrl == null) {
          this.throwError(new ValidationError("callId should be provided", {}));
        }
        this.pvcCall = await this.createCallAndJoin(callOptions, sfuOptions);
      } else {
        this.pvcCall = await this.joinCall(callId, sfuOptions, sfuJoinParams);
      }
      this.pvcCall.on("error", (err: unknown) => {
        const inner = err instanceof Error ? err : null;
        this.emitError(
          new InternalCallError("internal call error", { inner, internalCall: this.pvcCall ?? undefined }),
        );
      });

      this.emit("callId", { callId: this.pvcCall.call.id, user: this.pvcCall.user ?? null });

      this.pvcCall.on("CALL_ADD_PEER", this.handleAddPeer);
      this.pvcCall.on("CALL_REMOVE_PEER", this.handleRemovePeer);
      this.pvcCall.on("CALL_SFU_CONNECTION", () => this.emit("sfuConnection"));
      this.pvcCall.on("webrtc-stats", (stats) => {
        this.emit("webrtcStats", { stats });
      });
      this.pvcCall.on("webrtc-stats-raw", (stats) => {
        this.emit("webrtcStatsRaw", { stats });
      });
      this.pvcCall.on("CALL_DOMINANT_SPEAKER", (dominantSpeaker: DominantSpeaker) => {
        this.emit("dominantSpeaker", dominantSpeaker);
      });

      this[addProxy](this.pvcCall);

      this.pvcCall.on("CALL_PRODUCER_KICKED", () => {
        this.emit("viewerKicked");
      });

      this.pvcCall.start().catch((err) => {
        this.emitError(
          new JoinCallError("Unable to start call", {
            critical: true,
            inner: err,
            call: this,
          }),
        );
      });

      await new Promise<void>((resolve) => {
        this.pvcCall?.once("CALL_READY", resolve);
      });

      if (this.livelyEndpoint != null) {
        let websocketUri = null;

        if (this.livelyEndpoint.indexOf("https") !== -1) {
          websocketUri = `wss://${this.livelyEndpoint.substring(8)}`;
        } else if (this.livelyEndpoint.indexOf("http") !== -1) {
          websocketUri = `ws://${this.livelyEndpoint.substring(7)}`;
        } else {
          websocketUri = `ws://${this.livelyEndpoint}`;
        }

        if (this.livelyEndpoint.indexOf("localhost") !== -1) {
          websocketUri = "ws://localhost:9002";
        }

        this.clientStats = new ClientStats(
          websocketUri,
          new LoggerCore(PACKAGE_NAME).extend(this.ctx.logger).setLoggerMeta("chain", `${this.ctx.chain}:ClientStats`),
        );
        this.addInnerDisposer(() => {
          try {
            this.clientStats?.close();
          } catch (err) {
            //Do nothing
          }
        });
      }

      if (this.clientStats != null && callOptions.stats?.app != null && callOptions.stats.userId != null) {
        this.pvcCall.on("webrtc-stats", this.handleWebrtcStats);
      }
    })();

    this.addInnerDisposer(this.close);
  }

  get id(): string {
    return this.pvcCall?.call?.id ?? "";
  }

  get peers(): PeerAPI[] {
    return this.connectedPeers;
  }

  private handleAddPeer({ peer }: InternalCallEvents["CALL_ADD_PEER"]): void {
    if (this.pvcCall == null) {
      this.ctx.logger.warn("handleAddPeer() call is null", {
        aggregates: {
          peerId: peer.peerId ?? "",
          displayName: peer.displayName ?? "",
          scope: peer.scope ?? "",
        },
      });
      return;
    }

    if (this.connectedPeers.some((p) => p.peerId === peer.id)) {
      this.ctx.logger.debug("handleAddPeer() peer already exists", {
        aggregates: {
          peerId: peer.peerId ?? "",
          displayName: peer.displayName ?? "",
          scope: peer.scope ?? "",
        },
      });
      return;
    }

    const basePeer = new Peer(extendContext(this.ctx, Peer), this, this.pvcCall, peer, this.options.playerOptions);
    this.connectedPeers.push(basePeer);

    this[addProxy](basePeer);

    this.ctx.logger.debug("handleAddPeer() peer added", basePeer);
    this.emit("peerAdded", { call: this, peer: basePeer });
  }

  private handleRemovePeer({ peerId }: InternalCallEvents["CALL_REMOVE_PEER"]): void {
    const i = this.connectedPeers.findIndex((p) => p.peerId === peerId);
    if (i !== -1) {
      const deletingPeer = this.peers.splice(i, 1)[0];
      if (deletingPeer instanceof Peer) {
        this.ctx.logger.debug("handleRemovePeer() peer removed", deletingPeer);
        deletingPeer.dispose("removing peer");
      }

      this.emit("peerRemoved", { call: this, peer: deletingPeer });
    }
  }

  private handleWebrtcStats(stats: ConsumerStats | ProducerStats): void {
    this.clientStats?.push({
      measurement: stats.kind === "producer" ? "ProducerStats" : "ConsumerStats",
      tags: this.options.stats,
      fields: stats,
      timestamp: Date.now(),
    });
  }

  async ready(): Promise<this> {
    await this.readyPromise;
    return this;
  }

  private async createCall(options: BroadcasterCallBody): Promise<{ id: string } | null> {
    if (this.callUrl == null) {
      this.throwError(new ValidationError("callUrl is null", { critical: true }));
    }

    const body: BroadcasterCallBody = {
      mode: "broadcast",
      region: this.region ?? "",
      msVersion: [3],
    };
    Object.assign(body, options);

    this.ctx.logger.info("call creation", {
      callUrl: this.callUrl,
      auth: {
        token: this.token,
        hasRefreshToken: this.tokenRefresher != null,
      },
      body,
    });

    const response = await request<{ id: string }>(this.ctx, this.callUrl, {
      auth: this.auth,
      method: "post",
      body: JSON.stringify(body),
      failoverUrls: this.failoverCallUrls,
    });

    if (response != null) {
      return response.body;
    }
    this.ctx.logger.error("response is null", {
      callUrl: this.callUrl,
      auth: {
        token: this.token,
        hasRefreshToken: this.tokenRefresher != null,
      },
      ...body,
    });

    return null;
  }

  private async createCallAndJoin(
    options: BroadcasterCallBody,
    sfuOptions: Partial<SFUCallOptions> = {},
  ): Promise<InternalCall> {
    if (this.options?.clientReferrer != null) {
      options.clientReferrer = this.options.clientReferrer;
    }
    const createdCall = await this.createCall(options);
    const id = createdCall?.id ?? "";

    return call(
      this.ctx,
      id,
      `${this.livelyEndpoint}/lb/${callUrlVersion}/calls/${id}/join`,
      {
        auth: this.auth,
        failoverUrls: this.failover.map((lb) => `${lb}/lb/${callUrlVersion}/calls/${id}/join`),
        stats: this.options.stats,
        ...sfuOptions,
      },
      this,
    );
  }

  async joinCall(
    id: string,
    sfuOptions: Partial<SFUCallOptions> = {},
    sfuJoinParams: Required<Join> | null = null,
  ): Promise<InternalCall> {
    if (this.joinUrl == null) {
      this.throwError(new ValidationError("joinUrl is null", {}));
    }

    // Allow the call to have access to environment
    const mergedOptions: Partial<SFUCallOptions> = {
      ...this.options.sfu,
      ...sfuOptions,
    };

    return call(
      this.ctx,
      id,
      this.joinUrl,
      {
        auth: this.auth,
        failoverUrls: this.failoverJoinUrls,
        ...mergedOptions,
        stats: this.options.stats,
      },
      this,
      sfuJoinParams,
    );
  }

  async broadcast(ctrl: MediaStreamControllerAPI, broadcastOptions: BroadcastOptions): Promise<BroadcastAPI> {
    if (this.pvcCall == null) {
      this.throwError(new InternalCallError("call is not started; pvcCall is null", {}));
    }

    if (this.pvcCall.streams[broadcastOptions.streamName] != null || this.broadcasts.has(broadcastOptions.streamName)) {
      this.throwError(
        new StreamExistsError("stream name already exists", {
          broadcast: this.broadcasts.get(broadcastOptions.streamName) ?? ({} as BroadcastAPI),
          call: this,
        }),
      );
    }

    this.ctx.logger.debug("starting broadcast", {
      hasAudio: ctrl.hasActiveAudioTrack(),
      hasVideo: ctrl.hasActiveVideoTrack(),
      broadcastOptions,
    });

    this.pvcCall.setStream(broadcastOptions.streamName, {
      audio: { streamProvider: ctrl as SourceProvider<MediaStream> },
      video: { streamProvider: ctrl as SourceProvider<MediaStream> },
    });

    const newBroadcast = new Broadcast(extendContext(this.ctx, Broadcast), this, this.pvcCall, ctrl, broadcastOptions);

    this[addProxy](newBroadcast);

    /**
     * When the broadcast is disposed and closed, remove the stream
     * from the PVC call and remove the broadcast from `this.broadcasts`.
     */
    newBroadcast.once("disposed", () => {
      this.broadcasts.delete(broadcastOptions.streamName);
    });

    this.broadcasts.set(broadcastOptions.streamName, newBroadcast);

    return newBroadcast;
  }

  close(debugString = "Implementer did not pass debugString"): void {
    if (this.auth != null) {
      this.auth.destroy();
    }
    if (this.state !== "closed") {
      this.pvcCall?.off("CALL_ADD_PEER", this.handleAddPeer);
      this.pvcCall?.off("CALL_REMOVE_PEER", this.handleRemovePeer);
      this.pvcCall?.off("webrtc-stats", this.handleWebrtcStats);
      try {
        this.pvcCall?.close(debugString);
      } catch (err) {
        // pass
      }
      this.connectedPeers.splice(0, this.connectedPeers.length);

      cancel(this.ctx, "Call has been closed");

      this.emit("callClosed", { callId: this.id });
      this.state = "closed";
    }
  }

  public async kickViewer(userId: string, streamOnly: boolean, webhook = false): Promise<void> {
    return this.pvcCall?.kickViewer(userId, streamOnly, webhook);
  }

  // Permissions are type unknown for the time being, when this gets put into production we need to add a type.
  public async promoteViewer(userId: string, permissions: unknown, webhook = false): Promise<void> {
    return this.pvcCall?.promoteViewer(userId, permissions, webhook);
  }

  // Permissions are type unknown for the time being, when this gets put into production we need to add a type.
  public async demoteViewer(userId: string, permissions: unknown, webhook = false): Promise<void> {
    return this.pvcCall?.demoteViewer(userId, permissions, webhook);
  }

  toJSON(): Json {
    return {
      callUrl: this.callUrl,
      joinUrl: this.joinUrl,
      livelyEndpoint: this.livelyEndpoint,
      peersIds: this.connectedPeers.map((p) => p.peerId),
      options: {
        callId: this.options?.callId,
        livelyEndpoints: this.options?.livelyEndpoints,
        playerOptions: this.options?.playerOptions,
        sfu: this.options?.sfu,
      },
      aggregates: {
        ...extractAggregates(this.pvcCall, "support"),
        support: this.ctx.support.hash,
        state: this.state,
      },
    };
  }
}
