import { Properties } from "csstype";
import React, { forwardRef, KeyboardEventHandler, MouseEventHandler, useContext } from "react";
import { EncoderUiState } from "../../../store/encoder";
import { PlayerUiState } from "../../../store/player";
import { LivelyEncoderUiContext, LivelyPlayerUiContext } from "../../context";
import { useStyles } from "../../styling/livelyStyles";
import { RootStyles } from "../typings/css";
import { videoWrapperStyles } from "./styles";

interface VideoWrapperClasses extends RootStyles {
  playerOverlayButton?: RootStyles;
}

export interface VideoWrapperProps {
  onClick?: () => MouseEventHandler<HTMLButtonElement> | KeyboardEventHandler<HTMLButtonElement>;
  variant?: string;
  children?: React.ReactChildren | React.ReactElement;
  classes?: VideoWrapperClasses;
  style?: Properties;
  pillarBox?: boolean;
  backgroundAudio?: boolean;
  mirror?: boolean;
  isFullScreen?: boolean;
  elementBlur?: boolean;
  blurPixels?: number;
}

const VideoWrapper = forwardRef<HTMLDivElement, VideoWrapperProps>(
  (
    {
      onClick,
      variant,
      children,
      isFullScreen,
      classes,
      style,
      pillarBox = false,
      elementBlur = false,
      blurPixels = 11,
      mirror,
    },
    ref,
  ) => {
    /**
     * These are needed to access the videoElement from the player/encoder UiContext.
     */
    const playerCtx = useContext<PlayerUiState | null>(LivelyPlayerUiContext);
    const encoderCtx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);
    const ctx = playerCtx ?? encoderCtx;

    /**
     * Determines the styling needed based on pillarBox/isFullScreen props.
     */
    const styling = videoWrapperStyles(pillarBox, isFullScreen, mirror, elementBlur, blurPixels);

    const mergedClasses = useStyles({ source: classes ?? {}, target: styling }, "videoWrapper");

    const handleMouseOver = (): void => {
      if (ctx != null) {
        ctx.videoMouseOver = true;
      }
    };

    const handleMouseOut = (): void => {
      if (ctx != null) {
        ctx.videoMouseOver = false;
      }
    };

    return (
      <div
        onMouseEnter={() => handleMouseOver()}
        onMouseLeave={() => handleMouseOut()}
        className={`
      ${mergedClasses.root ? mergedClasses.root : ""}
      ${variant != null ? `lv-${variant}` : ""}
    `}
        data-selenium="video-element"
        onClick={onClick}
        onKeyDown={undefined}
        style={style}
        role="button"
        tabIndex={0}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);

VideoWrapper.displayName = "VideoWrapper";

export default VideoWrapper;
