import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { EncoderUiState } from "../../../../../../store";
import { LivelyEncoderUiContext } from "../../../../../context";
import ButtonIcon from "../../../../../ui-lib/Buttons/Icon";
import ButtonIconProps from "../../../../../ui-lib/Buttons/Icon/propTypes";
import Icon, { IconStyleProps } from "../../../../../ui-lib/Icons";
import { RootStyles } from "../../../../../ui-lib/typings/css";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../../ErrorBoundary";

interface MicrophoneButtonClasses extends RootStyles {
  icon?: IconStyleProps;
}

interface MicrophoneButtonProps extends Partial<ButtonIconProps> {
  classes?: MicrophoneButtonClasses;
}

const MicrophoneButton = observer(
  ({
    onClick,
    classes = {
      root: {},
      icon: {},
    },
    icon: ProvidedIcon,
    screenReaderText,
    label = "Toggle mute",
    active,
    ...props
  }: MicrophoneButtonProps) => {
    /**
     * Component Name (for error msg)
     */
    const componentName = "<MicrophoneButton/>";
    /**
     * Access LivelyEncoderUiContext & destructure API state
     */
    const ctx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);

    /**
     * Throw error (and trigger ErrorBoundary) if store is undefined.
     * */
    useUndefinedStoreError(ctx?.mediaStreamController != null, componentName);

    const toggleMic = (): void => {
      ctx.mediaStreamController.audioMuted = !ctx.mediaStreamController.audioMuted;
    };

    /**
     * handleClick function wrapped in global Error handler (to trigger ErrorBoundary).
     * */
    const handleClick = onClick || useUIEventError(toggleMic, componentName);

    const activeState = active ?? !ctx.mediaStreamController.audioMuted;

    const proppedIcon = activeState ? (
      <Icon title={label} iconName="microphone" classes={classes?.icon} />
    ) : (
      <Icon title={label} iconName="microphoneoff" classes={classes?.icon} />
    );

    return (
      <ButtonIcon
        active={activeState}
        classes={classes}
        data-selenium="mic-toggle-button"
        icon={ProvidedIcon || proppedIcon}
        onClick={handleClick}
        label={label}
        disabled={ctx.mediaStreamController.audioDeviceId == null}
        {...props}
      >
        {screenReaderText ?? `Click to ${active ? "Stop" : "Start"} Audio Stream`}
      </ButtonIcon>
    );
  },
);

// eslint-disable-next-line react/function-component-definition
const MicrophoneButtonWithErrorBoundary: FC<Partial<ButtonIconProps>> = ({
  icon: ProvidedIcon,
  classes,
  label = "Toggle mute",
  screenReaderText,
  style = {},
  ...props
}: MicrophoneButtonProps) => {
  const icon = ProvidedIcon || <Icon iconName="microphoneoff" classes={classes?.icon} />;

  return (
    <ErrorBoundary
      render={() => (
        <ButtonIcon
          data-selenium="mic-toggle-button"
          disabled
          label={label}
          icon={icon}
          classes={classes}
          onClick={undefined}
          active={false}
          {...props}
        >
          {screenReaderText ?? "Click to Start Audio Stream"}
        </ButtonIcon>
      )}
    >
      <MicrophoneButton
        classes={classes}
        icon={ProvidedIcon}
        label={label}
        screenReaderText={screenReaderText}
        style={style}
        {...props}
      />
    </ErrorBoundary>
  );
};

export default MicrophoneButtonWithErrorBoundary;
