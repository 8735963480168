import { MediaStream } from "../adapter/features/media-stream";

export type Listener<T> = (ev: T) => void;

export interface DomEventEmitter<M> {
  addEventListener<K extends keyof M | "newListener">(
    type: K,
    listener: Listener<(M & { newListener: keyof M })[K]>,
  ): void;
  removeEventListener<K extends keyof M | "newListener">(
    type: K,
    listener?: (ev: (M & { newListener: keyof M })[K]) => void,
  ): void;
}

interface VideoElementEventsMap {
  play: void;
  pause: void;
  volumechange: void;
  timeupdate: void;
  progress: void;
  ended: void;
  loadedmetadata: void;
  canplay: void;
  error: MediaError;
  loadeddata: void;

  // can be ignored on non-DOM implementation
  webkitendfullscreen: void;
}

interface MediaError {
  readonly code: number;
  readonly error: unknown;
  readonly message: string;
  readonly msExtendedCode: number;
  readonly MEDIA_ERR_ABORTED: number;
  readonly MEDIA_ERR_DECODE: number;
  readonly MEDIA_ERR_NETWORK: number;
  readonly MEDIA_ERR_SRC_NOT_SUPPORTED: number;
  readonly MS_MEDIA_ERR_ENCRYPTED: number;
  readonly target?: { code: number };
}

interface TimeRanges {
  readonly length: number;
  end(index: number): number;
  start(index: number): number;
}

export function isMediaStream(value: unknown): value is MediaStream {
  if (typeof value !== "object" || value == null) {
    return false;
  }
  return "getAudioTracks" in value && "getVideoTracks" in value;
}

export interface VideoElement extends DomEventEmitter<VideoElementEventsMap> {
  // mutable properties
  muted: boolean;
  volume: number;
  currentTime: number;
  srcObject: MediaStream | unknown | Blob | File;
  playbackRate: number;
  src: string;
  autoplay: boolean;

  // readonly properties
  readonly paused: boolean;
  readonly duration: number;
  readonly buffered: TimeRanges;
  readonly error: { code: number; message: string } | null;
  readonly readyState: number;
  readonly ended: boolean;
  readonly videoWidth: number;
  readonly videoHeight: number;

  // this method can be ignored on non-DOM implementations
  setAttribute(key: "class", value: string): void;
  setAttribute(key: "webkit-playsinline", value: "true" | "false"): void;
  setAttribute(key: "poster", value: string): void;
  setAttribute(key: "playsinline", boolean: "true" | "false"): void;
  setAttribute(key: "type", mimetype: string): void;
  setAttribute(key: "src", value: string): void;

  // methods
  play(): Promise<void>;
  pause(): void;
  load?(): void;
  captureStream?(): MediaStream;
}
