import React, { FC, ReactNode } from "react";
import { useStyles} from "../../styling";
import {  mergeStylesObjects } from "../../styling/utils";
import { EventFunction } from "../typings/clickevents";
import { RootStyles } from "../typings/css";
import styles from "./styles";
import Icon from "../Icons/";

export interface PlayerOverlayButtonProps {
  classes?: RootStyles;
  onClick?: EventFunction;
  children?: ReactNode;
  disabled?: boolean;
  onMouseEnter?: EventFunction;
  onMouseLeave?: EventFunction
}



const PlayerOverlayButton: FC<PlayerOverlayButtonProps> = ({ classes = { root: {} }, children, ...rest }) => {
  const mergedClasses = useStyles({ source: classes, target: styles }, "playerOverlayButton");
  const mergedStyles = mergeStylesObjects(classes, styles);

  return (
    <div {...rest} className={`${rest.disabled && mergedClasses["rootDisabled"]} ${mergedClasses.root}`}>
      {children ??  <Icon iconName="livelyplay" classes={mergedStyles.livelyPlayIcon}/> }
    </div>
  );
};

export default PlayerOverlayButton;
